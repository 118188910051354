import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { useRecoilState } from "recoil";
import { menuList } from "../../recoil/atoms";
import {
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  SvgIcon,
  Tooltip,
  Divider,
} from "@mui/material";
import routes from "../../routes";
import useMediaQuery from "@mui/material/useMediaQuery";

function NavDrawer() {
  const [, setMenu] = useRecoilState(menuList);
  const [open, setOpen] = useState(false);
  const matches = useMediaQuery("(min-width:600px)");
  const userDetails = localStorage.getItem('user');
  const userData = JSON.parse(userDetails);

  useEffect(() => {
    setOpen(false);
  }, [matches]);

  const changeMenu = (name) => {
    setMenu(name);
  };

  function getListItemSet(text, index) {
    return text.icon ? (
      <NavLink
        className="sideNav"
        key={index}
        style={{ textDecoration: "none" }}
        to={text.path}
      >
        <ListItem
          key={text}
          disablePadding
          sx={{ display: "block" }}
          onClick={() => changeMenu(text.name)}
        >
          <ListItemButton
            sx={{
              minHeight: 50,
              justifyContent: open ? "initial" : "center",
            }}
          >
            <SvgIcon style={{ color: "black" }}>
              <Tooltip title={text.name} placement="right">
                <text.icon stock={"black"} />
              </Tooltip>
            </SvgIcon>
            <ListItemText
              sx={{ opacity: open ? 1 : 0 }}
            />
          </ListItemButton>
        </ListItem>
      </NavLink>
    ) : null;
  }

  const drawer = (
    <div
      style={{
        display: "flex",
        width: "100%",
        flexDirection: "column",
        height: "100%",
        justifyContent: "space-between",
      }}
    > 
      <div>
        <Divider />
        <List>
          {routes.mainRoutes.map((text, index) => {
            if(userData.role_id === 1 || userData.role_id === 2){
              return getListItemSet(text, index);
            }
            else if(text.name !== "User Management" && userData.role_id === 3){
              return getListItemSet(text, index);
            }
            })}
        </List>
        {/* <Divider /> */}
      </div>
      {/* <div
        style={{
          width: "100%",
          marginBottom: 50,
          paddingTop: "20px",
          borderTop: "1px solid #ccc",
        }}
      >
        Use Tooltip component to create the tooltip
        <Tooltip title="Help & Support" placement="right">
          <div
            style={{
              cursor: "pointer",
              userSelect: "none",
              marginLeft: 10,
            }}
            onClick={goToSupport}
            id="help_and_support"
          >
            <SupportIcon />
          </div>
        </Tooltip>
      </div> */}
    </div>
  );

  return (
    <Drawer
      className="mobile-menu"
      variant={matches ? "permanent" : "persistent"}
      sx={{
        whiteSpace: "nowrap",
        width: matches ? 46 : 0,
        "& .MuiDrawer-paper": {
          marginTop: "65px",
          width: matches ? 47 : 0,
        },
      }}
      open={open}
    >
      {/* SIDEBAR LIST OF ICONS TOP*/}
      {drawer}
      {/* SIDEBAR LIST OF ICONS BOTTOM*/}
      <List>
        {routes.mainRoutes.map((text, index) =>
          text.footer && text.iconFlag ? getListItemSet(text, index) : null
        )}
      </List>
    </Drawer>
  );
}
export default NavDrawer;