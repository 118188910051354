import * as React from "react";
import { Typography, FormControl } from "@mui/material";
import TextField from "@mui/material/TextField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export default function TrialDate(props) {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Typography style={{ fontSize: "0.95rem" }}>{props.label}</Typography>
      <FormControl style={{ width: "100%" }}>
        {!props.isDate ? (
          <TimePicker
            ampm={false}
            name={props.name}
            value={props.value}
            onChange={props.onChange}
            error={props.error ? props.error : false}
            helperText={props.helperText ? props.helperText : ""}
            maxDate={props.maxLimit ? props.maxLimit : null}
            renderInput={(params) => (
              <TextField size="medium" variant="outlined" {...params} />
            )}
            disabled={props.readOnly ? props.readOnly : false}
          />
        ) : (
          <DatePicker
            name={props.name}
            value={props.value}
            onChange={props.onChange}
            error={props.error ? props.error : false}
            helperText={props.helperText ? props.helperText : ""}
            maxDate={props.maxLimit ? props.maxLimit : null}
            // disabled={true}
            renderInput={(params) => (
              <TextField size="medium" variant="outlined" {...params} />
            )}
          // disabled={props.readOnly ? props.readOnly : false}
          />
        )}
      </FormControl>
    </LocalizationProvider>
  );
}
