import React from "react";
import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";

const DeleteDialog = (props) => {
  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Are you sure, you want to delete ?"}
      </DialogTitle>
      <DialogActions>
        <Button onClick={props.handleDelete} autoFocus>
          Yes
        </Button>
        <Button onClick={props.onClose}>No</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;