import React from "react";
import {
  FormGroup,
  FormControl,
  FormControlLabel,
  Checkbox,
  FormLabel,
} from "@mui/material";

const CustomCheckBox = (props) => {
  return (
    <FormControl fullWidth>
      <FormGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name={props.name}
      >
        <FormLabel id={props.id} style={props.style}>
          {props.mainLabel} &nbsp;&nbsp;&nbsp;
        </FormLabel>
        {props.option
          ? // eslint-disable-next-line
          props.option.map((data, index) => {
            console.log(data, "data24")
            return (
              <FormControlLabel
                value={true}
                control={
                  <Checkbox
                    defaultChecked={props.value.includes(index.toString())}
                  />
                }
                onChange={props.getValue}
                name={index}
                label={data}
                key={"checkbox_" + data.id}
              />
            );
          })
          : null}
      </FormGroup>
    </FormControl>
  );
};

export default CustomCheckBox;