import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

const ChartComponent = (data) => {
  const [graphData, setGraphData] = useState({});

  useEffect(() => {
    // Process the data to group it by year and zone
    const groupedData = Object.values(data.series).reduce((acc, entry) => {
      const year = entry.year.toString();
      const zone = entry.zone_name;
      const trials = parseInt(entry.total_trials, 10);

      if (!acc[year]) {
        acc[year] = {};
      }

      if (!acc[year][zone]) {
        acc[year][zone] = 0;
      }

      acc[year][zone] += trials;

      return acc;
    }, {});

    // Prepare the data for the ApexCharts
    const years = Object.keys(groupedData);
    const staticLabel = "Year";
    const categories = years.map((year) => `${staticLabel} ${year}`);
    const zoneNames = Object.keys(groupedData[years[0]]);
    const series = zoneNames.map((zone) => ({
      name: zone,
      data: years.map((year) => groupedData[year][zone] || 0),
    }));

    setGraphData({
      options: {
        title: {
          text: "Trials Overview",
          align: "left",
          style: {
            fontSize: "14px",
            fontWeight: "550",
          },
        },
        chart: {
          id: "TrialsOverview",
        },
        xaxis: {
          categories: categories,
        },
        yaxis: {
          title: {
            text: "Number of Trials",
            fontSize: "13px",
            fontWeight: "500",
          },
        },
        colors: ["#F6AE2D", "#0A78A1", "#00A7A4", "#ED5958"],
      },
      series: series,
      plotOptions: {
        bar: { stacked: true },
      },
      legend: { position: "top" },
    });
  }, []);

  return (
    <div>
      {graphData.options && graphData.series ? (
        <Chart
          options={graphData.options}
          series={graphData.series}
          type="bar"
          height={350}
        />
      ) : (
        <p>Loading graph...</p>
      )}
    </div>
  );
};
export default ChartComponent;