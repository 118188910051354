import React, { useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CustomText from "../../../../../components/ui/Typography";
import { Container, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import { useRecoilState } from "recoil";
import {
  customerInfoData,
  error,
  temparatures,
  newVisitStatus,
} from "../../../../../recoil/atoms";
import configParam from "../../../../../config";

const Temperatures = (props) => {
  const deepCopy = configParam.DEEP_COPY;
  const [customerInfo] = useRecoilState(customerInfoData);
  const [temparaturesData, setTemparaturesData] = useRecoilState(temparatures);
  const [visitStatus] = useRecoilState(newVisitStatus);
  let chamberType = customerInfo.chamberType;
  const colLength = chamberType === "Both Side" ? 6 : 12;
  const numberOfPorts =
    customerInfo.furnaceType === "End Fired" ? 1 : customerInfo.numberOfPorts;
  const sgid = localStorage.getItem("UserID");
  const getItemNo = configParam.getVisitNumbers;
  const myRegenFeatures = localStorage.getItem("myRegenVisitData");
  let data = myRegenFeatures ? JSON.parse(myRegenFeatures) : {};
  const [stepError] = useRecoilState(error);

  const getNumberOfPorts = (type, subType) => {
    const arr = [];
    for (let i = 0; i < numberOfPorts; i++) {
      let id = i + 1;
      const getValueLeft = () => {
        if (customerInfo.furnaceType === "End Fired") {
          return chamberType === "One Side"
            ? temparaturesData[type + "EFF"][subType]["symmetricData"]
            : temparaturesData[type + "EFF"][subType]["left"];
        } else {
          return chamberType === "One Side"
            ? temparaturesData[type + "CFF"][subType]["symmetricData"][id]
            : temparaturesData[type + "CFF"][subType]["left"][id];
        }
      };
      const getValueRight = () => {
        if (customerInfo.furnaceType === "End Fired") {
          return chamberType === "One Side"
            ? temparaturesData[type + "EFF"][subType]["symmetricData"]
            : temparaturesData[type + "EFF"][subType]["right"];
        } else {
          return chamberType === "One Side"
            ? temparaturesData[type + "CFF"][subType]["symmetricData"][id]
            : temparaturesData[type + "CFF"][subType]["right"][id];
        }
      };
      arr.push(
        <React.Fragment>
          <Grid item xs={colLength} md={colLength}>
            <TextField
              variant="outlined"
              size="small"
              name={id}
              label={"#" + id}
              style={{ width: "100%" }}
              id="left"
              type="number"
              value={getValueLeft()}
              error={stepError && !getValueLeft()}
              onChange={(e) => {
                const newValue = e.target.value;
                if (newValue.length <= 10 && !isNaN(newValue)) {
                  onHandleChange(type, subType, e, "left");
                }
              }}
            />
          </Grid>
          {chamberType === "Both Side" ? (
            <Grid item xs={6} md={6}>
              <TextField
                variant="outlined"
                size="small"
                name={id}
                label={"#" + id}
                id="right"
                style={{ width: "100%" }}
                type="number"
                value={getValueRight()}
                error={stepError && !getValueRight()}
                onChange={(e) => {
                  const newValue = e.target.value;
                  if (newValue.length <= 10 && !isNaN(newValue)) {
                    onHandleChange(type, subType, e, "right");
                  }
                }}
              />
            </Grid>
          ) : null}
        </React.Fragment>
      );
    }
    return arr;
  };

  const onHandleChange = (type, subType, event, position) => {
    let tmpObj = deepCopy(temparaturesData);
    if (customerInfo.furnaceType === "End Fired") {
      if (chamberType === "One Side") {
        tmpObj[type + "EFF"][subType]["symmetricData"] = event.target.value;
      } else {
        tmpObj[type + "EFF"][subType][position] = event.target.value;
      }
    } else {
      if (chamberType === "One Side") {
        tmpObj[type + "CFF"][subType]["symmetricData"][event.target.name] =
          event.target.value;
      } else {
        tmpObj[type + "CFF"][subType][position][event.target.name] =
          event.target.value;
      }
    }
    setTemparaturesData(tmpObj);
    let rowIndex = getItemNo();
    let obj = { temparatures: temparaturesData };
    let merged = { ...data[sgid][rowIndex || 0], ...obj };
    data[sgid][rowIndex] = merged;
    localStorage.setItem("myRegenVisitData", JSON.stringify(data));
  };

  useEffect(() => {
    if (visitStatus) {
      let rowIndex = getItemNo();
      let obj = { temparatures: temparaturesData };
      let merged = { ...data[sgid][rowIndex || 0], ...obj };
      data[sgid][rowIndex] = merged;
      localStorage.setItem("myRegenVisitData", JSON.stringify(data));
    }
  });

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle style={{ fontWeight: 600 }}>
        Air & Fumes Temperatures
        <CloseIcon
          sx={{
            position: "absolute",
            right: 20,
            top: 20,
            cursor: "pointer"
          }}
          onClick={props.handleClose}
        />
      </DialogTitle>

      <DialogContent
        style={{ borderTop: "1px solid #a8a8a8", padding: "0 0 20px" }}
      >
        <DialogContentText>
          <Container>
            <CustomText
              value="Air Temperature"
              variant="h6"
              style={{
                color: "#2D2D2D",
                fontWeight: 500,
                marginTop: "10px",
                fontSize: "18px",
              }}
              align="left"
            />

            <Grid container spacing={2}>
              <Grid item xl={6} md={6} sm={12} xs={12}>
                {numberOfPorts > 0 ? (
                  <React.Fragment>
                    <CustomText
                      value="Bottom (cold air) [°C] *"
                      variant="subtitle1"
                      style={{ color: "#2D2D2D", fontSize: "15px" }}
                      align="left"
                    />
                    <Grid container spacing={2}>
                      {chamberType === "Both Side" ? (
                        <React.Fragment>
                          <Grid item xs={colLength} md={colLength}>
                            <CustomText
                              value=" Left *"
                              variant="subtitle1"
                              align="left"
                              style={{ marginBottom: "10px", fontSize: "14px" }}
                            />
                          </Grid>
                          <Grid item xs={6} md={6}>
                            <CustomText
                              value=" Right *"
                              style={{ marginBottom: "10px", fontSize: "14px" }}
                              variant="subtitle1"
                              align="left"
                            />
                          </Grid>
                        </React.Fragment>
                      ) : (
                        <Grid item xs={6} md={6}>
                          <CustomText
                            value=" "
                            variant="subtitle1"
                            align="left"
                          />
                        </Grid>
                      )}
                    </Grid>
                    <Grid container spacing={2}>
                      {getNumberOfPorts("airTemperature", "bottom")}
                    </Grid>
                  </React.Fragment>
                ) : null}
              </Grid>
              <Grid item xl={6} md={6} sm={12} xs={12}>
                {numberOfPorts > 0 ? (
                  <React.Fragment>
                    <CustomText
                      value="Top (preheated air) [°C] *"
                      variant="subtitle1"
                      style={{ color: "#2D2D2D" }}
                      align="left"
                    />
                    <Grid container spacing={2}>
                      {chamberType === "Both Side" ? (
                        <React.Fragment>
                          <Grid item xs={colLength} md={colLength}>
                            <CustomText
                              value=" Left *"
                              variant="subtitle1"
                              align="left"
                              style={{ marginBottom: "10px", fontSize: "14px" }}
                            />
                          </Grid>
                          <Grid item xs={6} md={6}>
                            <CustomText
                              value=" Right *"
                              style={{ marginBottom: "10px", fontSize: "14px" }}
                              variant="subtitle1"
                              align="left"
                            />
                          </Grid>
                        </React.Fragment>
                      ) : (
                        <Grid item xs={6} md={6}>
                          <CustomText
                            value=" "
                            variant="subtitle1"
                            align="left"
                          />
                        </Grid>
                      )}
                    </Grid>
                    <Grid container spacing={2}>
                      {getNumberOfPorts("airTemperature", "top")}
                    </Grid>
                  </React.Fragment>
                ) : null}
              </Grid>
            </Grid>
            <CustomText
              value="Fumes Temperature"
              variant="h6"
              style={{
                color: "#2D2D2D",
                fontWeight: 500,
                marginTop: "10px",
                fontSize: "18px",
              }}
              align="left"
            />
            <Grid container spacing={2}>
              <Grid item xl={6} md={6} sm={12} xs={12}>
                {numberOfPorts > 0 ? (
                  <React.Fragment>
                    <CustomText
                      value="Bottom (cold fumes) [°C] *"
                      variant="subtitle1"
                      style={{ color: "#2D2D2D", fontSize: "15px" }}
                      align="left"
                    />
                    <Grid container spacing={2}>
                      {chamberType === "Both Side" ? (
                        <React.Fragment>
                          <Grid item xs={colLength} md={colLength}>
                            <CustomText
                              value=" Left *"
                              variant="subtitle1"
                              align="left"
                              style={{ marginBottom: "10px", fontSize: "14px" }}
                            />
                          </Grid>
                          <Grid item xs={6} md={6}>
                            <CustomText
                              value=" Right *"
                              style={{ marginBottom: "10px", fontSize: "14px" }}
                              variant="subtitle1"
                              align="left"
                            />
                          </Grid>
                        </React.Fragment>
                      ) : (
                        <Grid item xs={6} md={6}>
                          <CustomText
                            value=" "
                            variant="subtitle1"
                            align="left"
                          />
                        </Grid>
                      )}
                    </Grid>
                    <Grid container spacing={2}>
                      {getNumberOfPorts("fumesTemperature", "bottom")}
                    </Grid>
                  </React.Fragment>
                ) : null}
              </Grid>
              <Grid item xl={6} md={6} sm={12} xs={12}>
                {numberOfPorts > 0 ? (
                  <React.Fragment>
                    <CustomText
                      value="Top (hot fumes) [°C] *"
                      variant="subtitle1"
                      style={{ color: "#2D2D2D", fontSize: "15px" }}
                      align="left"
                    />
                    <Grid container spacing={2}>
                      {chamberType === "Both Side" ? (
                        <React.Fragment>
                          <Grid item xs={colLength} md={colLength} xl={6}>
                            <CustomText
                              value=" Left *"
                              variant="subtitle1"
                              align="left"
                              style={{ marginBottom: "10px", fontSize: "14px" }}
                            />
                          </Grid>
                          <Grid item xs={6} md={6}>
                            <CustomText
                              value=" Right *"
                              style={{ marginBottom: "10px", fontSize: "14px" }}
                              variant="subtitle1"
                              align="left"
                            />
                          </Grid>
                        </React.Fragment>
                      ) : (
                        <Grid item xs={6} md={6}>
                          <CustomText
                            value=" "
                            variant="subtitle1"
                            align="left"
                          />
                        </Grid>
                      )}
                    </Grid>
                    <Grid container spacing={2}>
                      {getNumberOfPorts("fumesTemperature", "top")}
                    </Grid>
                  </React.Fragment>
                ) : null}
              </Grid>
            </Grid>
          </Container>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default Temperatures;
