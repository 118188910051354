import React from "react";
import {
  Grid,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Box,
  Card,
  Typography,
} from "@mui/material";
import {
  customerInfoData,
} from "../../recoil/atoms";
import { useRecoilState } from "recoil";

const Heading = ({ cardHeading }) => {
  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography
          variant="h6"
          style={{ marginTop: 8, marginLeft: 20, fontWeight: "bold" }}
        >
          {cardHeading}
        </Typography>
      </Grid>
    </Grid>
  );
};

export const FlowsInputCard = ({
  tableHeading,
  fuelInputDet,
  batchInputDet,
  regenInputDet,
}) => {
  return (
    <Card
      style={{
        border: "1px solid rgb(191, 191, 191)",
        boxShadow: "none",
        borderRadius: 2,
      }}
    >
      <Heading cardHeading={tableHeading} />
      <Box p={1}>
        <Grid container spacing={0}>
          <Grid item md={4} xs={12}>
            <Typography
              variant="h7"
              style={{ marginLeft: 15, fontWeight: "bold" }}
            >
              Fuel
            </Typography>
            <TableContainer>
              <Table size="small">
                <TableBody>
                  {Object.keys(fuelInputDet).map((details) => (
                    <TableRow key={details}>
                      <TableCell style={{ borderBottom: "none" }}>
                        {details}
                      </TableCell>
                      <TableCell style={{ borderBottom: "none" }}>:</TableCell>
                      <TableCell style={{ borderBottom: "none" }}>
                        {fuelInputDet[details]}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item md={3} xs={12}>
            <Typography
              variant="h7"
              style={{ marginLeft: 15, fontWeight: "bold" }}
            >
              Batch Data
            </Typography>
            <TableContainer>
              <Table size="small">
                <TableBody>
                  {Object.keys(batchInputDet).map((details) => (
                    <TableRow key={details}>
                      <TableCell style={{ borderBottom: "none" }}>
                        {details}
                      </TableCell>
                      <TableCell style={{ borderBottom: "none" }}>:</TableCell>
                      <TableCell style={{ borderBottom: "none" }}>
                        {batchInputDet[details]}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item md={5} xs={12}>
            <Typography
              variant="h7"
              style={{ marginLeft: 15, fontWeight: "bold" }}
            >
              Regenerator Design
            </Typography>
            <TableContainer>
              <Table size="small">
                <TableBody>
                  {Object.keys(regenInputDet).map((details) => (
                    <TableRow key={details}>
                      <TableCell style={{ borderBottom: "none" }}>
                        {details}
                      </TableCell>
                      <TableCell style={{ borderBottom: "none" }}>:</TableCell>
                      <TableCell style={{ borderBottom: "none" }}>
                        {regenInputDet[details]}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

export const FlowsOutputCard = ({
  tableHeading,
  fuelOutputDet,
  batchDet,
  regenOutputDet,
}) => {
  const [customerInfo] = useRecoilState(customerInfoData);
  if (customerInfo.furnaceType === "End Fired") {
    return (
      <Card
        style={{
          border: "1px solid rgb(191, 191, 191)",
          boxShadow: "none",
          borderRadius: 2,
        }}
      >
        <Heading cardHeading={tableHeading} />
        <Box p={1}>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <Typography
                variant="h7"
                style={{ marginLeft: 15, fontWeight: "bold" }}
              >
                Fuel
              </Typography>
              <TableContainer>
                <Table size="small">
                  <TableBody>
                    {Object.keys(fuelOutputDet).map((details) => (
                      <TableRow key={details}>
                        <TableCell style={{ borderBottom: "none" }}>
                          {details}
                        </TableCell>
                        <TableCell style={{ borderBottom: "none" }}>:</TableCell>
                        <TableCell style={{ borderBottom: "none" }}>
                          {fuelOutputDet[details]}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item md={6} xs={12}>
              <Typography
                variant="h7"
                style={{ marginLeft: 15, fontWeight: "bold" }}
              >
                Regenerator Design
              </Typography>
              <TableContainer>
                <Table size="small">
                  <TableBody>
                    {Object.keys(regenOutputDet).map((details) => (
                      <TableRow key={details}>
                        <TableCell style={{ borderBottom: "none" }}>
                          {details}
                        </TableCell>
                        <TableCell style={{ borderBottom: "none" }}>:</TableCell>
                        <TableCell style={{ borderBottom: "none" }}>
                          {regenOutputDet[details]}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Box>
      </Card>
    )
  }
  else {
    return (
      <Card
        style={{
          border: "1px solid rgb(191, 191, 191)",
          boxShadow: "none",
          borderRadius: 2,
        }}
      >
        <Heading cardHeading={tableHeading} />
        <Box p={1}>
          <Grid container spacing={2}>
            <Grid item md={4} xs={12}>
              <Typography
                variant="h7"
                style={{ marginLeft: 15, fontWeight: "bold" }}
              >
                Fuel
              </Typography>
              <TableContainer>
                <Table size="small">
                  <TableBody>
                    {Object.keys(fuelOutputDet).map((details) => (
                      <TableRow key={details}>
                        <TableCell style={{ borderBottom: "none" }}>
                          {details}
                        </TableCell>
                        <TableCell style={{ borderBottom: "none" }}>:</TableCell>
                        <TableCell style={{ borderBottom: "none" }}>
                          {fuelOutputDet[details]}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography
                variant="h7"
                style={{ marginLeft: 15, fontWeight: "bold" }}
              >
                Batch Data
              </Typography>
              <TableContainer>
                <Table size="small">
                  <TableBody>
                    {Object.keys(batchDet).map((details) => (
                      <TableRow key={details}>
                        <TableCell style={{ borderBottom: "none" }}>
                          {details}
                        </TableCell>
                        <TableCell style={{ borderBottom: "none" }}>: </TableCell>
                        <TableCell style={{ borderBottom: "none" }}>
                          {batchDet[details]}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item md={4} xs={12}>
              <Typography
                variant="h7"
                style={{ marginLeft: 15, fontWeight: "bold" }}
              >
                Regenerator Design
              </Typography>
              <TableContainer>
                <Table size="small">
                  <TableBody>
                    {Object.keys(regenOutputDet).map((details) => (
                      <TableRow key={details}>
                        <TableCell style={{ borderBottom: "none" }}>
                          {details}
                        </TableCell>
                        <TableCell style={{ borderBottom: "none" }}>:</TableCell>
                        <TableCell style={{ borderBottom: "none" }}>
                          {regenOutputDet[details]}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </Box>
      </Card>
    )
  }
};

export const EfficienciesInputCard = ({
  tableHeading,
  airFumesTempInputDet,
  fumesGasCompInputDet,
}) => {
  return (
    <Card
      style={{
        border: "1px solid rgb(191, 191, 191)",
        boxShadow: "none",
        borderRadius: 2,
      }}
    >
      <Heading cardHeading={tableHeading} />
      <Box p={1}>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Typography
              variant="h7"
              style={{ marginLeft: 15, fontWeight: "bold" }}
            >
              Air & Fumes Temperatures
            </Typography>
            <TableContainer>
              <Table size="small">
                <TableBody>
                  {Object.keys(airFumesTempInputDet).map((details) => (
                    <TableRow key={details}>
                      <TableCell style={{ borderBottom: "none" }}>
                        {details}
                      </TableCell>
                      <TableCell style={{ borderBottom: "none" }}>:</TableCell>
                      <TableCell style={{ borderBottom: "none" }}>
                        {airFumesTempInputDet[details]}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography
              variant="h7"
              style={{ marginLeft: 15, fontWeight: "bold" }}
            >
              Fumes Gas Composition
            </Typography>
            <TableContainer>
              <Table size="small">
                <TableBody>
                  {Object.keys(fumesGasCompInputDet).map((details) => (
                    <TableRow key={details}>
                      <TableCell style={{ borderBottom: "none" }}>
                        {details}
                      </TableCell>
                      <TableCell style={{ borderBottom: "none" }}>:</TableCell>
                      <TableCell style={{ borderBottom: "none" }}>
                        {fumesGasCompInputDet[details]}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

export const EfficienciesOutputCard = ({
  tableHeading,
  airFumesTempOutputDet,
  fumesGasCompOutputDet,
}) => {
  return (
    <Card
      style={{
        border: "1px solid rgb(191, 191, 191)",
        boxShadow: "none",
        borderRadius: 2,
      }}
    >
      <Heading cardHeading={tableHeading} />
      <Box p={1}>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <Typography
              variant="h7"
              style={{ marginLeft: 15, fontWeight: "bold" }}
            >
              Air & Fumes Temperature
            </Typography>
            <TableContainer>
              <Table size="small">
                <TableBody>
                  {Object.keys(airFumesTempOutputDet).map((details) => (
                    <TableRow key={details}>
                      <TableCell style={{ borderBottom: "none" }}>
                        {details}
                      </TableCell>
                      <TableCell style={{ borderBottom: "none" }}>:</TableCell>
                      <TableCell style={{ borderBottom: "none" }}>
                        {airFumesTempOutputDet[details]}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item md={6} xs={12}>
            <Typography
              variant="h7"
              style={{ marginLeft: 15, fontWeight: "bold" }}
            >
              Fumes Gas Composition (Total)
            </Typography>
            <TableContainer>
              <Table size="small">
                <TableBody>
                  {Object.keys(fumesGasCompOutputDet).map((details) => (
                    <TableRow key={details}>
                      <TableCell style={{ borderBottom: "none" }}>
                        {details}
                      </TableCell>
                      <TableCell style={{ borderBottom: "none" }}>:</TableCell>
                      <TableCell style={{ borderBottom: "none" }}>
                        {fumesGasCompOutputDet[details]}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

export const DustsInputCard = ({ tableHeading, dustsAnalysisInputDet }) => {
  return (
    <Card
      style={{
        border: "1px solid rgb(191, 191, 191)",
        boxShadow: "none",
        borderRadius: 2,
      }}
    >
      <Heading cardHeading={tableHeading} />
      <Box p={1}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="h7"
              style={{ marginLeft: 15, fontWeight: "bold" }}
            >
              Dusts Analysis
            </Typography>
            <TableContainer>
              <Table size="small">
                <TableBody>
                  {Object.keys(dustsAnalysisInputDet).map((details) => (
                    <TableRow key={details}>
                      <TableCell style={{ borderBottom: "none" }}>
                        {details}
                      </TableCell>
                      <TableCell style={{ borderBottom: "none" }}>:</TableCell>
                      <TableCell style={{ borderBottom: "none" }}>
                        {dustsAnalysisInputDet[details]}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

export const DustsOutputCard = ({ tableHeading, dustsAnalysisOutputDet }) => {
  return (
    <Card
      style={{
        border: "1px solid rgb(191, 191, 191)",
        boxShadow: "none",
        borderRadius: 2,
      }}
    >
      <Heading cardHeading={tableHeading} />
      <Box p={1}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="h7"
              style={{ marginLeft: 15, fontWeight: "bold" }}
            >
              Dusts Analysis
            </Typography>
            <TableContainer>
              <Table size="small">
                <TableBody>
                  {Object.keys(dustsAnalysisOutputDet).map((details) => (
                    <TableRow key={details}>
                      <TableCell style={{ borderBottom: "none" }}>
                        {details}
                      </TableCell>
                      <TableCell style={{ borderBottom: "none" }}>: </TableCell>
                      <TableCell style={{ borderBottom: "none" }}>
                        {dustsAnalysisOutputDet[details]}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};