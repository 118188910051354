import React, { useState } from "react";
import { useRecoilState } from "recoil";
import { activeStep } from "../../../recoil/atoms";
import CustomerCreate from "./CustomerInfo/CustomerCreate";
import Flows from "./Flows";
import Efficiencies from "./Efficiencies";
import Summary from "./Summary";
import CustomTabs from "../../../components/ui/Tabs";
import { Breadcrumbs } from "@mui/material";
import { Link } from "@material-ui/core";
import { useNavigate } from "react-router-dom";
import SuccessDialog from "../../../input/SuccessDialog";

const Index = () => {
  const [currentStep, setActiveStep] = useRecoilState(activeStep);
  const [showDialog, setShowDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");

  const stepperArray = [
    "Customer Information",
    "Furnace & Fuel Data",
    "Measurements",
    "Summary",
  ];
  const navigate = useNavigate("/case");

  const onChangeStep = (step) => {
    setActiveStep(step);
  };
  const handleSubmit = (message) => {
    // This function will be called from MyComponent upon success
    console.log('Success function called', message);
    setShowDialog(true);
    setDialogMessage("Form successfully submitted");
    // You can perform additional actions here
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
    navigate(`/case`);
  }
  return (
    <React.Fragment>
      <div className="stepper">
        <Breadcrumbs aria-label="breadcrumb" style={{ margin: 15 }}>
          <Link
            color="inherit"
            style={{ cursor: "pointer", fontWeight: 500 }}
            onClick={() => navigate("/case")}
          >
            Saved Reports
          </Link>
        </Breadcrumbs>
        {
          // STEPPER WRAPPER
        }
        <div
          className="sub-Header"
          style={{ textAlign: "center", display: "flex" }}
        >
          <CustomTabs tabs={stepperArray} onChangeStep={onChangeStep} />
        </div>
      </div>
      <div style={{ marginTop: "100px" }}>
        {currentStep === 0 ? (
          <CustomerCreate />
        ) : currentStep === 1 ? (
          <Flows />
        ) : currentStep === 2 ? (
          <Efficiencies />
        ) : currentStep === 3 ? (
          <>
            {showDialog ?
              <SuccessDialog open={showDialog} onClose={handleCloseDialog} msg={dialogMessage} />
              :
              <Summary onSubmit={handleSubmit} />
            }
          </>
        ) : null}
      </div>
    </React.Fragment>
  );
};

export default Index;