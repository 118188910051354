import React, { useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  TextField,
} from "@mui/material";
import CustomText from "../../../../components/ui/Typography";
import { useRecoilState } from "recoil";
import {
  customerInfoData,
  error,
  fuelInfo,
  newVisitStatus,
  fuelStatus
} from "../../../../recoil/atoms";
import CustomCheckBox from "../../../../components/ui/CheckBox";
import CloseIcon from "@mui/icons-material/Close";
import configParam from "../../../../config";

const FuelPropertiesPopup = (props) => {
  const deepCopy = configParam.DEEP_COPY;
  const [customerInfo] = useRecoilState(customerInfoData);
  const [fuelData, setFuelData] = useRecoilState(fuelInfo);
  const [fuelType, setFuelType] = useRecoilState(fuelStatus);
  const [maxWidth, setMaxWidth] = React.useState("sm");
  const [stepError] = useRecoilState(error);
  let chamberType = customerInfo.chamberType;
  const colLength = chamberType === "Both Side" ? 6 : 12;
  let numberOfPorts = customerInfo.numberOfPorts;
  if (customerInfo.furnaceType === "End Fired") {
    numberOfPorts = 1;
  }
  const [visitStatus] = useRecoilState(newVisitStatus);

  const getItemNo = configParam.getVisitNumbers;
  const myRegenFeatures = localStorage.getItem("myRegenVisitData");
  let data = JSON.parse(myRegenFeatures);
  const sgid = localStorage.getItem("UserID");

  const getNumberOfPorts = (type) => {
    const arr = [];
    for (let i = 0; i < numberOfPorts; i++) {
      let id = i + 1;
      arr.push(
        <React.Fragment>
          {chamberType === "Both Side" && (
            <Grid item xs={6} md={6}>
              <TextField
                variant="outlined"
                size="small"
                name={id}
                id={id}
                style={{ width: "100%", marginBottom: "15px" }}
                label={"#" + id}
                type="number"
                value={
                  customerInfo.furnaceType === "End Fired"
                    ? chamberType === "One Side"
                      ? fuelData[type].consumptionEFF?.symetricData
                      : fuelData[type].consumptionEFF?.left
                    : chamberType === "One Side"
                      ? fuelData[type].consumptionCFF?.symetricData[id]
                      : fuelData[type].consumptionCFF?.left[id]
                }
                error={
                  stepError &&
                    !(customerInfo.furnaceType === "End Fired"
                      ? chamberType === "One Side"
                        ? fuelData[type].consumptionEFF?.symetricData
                        : fuelData[type].consumptionEFF?.left
                      : chamberType === "One Side"
                        ? fuelData[type].consumptionCFF?.symetricData[id]
                        : fuelData[type].consumptionCFF?.left[id])
                    ? true
                    : false
                }
                onChange={(e) => {
                  const newValue = e.target.value;
                  if (newValue.length <= 10 && !isNaN(newValue)) {
                    onHandlechange("left" + type, e, type, "left");
                  }
                }}
              />
            </Grid>
          )}
          <Grid item xs={colLength} md={colLength}>
            <TextField
              variant="outlined"
              size="small"
              name={id}
              style={{
                width: "100%",
                marginBottom: "15px",
                paddingLeft: "10px",
              }}
              id={id}
              label={"#" + id}
              type="number"
              value={
                customerInfo.furnaceType === "End Fired"
                  ? chamberType === "One Side"
                    ? fuelData[type].consumptionEFF?.symmetricData
                    : fuelData[type].consumptionEFF?.right
                  : chamberType === "One Side"
                    ? fuelData[type].consumptionCFF?.symmetricData[id]
                    : fuelData[type].consumptionCFF?.right[id]
              }
              error={
                stepError &&
                  !(customerInfo.furnaceType === "End Fired"
                    ? chamberType === "One Side"
                      ? fuelData[type].consumptionEFF?.symmetricData
                      : fuelData[type].consumptionEFF?.right
                    : chamberType === "One Side"
                      ? fuelData[type].consumptionCFF?.symmetricData[id]
                      : fuelData[type].consumptionCFF?.right[id])
                  ? true
                  : false
              }
              onChange={(e) => {
                const newValue = e.target.value;
                if (newValue.length <= 10 && !isNaN(newValue)) {
                  onHandlechange("right" + type, e, type, "right");
                }
              }}
            />
          </Grid>
        </React.Fragment>
      );
    }
    return arr;
  };

  const portObj = (num) => {
    let tmpVal = num ? 0 : "";
    return {
      1: tmpVal,
      2: tmpVal,
      3: tmpVal,
      4: tmpVal,
      5: tmpVal,
      6: tmpVal,
      7: tmpVal,
      8: tmpVal,
    };
  };

  function onChangeType(value) {
    let tmpObj = deepCopy(fuelData);
    if (value.target.name === "0") {
      tmpObj.gaz.status = !tmpObj.gaz.status;
      if (tmpObj.gaz.status === false) {
        tmpObj.gaz.volPercentage = {
          h2: "",
          co: "",
          ch4: "",
          c2h4: "",
          c2h6: "",
          c3h6: "",
          c3h8: "",
          c4h8: "",
          c4h10: "",
          c5h12: "",
          co2: "",
          n2: "",
          o2: "",
        };
        tmpObj.gaz.consumptionCFF = {
          left: portObj(),
          right: portObj(),
          symmetricData: portObj(),
        };
        tmpObj.gaz.consumptionEFF = {
          left: "",
          right: "",
          symmetricData: "",
        }
      } else {
        setFuelData(tmpObj);
      }
    } else if (value.target.name === "1") {
      tmpObj.oil.status = !tmpObj.oil.status;
      if (tmpObj.oil.status === false) {
        tmpObj.oil.analysisPercMass = {
          carbon: "",
          hydrogen: "",
          oxygen: "",
          nitrogen: "",
          sulphur: "",
          humidity: "",
        };
        tmpObj.oil.consumptionCFF = {
          left: portObj(),
          right: portObj(),
          symmetricData: portObj(),
        };
        tmpObj.oil.consumptionEFF = {
          left: "",
          right: "",
          symmetricData: "",
        }
      } else {
        setFuelData(tmpObj);
      }
    }
    setFuelData(tmpObj);
  }

  const onHandlechange = (type, event, consumptionType, subType) => {
    let tempFlowsObj = deepCopy(fuelData);
    if (type === "gaz") {
      tempFlowsObj["gaz"]["volPercentage"][event.target.name] =
        event.target.value;
    } else if (type === "oil") {
      tempFlowsObj["oil"]["analysisPercMass"][event.target.name] =
        event.target.value;
    } else if (type === "leftgaz" || type === "rightgaz") {
      if (customerInfo.furnaceType === "End Fired") {
        if (chamberType === "One Side") {
          tempFlowsObj["gaz"]["consumptionEFF"]["symmetricData"] =
            event.target.value;
        } else {
          tempFlowsObj["gaz"]["consumptionEFF"][subType] = event.target.value;
        }
      } else {
        if (chamberType === "One Side") {
          tempFlowsObj["gaz"]["consumptionCFF"]["symmetricData"][
            event.target.name
          ] = event.target.value;
        } else {
          tempFlowsObj["gaz"]["consumptionCFF"][subType][event.target.name] =
            event.target.value;
        }
      }
    } else if (type === "leftoil" || type === "rightoil") {
      if (customerInfo.furnaceType === "End Fired") {
        if (chamberType === "One Side") {
          tempFlowsObj["oil"]["consumptionEFF"]["symmetricData"] =
            event.target.value;
        } else {
          tempFlowsObj["oil"]["consumptionEFF"][subType] = event.target.value;
        }
      } else {
        if (chamberType === "One Side") {
          tempFlowsObj["oil"]["consumptionCFF"]["symmetricData"][
            event.target.name
          ] = event.target.value;
        } else {
          tempFlowsObj["oil"]["consumptionCFF"][subType][event.target.name] =
            event.target.value;
        }
      }
    }
    setFuelData(tempFlowsObj);
  };

  useEffect(() => {
    if (visitStatus) {
      let rowIndex = getItemNo();
      let obj = { fuelData: fuelData };
      let merged = { ...data[sgid][rowIndex || 0], ...obj };
      data[sgid][rowIndex] = merged;
      localStorage.setItem("myRegenVisitData", JSON.stringify(data));
    }
    let arr = fuelType;
    if (
      fuelData["gaz"]["status"] === true &&
      fuelData["oil"]["status"] === true
    ) {
      setFuelType(["0", "1"]);
      arr = ["0", "1"];
    } else if (fuelData["gaz"]["status"] === true) {
      setFuelType(["0"]);
      arr = ["0"];
    }
    else if (fuelData["oil"]["status"] === true) {
      setFuelType(["1"]);
      arr = ["1"];
    }
    let width = arr.length > 1 ? "lg" : "sm";
    setMaxWidth(width);
  }, [fuelData]);
  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      fullWidth={true}
      maxWidth={maxWidth}
    >
      <DialogTitle style={{ borderBottom: "1px solid #ccc" }}>
        Fuel
        <CloseIcon
          sx={{
            position: "absolute",
            right: 20,
            top: 13,
            cursor: "pointer"
          }}
          onClick={props.handleClose}
        />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <CustomCheckBox
            name="row-radio-buttons-group"
            id="radio-btn-title"
            option={["Natural Gas", "Heavy Oil"]}
            getValue={(e) => {
              onChangeType(e);
            }}
            value={fuelType}
          />
          {/* Fuel Properties textbox */}
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              lg={maxWidth === "sm" ? 12 : 6}
              xl={maxWidth === "sm" ? 12 : 6}
            >
              {fuelType.includes("0") && (
                <Grid container columns={maxWidth === "sm" ? 16 : 8}>
                  <CustomText
                    value="Natural Gas Composition [%]"
                    variant="subtitle1"
                    style={{
                      color: "#2D2D2D",
                      padding: "0 10px 20px",
                    }}
                    align="left"
                  />
                  <Grid container>
                    <Grid
                      item
                      xs={6}
                      md={3}
                      style={{ paddingLeft: "10px", marginBottom: "15px" }}
                    >
                      <TextField
                        variant="outlined"
                        size="small"
                        style={{ width: "100%" }}
                        name="h2"
                        label="H2 *"
                        value={fuelData["gaz"]["volPercentage"]["h2"]}
                        error={stepError && !fuelData["gaz"]["volPercentage"]["h2"]}
                        type="number"
                        onChange={(e) => {
                          const newValue = e.target.value;
                          if (newValue.length <= 10 && !isNaN(newValue)) {
                            onHandlechange("gaz", e);
                          }
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={3}
                      style={{ paddingLeft: "10px", marginBottom: "15px" }}
                    >
                      <TextField
                        variant="outlined"
                        size="small"
                        style={{ width: "100%" }}
                        name="co"
                        label="CO *"
                        value={fuelData["gaz"]["volPercentage"]["co"]}
                        error={stepError && !fuelData["gaz"]["volPercentage"]["co"]}
                        type="number"
                        onChange={(e) => {
                          const newValue = e.target.value;
                          if (newValue.length <= 10 && !isNaN(newValue)) {
                            onHandlechange("gaz", e);
                          }
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={3}
                      style={{ paddingLeft: "10px", marginBottom: "15px" }}
                    >
                      <TextField
                        variant="outlined"
                        size="small"
                        style={{ width: "100%" }}
                        name="ch4"
                        label="CH4 *"
                        value={fuelData["gaz"]["volPercentage"]["ch4"]}
                        error={stepError && !fuelData["gaz"]["volPercentage"]["ch4"]}
                        type="number"
                        onChange={(e) => {
                          const newValue = e.target.value;
                          if (newValue.length <= 10 && !isNaN(newValue)) {
                            onHandlechange("gaz", e);
                          }
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={3}
                      style={{ paddingLeft: "10px", marginBottom: "15px" }}
                    >
                      <TextField
                        variant="outlined"
                        size="small"
                        style={{ width: "100%" }}
                        name="c2h4"
                        label="C2H4 *"
                        value={fuelData["gaz"]["volPercentage"]["c2h4"]}
                        error={stepError && !fuelData["gaz"]["volPercentage"]["c2h4"]}
                        type="number"
                        onChange={(e) => {
                          const newValue = e.target.value;
                          if (newValue.length <= 10 && !isNaN(newValue)) {
                            onHandlechange("gaz", e);
                          }
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={3}
                      style={{ paddingLeft: "10px", marginBottom: "15px" }}
                    >
                      <TextField
                        variant="outlined"
                        size="small"
                        style={{ width: "100%" }}
                        name="c2h6"
                        label="C2H6 *"
                        value={fuelData["gaz"]["volPercentage"]["c2h6"]}
                        error={stepError && !fuelData["gaz"]["volPercentage"]["c2h6"]}
                        type="number"
                        onChange={(e) => {
                          const newValue = e.target.value;
                          if (newValue.length <= 10 && !isNaN(newValue)) {
                            onHandlechange("gaz", e);
                          }
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={3}
                      style={{ paddingLeft: "10px", marginBottom: "15px" }}
                    >
                      <TextField
                        variant="outlined"
                        size="small"
                        style={{ width: "100%" }}
                        name="c3h6"
                        label="C3H6 *"
                        value={fuelData["gaz"]["volPercentage"]["c3h6"]}
                        error={stepError && !fuelData["gaz"]["volPercentage"]["c3h6"]}
                        type="number"
                        onChange={(e) => {
                          const newValue = e.target.value;
                          if (newValue.length <= 10 && !isNaN(newValue)) {
                            onHandlechange("gaz", e);
                          }
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={3}
                      style={{ paddingLeft: "10px", marginBottom: "15px" }}
                    >
                      <TextField
                        variant="outlined"
                        size="small"
                        style={{ width: "100%" }}
                        name="c3h8"
                        label="C3H8 *"
                        value={fuelData["gaz"]["volPercentage"]["c3h8"]}
                        error={stepError && !fuelData["gaz"]["volPercentage"]["c3h8"]}
                        type="number"
                        onChange={(e) => {
                          const newValue = e.target.value;
                          if (newValue.length <= 10 && !isNaN(newValue)) {
                            onHandlechange("gaz", e);
                          }
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={3}
                      style={{ paddingLeft: "10px", marginBottom: "15px" }}
                    >
                      <TextField
                        variant="outlined"
                        size="small"
                        style={{ width: "100%" }}
                        name="c4h8"
                        label="C4H8 *"
                        value={fuelData["gaz"]["volPercentage"]["c4h8"]}
                        error={stepError && !fuelData["gaz"]["volPercentage"]["c4h8"]}
                        type="number"
                        onChange={(e) => {
                          const newValue = e.target.value;
                          if (newValue.length <= 10 && !isNaN(newValue)) {
                            onHandlechange("gaz", e);
                          }
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={3}
                      style={{ paddingLeft: "10px", marginBottom: "15px" }}
                    >
                      <TextField
                        variant="outlined"
                        size="small"
                        style={{ width: "100%" }}
                        name="c4h10"
                        label="C4H10 *"
                        type="number"
                        value={fuelData["gaz"]["volPercentage"]["c4h10"]}
                        error={stepError &&
                          !fuelData["gaz"]["volPercentage"]["c4h10"]}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          if (newValue.length <= 10 && !isNaN(newValue)) {
                            onHandlechange("gaz", e);
                          }
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={3}
                      style={{ paddingLeft: "10px", marginBottom: "15px" }}
                    >
                      <TextField
                        variant="outlined"
                        size="small"
                        style={{ width: "100%" }}
                        name="c5h12"
                        label="C5H12 *"
                        type="number"
                        value={fuelData["gaz"]["volPercentage"]["c5h12"]}
                        error={stepError && !fuelData["gaz"]["volPercentage"]["c5h12"]}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          if (newValue.length <= 10 && !isNaN(newValue)) {
                            onHandlechange("gaz", e);
                          }
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={3}
                      style={{ paddingLeft: "10px", marginBottom: "15px" }}
                    >
                      <TextField
                        variant="outlined"
                        size="small"
                        style={{ width: "100%" }}
                        name="co2"
                        label="CO2 *"
                        value={fuelData["gaz"]["volPercentage"]["co2"]}
                        error={stepError && !fuelData["gaz"]["volPercentage"]["co2"]}
                        type="number"
                        onChange={(e) => {
                          const newValue = e.target.value;
                          if (newValue.length <= 10 && !isNaN(newValue)) {
                            onHandlechange("gaz", e);
                          }
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={3}
                      style={{ paddingLeft: "10px", marginBottom: "15px" }}
                    >
                      <TextField
                        variant="outlined"
                        size="small"
                        style={{ width: "100%" }}
                        name="n2"
                        label="N2 *"
                        value={fuelData["gaz"]["volPercentage"]["n2"]}
                        error={stepError && !fuelData["gaz"]["volPercentage"]["n2"]}
                        type="number"
                        onChange={(e) => {
                          const newValue = e.target.value;
                          if (newValue.length <= 10 && !isNaN(newValue)) {
                            onHandlechange("gaz", e);
                          }
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={3}
                      style={{ paddingLeft: "10px", marginBottom: "15px" }}
                    >
                      <TextField
                        variant="outlined"
                        size="small"
                        style={{ width: "100%" }}
                        name="o2"
                        label="O2 *"
                        value={fuelData["gaz"]["volPercentage"]["o2"]}
                        error={stepError && !fuelData["gaz"]["volPercentage"]["o2"]}
                        type="number"
                        onChange={(e) => {
                          const newValue = e.target.value;
                          if (newValue.length <= 10 && !isNaN(newValue)) {
                            onHandlechange("gaz", e);
                          }
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              lg={maxWidth === "sm" ? 12 : 6}
              xl={maxWidth === "sm" ? 12 : 6}
            >
              {fuelType.includes("1") && (
                <Grid container columns={maxWidth === "sm" ? 16 : 8}>
                  <CustomText
                    value="Heavy Oil Composition [wt%] "
                    variant="subtitle1"
                    style={{ color: "#2D2D2D", padding: "0 10px 20px" }}
                    align="left"
                  />
                  <Grid container style={{}}>
                    <Grid
                      item
                      xs={6}
                      md={4}
                      style={{ paddingLeft: "10px", marginBottom: "15px" }}
                    >
                      <TextField
                        variant="outlined"
                        size="small"
                        style={{ width: "100%" }}
                        name="carbon"
                        label="Carbon *"
                        value={fuelData["oil"]["analysisPercMass"]["carbon"]}
                        error={stepError && !fuelData["oil"]["analysisPercMass"]["carbon"]}
                        type="number"
                        onChange={(e) => {
                          const newValue = e.target.value;
                          if (newValue.length <= 10 && !isNaN(newValue)) {
                            onHandlechange("oil", e);
                          }
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={4}
                      style={{ paddingLeft: "10px", marginBottom: "15px" }}
                    >
                      <TextField
                        variant="outlined"
                        size="small"
                        style={{ width: "100%" }}
                        name="hydrogen"
                        label="Hydrogen *"
                        value={fuelData["oil"]["analysisPercMass"]["hydrogen"]}
                        error={stepError && !fuelData["oil"]["analysisPercMass"]["hydrogen"]}
                        type="number"
                        onChange={(e) => {
                          const newValue = e.target.value;
                          if (newValue.length <= 10 && !isNaN(newValue)) {
                            onHandlechange("oil", e);
                          }
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={4}
                      style={{ paddingLeft: "10px", marginBottom: "15px" }}
                    >
                      <TextField
                        variant="outlined"
                        size="small"
                        style={{ width: "100%" }}
                        name="oxygen"
                        label="Oxygen *"
                        value={fuelData["oil"]["analysisPercMass"]["oxygen"]}
                        error={stepError && !fuelData["oil"]["analysisPercMass"]["oxygen"]}
                        type="number"
                        onChange={(e) => {
                          const newValue = e.target.value;
                          if (newValue.length <= 10 && !isNaN(newValue)) {
                            onHandlechange("oil", e);
                          }
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={4}
                      style={{ paddingLeft: "10px", marginBottom: "15px" }}
                    >
                      <TextField
                        variant="outlined"
                        size="small"
                        style={{ width: "100%" }}
                        name="nitrogen"
                        label="Nitrogen *"
                        value={fuelData["oil"]["analysisPercMass"]["nitrogen"]}
                        error={stepError && !fuelData["oil"]["analysisPercMass"]["nitrogen"]}
                        type="number"
                        onChange={(e) => {
                          const newValue = e.target.value;
                          if (newValue.length <= 10 && !isNaN(newValue)) {
                            onHandlechange("oil", e);
                          }
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={4}
                      style={{ paddingLeft: "10px", marginBottom: "15px" }}
                    >
                      <TextField
                        variant="outlined"
                        size="small"
                        style={{ width: "100%" }}
                        name="sulphur"
                        label="Sulphur *"
                        value={fuelData["oil"]["analysisPercMass"]["sulphur"]}
                        error={stepError && !fuelData["oil"]["analysisPercMass"]["sulphur"]}
                        type="number"
                        onChange={(e) => {
                          const newValue = e.target.value;
                          if (newValue.length <= 10 && !isNaN(newValue)) {
                            onHandlechange("oil", e);
                          }
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={4}
                      style={{ paddingLeft: "10px", marginBottom: "15px" }}
                    >
                      <TextField
                        variant="outlined"
                        size="small"
                        style={{ width: "100%" }}
                        name="humidity"
                        label="Humidity *"
                        value={fuelData["oil"]["analysisPercMass"]["humidity"]}
                        error={stepError && !fuelData["oil"]["analysisPercMass"]["humidity"]}
                        type="number"
                        onChange={(e) => {
                          const newValue = e.target.value;
                          if (newValue.length <= 10 && !isNaN(newValue)) {
                            onHandlechange("oil", e);
                          }
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid container>
            {fuelType.includes("0") && (
              <Grid
                style={{ paddingLeft: "10px" }}
                item
                xs={fuelType.length === 2 ? 12 : 12}
                md={fuelType.length === 2 ? 6 : 12}
              >
                {numberOfPorts > 0 && (
                  <React.Fragment>
                    <CustomText
                      value="Consumption Gas [Nm3/h] *"
                      variant="subtitle1"
                      style={{
                        color: "#2D2D2D",
                        marginTop: "10px",
                        marginButtom: "10px",
                      }}
                      align="left"
                    />
                    <Grid container>
                      {chamberType === "Both Side" ? (
                        <React.Fragment>
                          <Grid item xs={colLength} md={colLength}>
                            <CustomText
                              value="Left *"
                              variant="subtitle1"
                              align="left"
                              style={{ marginBottom: "10px" }}
                            />
                          </Grid>
                          <Grid item xs={6} md={6}>
                            <CustomText
                              style={{
                                marginBottom: "10px",
                                paddingLeft: "10px",
                              }}
                              value="Right *"
                              variant="subtitle1"
                              align="left"
                            />
                          </Grid>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <Grid item xs={colLength} md={colLength}>
                            <CustomText
                              value=" "
                              variant="subtitle1"
                              align="left"
                              style={{ marginBottom: "10px" }}
                            />
                          </Grid>
                          <Grid item xs={6} md={6}>
                            <CustomText
                              value=" "
                              variant="subtitle1"
                              align="left"
                            />
                          </Grid>
                        </React.Fragment>
                      )}
                    </Grid>
                    <Grid container>{getNumberOfPorts("gaz")}</Grid>
                  </React.Fragment>
                )}
              </Grid>
            )}
            {fuelType.includes("1") && (
              <Grid
                style={{ paddingLeft: "10px" }}
                item
                xs={fuelType.length === 2 ? 12 : 12}
                md={fuelType.length === 2 ? 6 : 12}
              >
                {numberOfPorts > 0 && (
                  <React.Fragment>
                    <CustomText
                      value="Consumption Oil [kg/h] *"
                      variant="subtitle1"
                      style={{
                        color: "#2D2D2D",
                        marginTop: "10px",
                        marginButtom: "10px",
                      }}
                      align="left"
                    />
                    <Grid container>
                      {chamberType === "Both Side" ? (
                        <React.Fragment>
                          <Grid item xs={colLength} md={colLength}>
                            <CustomText
                              value="Left *"
                              variant="subtitle1"
                              align="left"
                              style={{ marginBottom: "10px" }}
                            />
                          </Grid>
                          <Grid item xs={6} md={6}>
                            <CustomText
                              style={{
                                marginBottom: "10px",
                                paddingLeft: "10px",
                              }}
                              value="Right *"
                              variant="subtitle1"
                              align="left"
                            />
                          </Grid>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          <Grid item xs={colLength} md={colLength}>
                            <CustomText
                              value=" "
                              variant="subtitle1"
                              align="left"
                              style={{
                                marginLeft: "10px",
                                marginBottom: "10px",
                              }}
                            />
                          </Grid>
                          <Grid item xs={6} md={6}>
                            <CustomText
                              value=" "
                              variant="subtitle1"
                              align="left"
                            />
                          </Grid>
                        </React.Fragment>
                      )}
                    </Grid>
                    <Grid container>{getNumberOfPorts("oil")}</Grid>
                  </React.Fragment>
                )}
              </Grid>
            )}
          </Grid>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default FuelPropertiesPopup;