import React from "react";
import {
  Avatar,
  Typography,
  Grid,
} from "@mui/material";

const LeftContainer = () => {
  const userDetails = JSON.parse(localStorage.getItem("user"));

  let zone = userDetails.zone_id;
  let zoneName = "";
  if (zone === 1) {
    zoneName = "America";
  } else if (zone === 2) {
    zoneName = "Asia";
  } else if (zone === 3) {
    zoneName = "Europe";
  } else {
    zoneName = "Central";
  }

  let role = userDetails.role_id;
  let roleName = "";
  if (role === 1) {
    roleName = "Super Admin";
  } else if (role === 2) {
    roleName = "Admin";
  } else {
    roleName = "User";
  }

  return (
    <React.Fragment>
      <div>
        <Grid container style={{ padding: "20px", border: "1px solid #ccc" }}>
          <Grid style={{ margin: "auto", padding: "0 20px" }}>
            <Avatar
              alt="Remy Sharp"
              src="/broken-image.jpg"
              style={{
                width: "150px",
                height: "150px",
                borderRadius: "50%",
                textAlign: "center",
                backgroundColor: "#203468",
                fontSize: "32px"
              }}
            >
              {userDetails ? userDetails.first_name.charAt(0) + userDetails.last_name.charAt(0) : "--"}
            </Avatar>
          </Grid>
          <Grid
            container
            sm
            style={{
              padding: "20px 0",
              justifyContent: "space-around",
              alignItems: "flex-start",
            }}
          >
            <Grid md={4} sm={6} xs={12}>
              <Typography
                sx={{ fontSize: 16, lineHeight: "1.2" }}
                variant="subtitle1"
                color="text.secondary"
                gutterBottom
              >
                Name
              </Typography>
              <Typography
                variant="subtitle1"
                component="div"
                style={{ fontWeight: 700 }}
              >
                {userDetails.first_name + " " + userDetails.last_name} <br />
              </Typography>
              <Typography
                sx={{ fontSize: 16, lineHeight: "1.2", marginTop: "15px" }}
                variant="subtitle1"
                color="text.secondary"
                gutterBottom
              >
                SG ID
              </Typography>
              <Typography
                variant="subtitle1"
                component="div"
                style={{ fontWeight: 700 }}
              >
                {userDetails.sgid}
              </Typography>
            </Grid>
            <Grid md={4} sm={6} xs={12}>
              <Typography
                sx={{ fontSize: 16, lineHeight: "1.2", marginTop: "15px" }}
                variant="subtitle1"
                color="text.secondary"
                gutterBottom
              >
                Zone
              </Typography>

              <Typography
                variant="subtitle1"
                component="div"
                style={{ fontWeight: 700 }}
              >
                {zone ? zoneName : "--"}
              </Typography>
              <Typography
                sx={{ fontSize: 16, lineHeight: "1.2", marginTop: "15px" }}
                variant="subtitle1"
                color="text.secondary"
                gutterBottom
              >
                Role
              </Typography>
              <Typography
                variant="subtitle1"
                component="div"
                style={{ fontWeight: 700 }}
              >
                {role ? roleName : "--"}
              </Typography>
            </Grid>
            <Grid md={4} xs={12}>
              <Typography
                sx={{ fontSize: 16, lineHeight: "1.2", marginTop: "15px" }}
                variant="subtitle1"
                color="text.secondary"
                gutterBottom
              >
                E-mail ID
              </Typography>
              <Typography
                variant="subtitle1"
                component="div"
                style={{ fontWeight: 700 }}
              >
                {userDetails.email_id}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};

export default LeftContainer;