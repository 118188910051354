import React, { useEffect, useState, useRef } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  Button,
  FormControl,
  TextField,
} from "@mui/material";
import CustomSelect from "../../components/ui/SelectBox";
import axios from "axios";
import API from "../Services/API";

const UserForm = (props) => {
  const [zone, setZone] = useState([]);
  const [role, setRole] = useState([]);
  const [zoneId, setZoneId] = useState("");
  const [roleId, setRoleId] = useState("");
  const [error, setError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const firstNameRef = useRef();
  const lastNameRef = useRef();
  const emailRef = useRef();
  const sgidRef = useRef();
  const zoneIdRef = useRef();
  const roleIdRef = useRef();
  const userDetails = localStorage.getItem("user");
  const userValue = JSON.parse(userDetails);

  const insertUserDetails = async (
    first_name,
    last_name,
    email_id,
    sgid,
    zone_id,
    role_id,
    created_by,
    updated_at
  ) => {
    try {
      const formInsertData = new FormData();
      formInsertData.append("first_name", first_name);
      formInsertData.append("last_name", last_name);
      formInsertData.append("email_id", email_id);
      formInsertData.append("sgid", sgid);
      formInsertData.append("zone_id", zone_id);
      formInsertData.append("role_id", role_id);
      formInsertData.append("created_by", created_by);
      formInsertData.append("updated_at", updated_at);
      await axios.post(API.INSERT_USER, formInsertData, {
        headers: {
          authorization: userValue.web_token,
          'Content-Type': 'application/json'
        }
      }).then((res) => {
        if (res.status === 200) {
          props.setStatus(true);
          props.handleClose();
          props.onHandleAlert();
        }
      }).catch((e) => {
        console.log("ERROR", e);
      });
    } catch (e) {
      console.log("ERROR", e);
    }
  };

  const updateUserDetails = async (
    first_name,
    last_name,
    email_id,
    sgid,
    zone_id,
    role_id,
    created_by,
    updated_at,
  ) => {
    try {
      const formUpdateData = new FormData();
      formUpdateData.append("first_name", first_name);
      formUpdateData.append("last_name", last_name);
      formUpdateData.append("email_id", email_id);
      formUpdateData.append("sgid", sgid);
      formUpdateData.append("zone_id", zone_id);
      formUpdateData.append("role_id", role_id);
      formUpdateData.append("created_by", created_by);
      formUpdateData.append("updated_at", updated_at);
      formUpdateData.append("user_id", props.data.id);
      await axios.put(API.UPDATE_USER, formUpdateData, {
        headers: {
          authorization: userValue.web_token,
          'Content-Type': 'application/json'
        }
      }).then((res) => {
        if (res.status === 200) {
          props.setStatus(true);
          props.handleClose();
          props.onHandleAlert();
        }
      }).catch((e) => {
        console.log("ERROR", e);
      });
    } catch (e) {
      console.log("ERROR", e);
    }
  };

  useEffect(() => {
    axios
      .get(API.GET_ROLE_LIST, {
        headers: {
          authorization: userValue.web_token
        }
      })
      .then(function (response) {
        // handle success
        if (response.status === 200 && response.data?.data?.roles?.length > 0) {
          const roles = response.data.data.roles;
          console.log(roles);
          setRole(roles);
          props.setStatus(true);
          props.handleClose();
        } else {
          setRole([]);
        }
      })
      .catch(function (e) {
        console.log("ERROR", e);
      });

    axios
      .get(API.GET_ZONE_LIST, {
        headers: {
          authorization: userValue.web_token
        }
      })
      .then(function (response) {
        // handle success
        if (response.status === 200 && response.data?.data?.zone?.length > 0) {
          const zone = response.data.data.zone;
          setZone(zone);
          props.setStatus(true);
          props.handleClose();
        } else {
          setZone([]);
        }
      })
      .catch(function (e) {
        console.log("ERROR", e);
      });
  }, []);

  const onChangeValue = (event) => {
    if (event.target.name === "zone_id") {
      setZoneId(event.target.value);
    } else {
      setRoleId(event.target.value);
    }
  };

  const onHandleSave = () => {
    if (
      firstNameRef.current.value === "" ||
      lastNameRef.current.value === "" ||
      emailRef.current.value === "" ||
      zoneId === "" ||
      roleId === ""
    ) {
      setError(true);
      return true;
    } else {
      setError(false);
    }
    
    const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    
    if (!regex.test(emailRef.current.value)) {
      setEmailError(true);
      return true;
    } else {
      setEmailError(false);
    }

    let userData = JSON.parse(localStorage.getItem("user"));
    if (!props.data.id) {
      insertUserDetails(
        firstNameRef.current.value,
        lastNameRef.current.value,
        emailRef.current.value,
        sgidRef.current.value,
        zoneId,
        roleId,
        userData.created_by,
        new Date().toISOString()
      );
    } else {
      updateUserDetails(
        firstNameRef.current.value,
        lastNameRef.current.value,
        emailRef.current.value,
        sgidRef.current.value,
        zoneId,
        roleId,
        userData.created_by,
        new Date().toISOString()
      );
    }
  };

  const setInitialFieldValue = () => {
    if (firstNameRef.current) {
      firstNameRef.current.value = props.data.first_name
        ? props.data.first_name
        : "";
    }
    if (lastNameRef.current) {
      lastNameRef.current.value = props.data.last_name
        ? props.data.last_name
        : "";
    }
    if (emailRef.current) {
      emailRef.current.value = props.data.email_id ? props.data.email_id : "";
    }
    if (sgidRef.current) {
      sgidRef.current.value = props.data.sgid ? props.data.sgid : "";
    }
    setZoneId(props.data.zone_id ? props.data.zone_id : "");
    setRoleId(props.data.role_id ? props.data.role_id : "");
  };

  useEffect(() => {
    setError(false);
    setEmailError(false);
    setTimeout(function () {
      setInitialFieldValue();
      props.setStatus(false);
    }, 5);
  }, [props.open]);

  const handleInput = (event, setErrorMessage) => {
    const inputValue = event.target.value;
    const alphabeticValue = inputValue.replace(/[^A-Za-z\s]/g, '');
    event.target.value = alphabeticValue;
    if (inputValue !== alphabeticValue) {
      setErrorMessage('Only alphabets are allowed');
    } else {
      setErrorMessage('');
    }
    if (props.onChange) {
      props.onChange(event);
    }
  };

  return (
    <div>
      <Dialog
        onClose={props.handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.open}
        fullWidth={true}
      >
        <DialogTitle id="customized-dialog-title" onClose={props.handleClose}>
          {props.type}
        </DialogTitle>
        <DialogContent dividers>
          <div>
            <TextField
              variant="outlined"
              size="medium"
              label="First Name"
              name="first_name"
              style={{ width: "100%" }}
              onChange={props.onChange}
              onInput={(event) => handleInput(event, setFirstNameError)}
              inputRef={firstNameRef}
            />
            {firstNameError && <p style={{ color: 'red' }}>{firstNameError}</p>}
          </div>
          <div style={{ marginTop: 20 }}>
            <TextField
              variant="outlined"
              size="medium"
              label="Last Name"
              name="last_name"
              style={{ width: "100%" }}
              onChange={props.onChange}
              onInput={(event) => handleInput(event, setLastNameError)}
              inputRef={lastNameRef}
            />
            {lastNameError && <p style={{ color: 'red' }}>{lastNameError}</p>}
          </div>
          <div style={{ marginTop: 20 }}>
            <TextField
              variant="outlined"
              size="medium"
              label="Email"
              name="email_id"
              style={{ width: "100%" }}
              onChange={props.onChange}
              inputRef={emailRef}
            />
          </div>
          <div style={{ marginTop: 20 }}>
            <TextField
              variant="outlined"
              size="medium"
              label="SGID"
              name="sgid"
              style={{ width: "100%" }}
              onChange={props.onChange}
              inputRef={sgidRef}
            />
          </div>
          <div style={{ marginTop: 20 }}>
            <FormControl fullWidth className="customerinfo-formcontrol">
              <CustomSelect
                label="Zone"
                name="zone_id"
                value={zoneId}
                inputRef={zoneIdRef}
                isMArray={true}
                datakey="id"
                dataValue="name"
                onChange={onChangeValue}
                options={zone}
              />
            </FormControl>
          </div>
          <div style={{ marginTop: 20 }}>
            <FormControl fullWidth className="customerinfo-formcontrol">
              <CustomSelect
                label="Role"
                name="role_id"
                value={roleId}
                inputRef={roleIdRef}
                isMArray={true}
                datakey="id"
                dataValue="role"
                onChange={onChangeValue}
                options={role}
              />
            </FormControl>
          </div>
          {error ? (
            <Typography variant="subtitle2" color="red">
              {""}
              Please fill all the fields
            </Typography>
          ) : null}
          {emailError ? (
            <Typography variant="subtitle2" color="red">
              {""}
              Invalid Email Id
            </Typography>
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            variant="contained"
            style={{
              backgroundColor: "#203468",
            }}
            onClick={onHandleSave}
          >
            Save
          </Button>
          <Button
            variant="outlined"
            style={{
              borderColor: "#203468",
              color: "#203468",
            }}
            onClick={() => {
              setError(false);
              setEmailError(false);
              props.handleClose();
            }}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default UserForm;