import React from "react";
import { Typography, Grid, Card } from "@mui/material";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";

const Cards = ({
  trialsData,
  totalTrials,
  americaTrials,
  asiaTrials,
  europeTrials,
  centralTrials,
}) => {
  const valueMap = {
    "Total Trials": totalTrials,
    "America": americaTrials,
    "Asia": asiaTrials,
    "Europe": europeTrials,
    "Central": centralTrials,
  };
  return (
    <Grid
      container
      spacing={2}
      style={{ display: "flex", marginBottom: "10px" }}
    >
      {trialsData.map((e) => (
        <Grid item key={e.id} md sm={6} xs={12}>
          <Card style={{ padding: "16px", background: "#0F0866" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                style={{ fontSize: 14, fontWeight: 400, color: "#fff" }}
              >
                {e.name}
              </Typography>
              <TrendingUpIcon style={{ color: "white" }} />
            </div>
            <div>
              <div style={{ float: "left", paddingRight: 7 }}>
                <Typography
                  style={{ fontSize: 28, fontWeight: 400, color: "#fff" }}
                >
                  {valueMap[e.name] || e.value}
                </Typography>
              </div>
            </div>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default Cards;