import React, { useState } from "react";
import {
  Box,
  Card,
  IconButton,
  Grid,
  CardHeader,
  CardContent,
} from "@mui/material";
import { ReactComponent as Edit } from "../../../../assets/icons/Edit.svg";
import FuelPropertiesPopup from "./FuelPropertiesPopup";
import BatchData from "./BatchData";
import RegeneratorData from "./RegeneratorData";
import {
  errorSection
} from "../../../../recoil/atoms";
import { useRecoilState } from "recoil";

const Flows = () => {
  const [open, setOpen] = useState(false);
  const [openBatchData, setOpenBatchData] = useState(false);
  const [openRegeneratorData, setOpenRegeneratorData] = useState(false);
  const [sectionError] = useRecoilState(errorSection);


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenBatchData(false);
    setOpenRegeneratorData(false);
  };

  const handleClickOpenBatchData = () => {
    setOpenBatchData(true);
  };

  const handleClickOpenRegeneratorData = () => {
    setOpenRegeneratorData(true);
  };
  return (
    <div>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2} justifyContent={"space-around"}>
          <Grid item md={3} xl={3} xs={12}>
            <Card variant="outlined">
              <CardHeader
                action={
                  <IconButton aria-label="settings" onClick={handleClickOpen}>
                    <Edit />
                  </IconButton>
                }
                style={{
                  fontSize: "18px",
                  fontWeight: 400,
                  borderBottom: "1px solid #0000001f",
                  backgroundColor: sectionError === "fuel" ? "red" : ""
                }}
                title="Fuel Properties"
              />
              <CardContent>
                <p style={{ marginTop: 0 }}>
                  Fuel composition & consumption
                </p>
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={3} xl={3} xs={12}>
            <Card variant="outlined">
              <CardHeader
                action={
                  <IconButton
                    aria-label="settings"
                    onClick={handleClickOpenBatchData}
                  >
                    <Edit />
                  </IconButton>
                }
                style={{
                  marginTop: "0px",
                  fontSize: "18px",
                  fontWeight: 400,
                  borderBottom: "1px solid #0000001f",
                  backgroundColor: sectionError === "batch" ? "red" : ""
                }}
                title="Batch Data"
              />
              <CardContent>
                <p style={{ marginTop: 0 }}>
                  Batch composition and fumes distribution
                </p>
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={3} xl={3} xs={12}>
            <Card variant="outlined">
              <CardHeader
                action={
                  <IconButton
                    aria-label="settings"
                    onClick={handleClickOpenRegeneratorData}
                  >
                    <Edit />
                  </IconButton>
                }
                style={{
                  marginTop: "0px",
                  fontSize: "18px",
                  fontWeight: 400,
                  borderBottom: "1px solid #0000001f",
                  backgroundColor: sectionError === "regenerator" ? "red" : ""
                }}
                title="Regenerator Design"
              />
              <CardContent>
                <p style={{ marginTop: 0 }}>
                  Chamber & Packing Dimensions
                </p>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <FuelPropertiesPopup open={open} handleClose={handleClose} />
      <BatchData open={openBatchData} handleClose={handleClose} />
      <RegeneratorData open={openRegeneratorData} handleClose={handleClose} />
    </div>
  );
};

export default Flows;