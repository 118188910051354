import React from "react";
import MUIDataTable from "mui-datatables";

const DataTable = ({
  data,
  columns,
  title,
  onRowClick,
  rowCount,
  noPagination,
  noRowsPerPage,
}) => {
  let tableOptions = {
    elevation: 0,
    filterType: "checkbox",
    selectableRows: false,
    onRowClick: onRowClick,
    filter: false,
    print: false,
    download: false,
    search: true,
    viewColumns: false,
  };

  if (!noPagination)
    tableOptions = {
      ...tableOptions,
      rowsPerPage: rowCount || 10,
      rowsPerPageOptions: noRowsPerPage ? [] : [3, 5, 10],
    };

  return (
    <MUIDataTable
      options={tableOptions}
      title={title}
      data={data}
      columns={columns}
    />
  );
};

export default DataTable;