import React from "react";
import {
  Box,
  FormControl,
  Select,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";

export default function CustomSelect(props) {
  const renderMenuItems = () => {
    return props.options.map((data) => {
      const key = props.isMArray && props.datakey ? data[props.datakey] : data;
      const id = props.isMArray && props.datakey ? data[props.datakey] : data;
      const value = props.isMArray && props.datakey ? data[props.datakey] : data;
      const displayValue = props.isMArray && props.dataValue ? data[props.dataValue] : data;
      return (
        <MenuItem key={key} id={id} value={value}>
          {displayValue}
        </MenuItem>
      );
    });
  };

  return (
    <Box sx={{ minWidth: 120 }}>
      <FormControl style={{ width: "100%" }}>
        <InputLabel id="demo-simple-select-label">{props.label}</InputLabel>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          label={props.label}
          name={props.name}
          onChange={props.onChange}
          value={props.value}
          error={props.error ? props.error : false}
          variant="outlined"
          size="medium"
        >
          {props.options ? renderMenuItems() : null}
        </Select>
        <FormHelperText>{props.helperText}</FormHelperText>
      </FormControl>
    </Box>
  );
}