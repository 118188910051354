import React from "react";
import ReactApexChart from "react-apexcharts";

const PieChart = (data) => {
  // Safely handle undefined or empty series
  const { series } = data;
  if (!Array.isArray(series) || series.length === 0) {
    return <div>No data available</div>; // Show a fallback message if no data
  }

  // Aggregate the total_trials per zone
  const zoneData = series.reduce((acc, item) => {
    const existingZone = acc.find((z) => z.zone_name === item.zone_name);
    if (existingZone) {
      existingZone.total_trials += Number(item.total_trials); // Add total_trials for the same zone
    } else {
      acc.push({
        zone_name: item.zone_name,
        total_trials: Number(item.total_trials),
      });
    }
    return acc;
  }, []);

  const chartData = {
    series: zoneData.map((item) => item.total_trials),
    options: {
      chart: {
        type: "pie",
      },
      title: {
        text: "Zone-wise Trials Data",
        style: {
          fontSize: "14px",
          fontWeight: "550",
        },
      },
      labels: zoneData.map((item) => item.zone_name),
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: "100%",
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      colors: ["#F6AE2D", "#0A78A1", "#00A7A4", "#ED5958"],
    },
  };

  return (
    <ReactApexChart
      options={chartData.options}
      series={chartData.series}
      type="pie"
      height={350}
    />
  );
};

export default PieChart;
