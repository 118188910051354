import React, { useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CustomText from "../../../../components/ui/Typography";
import { FormControl, Container, TextField } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useRecoilState } from "recoil";
import {
  customerInfoData,
  furnaceDetails,
  error,
  newVisitStatus,
} from "../../../../recoil/atoms";
import configParam from "../../../../config";
import CloseIcon from "@mui/icons-material/Close";

const RegeneratorData = (props) => {
  const deepCopy = configParam.DEEP_COPY;
  const [customerInfo] = useRecoilState(customerInfoData);
  const [furnaceData, setFurnaceData] = useRecoilState(furnaceDetails);
  const numberOfPorts =
    customerInfo.furnaceType === "End Fired" ? 0 : customerInfo.numberOfPorts;
  const [stepError] = useRecoilState(error);
  const [visitStatus] = useRecoilState(newVisitStatus);

  const sgid = localStorage.getItem("UserID");
  const getItemNo = configParam.getVisitNumbers;
  const myRegenFeatures = localStorage.getItem("myRegenVisitData");
  let data = myRegenFeatures ? JSON.parse(myRegenFeatures) : {};

  const getNumberOfPorts = () => {
    const arr = [];
    for (let i = 0; i < numberOfPorts; i++) {
      let id = i + 1;
      const depthValue =
        customerInfo.furnaceType === "End Fired"
          ? furnaceData["regeneratorDesign"]["chamberDimensionsEFF"]["depth"]
          : furnaceData["regeneratorDesign"]["chamberDimensionsCFF"]["depth"][id];
      const widthValue =
        customerInfo.furnaceType === "End Fired"
          ? furnaceData["regeneratorDesign"]["chamberDimensionsEFF"]["width"]
          : furnaceData["regeneratorDesign"]["chamberDimensionsCFF"]["width"][id];
      arr.push(
        <React.Fragment>
          <Grid item xs={6}>
            <FormControl fullWidth={true}>
              <TextField
                variant="outlined"
                size="small"
                name={id}
                label={id + " Depth [mm] *"}
                type="number"
                value={depthValue}
                error={
                  stepError && !depthValue}
                onChange={(e) => {
                  const newValue = e.target.value;
                  if (newValue.length <= 10 && !isNaN(newValue)) {
                    onHandleChange("depth", e);
                  }
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth={true}>
              <TextField
                variant="outlined"
                size="small"
                name={id}
                label={id + " Width [mm] *"}
                type="number"
                value={widthValue}
                error={
                  stepError && !widthValue}
                onChange={(e) => {
                  const newValue = e.target.value;
                  if (newValue.length <= 10 && !isNaN(newValue)) {
                    onHandleChange("width", e);
                  }
                }}
              />
            </FormControl>
          </Grid>
        </React.Fragment>
      );
    }
    return arr;
  };

  const getNumberOfPortsofPacking = () => {
    const arr = [];
    for (let i = 0; i < numberOfPorts; i++) {
      let id = i + 1;
      const thicknessValue =
        customerInfo.furnaceType === "End Fired"
          ? furnaceData["regeneratorDesign"]["packingPropertiesEFF"]["thickness"]
          : furnaceData["regeneratorDesign"]["packingPropertiesCFF"]["thickness"][id];
      const sizeValue =
        customerInfo.furnaceType === "End Fired"
          ? furnaceData["regeneratorDesign"]["packingPropertiesEFF"]["size"]
          : furnaceData["regeneratorDesign"]["packingPropertiesCFF"]["size"][id];
      arr.push(
        <React.Fragment>
          <Grid item xs={6}>
            <FormControl fullWidth={true}>
              <TextField
                variant="outlined"
                size="small"
                name={id}
                label={id + " Checkers thickness [mm] *"}
                value={thicknessValue}
                error={
                  stepError && !thicknessValue}
                type="number"
                onChange={(e) => {
                  const newValue = e.target.value;
                  if (newValue.length <= 10 && !isNaN(newValue)) {
                    onHandlePackingProperties("thickness", e);
                  }
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth={true}>
              <TextField
                variant="outlined"
                size="small"
                name={id}
                label={id + " Channel size [mm] *"}
                value={sizeValue}
                error={
                  stepError && !sizeValue}
                type="number"
                onChange={(e) => {
                  const newValue = e.target.value;
                  if (newValue.length <= 10 && !isNaN(newValue)) {
                    onHandlePackingProperties("size", e);
                  }
                }}
              />
            </FormControl>
          </Grid>
        </React.Fragment>
      );
    }
    return arr;
  };

  const getNumberOfPortsofChannels = () => {
    const arr = [];
    for (let i = 0; i < numberOfPorts; i++) {
      let id = i + 1;
      const depthChannelsValue =
        customerInfo.furnaceType === "End Fired"
          ? furnaceData["regeneratorDesign"]["noOfChannelsEFF"]["depthChannels"]
          : furnaceData["regeneratorDesign"]["noOfChannelsCFF"]["depthChannels"][id];
      const widthChannelsValue =
        customerInfo.furnaceType === "End Fired"
          ? furnaceData["regeneratorDesign"]["noOfChannelsEFF"]["widthChannels"]
          : furnaceData["regeneratorDesign"]["noOfChannelsCFF"]["widthChannels"][id];
      arr.push(
        <React.Fragment>
          <Grid item xs={6}>
            <FormControl fullWidth={true}>
              <TextField
                variant="outlined"
                size="small"
                name={id}
                label={id + " Depth [mm] *"}
                type="number"
                value={depthChannelsValue}
                error={
                  stepError && !depthChannelsValue}
                onChange={(e) => {
                  const newValue = e.target.value;
                  if (newValue.length <= 10 && !isNaN(newValue)) {
                    onHandleNoOfChannels("depthChannels", e);
                  }
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth={true}>
              <TextField
                variant="outlined"
                size="small"
                name={id}
                label={id + " Width [mm] *"}
                type="number"
                value={widthChannelsValue}
                error={
                  stepError && !widthChannelsValue}
                onChange={(e) => {
                  const newValue = e.target.value;
                  if (newValue.length <= 10 && !isNaN(newValue)) {
                    onHandleNoOfChannels("widthChannels", e);
                  }
                }}
              />
            </FormControl>
          </Grid>
        </React.Fragment>
      );
    }
    return arr;
  };

  const onHandleChange = (type, event) => {
    let tmpObj = deepCopy(furnaceData);
    if (customerInfo.furnaceType === "End Fired") {
      tmpObj["regeneratorDesign"]["chamberDimensionsEFF"][type] =
        event.target.value;
    } else {
      tmpObj["regeneratorDesign"]["chamberDimensionsCFF"][type][
        event.target.name
      ] = event.target.value;
    }
    setFurnaceData(tmpObj);
    let rowIndex = getItemNo();
    let obj = { furnaceDetails: furnaceData };
    let merged = { ...data[sgid][rowIndex || 0], ...obj };
    data[sgid][rowIndex] = merged;
    localStorage.setItem("myRegenVisitData", JSON.stringify(data));
  };

  const onHandlePackingProperties = (type, event) => {
    let tmpObj = deepCopy(furnaceData);
    if (customerInfo.furnaceType === "End Fired") {
      tmpObj["regeneratorDesign"]["packingPropertiesEFF"][type] =
        event.target.value;
    } else {
      tmpObj["regeneratorDesign"]["packingPropertiesCFF"][type][
        event.target.name
      ] = event.target.value;
    }
    setFurnaceData(tmpObj);
    let rowIndex = getItemNo();
    let obj = { furnaceDetails: furnaceData };
    let merged = { ...data[sgid][rowIndex || 0], ...obj };
    data[sgid][rowIndex] = merged;
    localStorage.setItem("myRegenVisitData", JSON.stringify(data));
  };

  const onHandleNoOfChannels = (type, event) => {
    let tmpObj = deepCopy(furnaceData);
    if (customerInfo.furnaceType === "End Fired") {
      tmpObj["regeneratorDesign"]["noOfChannelsEFF"][type] =
        event.target.value;
    } else {
      tmpObj["regeneratorDesign"]["noOfChannelsCFF"][type][
        event.target.name
      ] = event.target.value;
    }
    setFurnaceData(tmpObj);
    let rowIndex = getItemNo();
    let obj = { furnaceDetails: furnaceData };
    let merged = { ...data[sgid][rowIndex || 0], ...obj };
    data[sgid][rowIndex] = merged;
    localStorage.setItem("myRegenVisitData", JSON.stringify(data));
  };

  function onHandleChamberDimensions(type, event) {
    let tmpObj = deepCopy(furnaceData);
    if (type === "depth" || type === "width") {
      if (customerInfo.furnaceType === "End Fired") {
        tmpObj["regeneratorDesign"]["chamberDimensionsEFF"][type] =
          event.target.value;
      } else {
        tmpObj["regeneratorDesign"]["chamberDimensionsCFF"][type][
          event.target.name
        ] = event.target.value;
      }
    } else if (type === "thickness" || type === "size") {
      if (customerInfo.furnaceType === "End Fired") {
        tmpObj["regeneratorDesign"]["packingPropertiesEFF"][type] =
          event.target.value;
      } else {
        tmpObj["regeneratorDesign"]["packingPropertiesCFF"][type][
          event.target.name
        ] = event.target.value;
      }
    } else if (type === "depthChannels" || type === "widthChannels") {
      if (customerInfo.furnaceType === "End Fired") {
        tmpObj["regeneratorDesign"]["noOfChannelsEFF"][type] =
          event.target.value;
      } else {
        tmpObj["regeneratorDesign"]["noOfChannelsCFF"][type][
          event.target.name
        ] = event.target.value;
      }
    }
    setFurnaceData(tmpObj);
  }

  useEffect(() => {
    if (visitStatus) {
      let rowIndex = getItemNo();
      let obj = { furnaceDetails: furnaceData };
      let merged = { ...data[sgid][rowIndex || 0], ...obj };
      data[sgid][rowIndex] = merged;
      localStorage.setItem("myRegenVisitData", JSON.stringify(data));
    }
  });

  return (
    <Dialog open={props.open} onClose={props.handleClose} fullWidth={true}>
      <DialogTitle>
        Regenerator Design
        <CloseIcon
          sx={{
            position: "absolute",
            right: 20,
            top: 13,
            cursor: "pointer"
          }}
          onClick={props.handleClose}
        />
      </DialogTitle>

      <DialogContent
        style={{ borderTop: "1px solid #a8a8a8", marginTop: "-10px" }}
      >
        <DialogContentText>
          <Container>
            <CustomText
              value="Chamber dimensions"
              variant="subtitle1"
              style={{ color: "#2D2D2D", margin: "10px 0px 10px 0px" }}
              align="left"
            />
            <Grid container spacing={2}>
              {customerInfo.furnaceType === "End Fired" ? (
                <React.Fragment>
                  <Grid item xs={6}>
                    <FormControl fullWidth={true}>
                      <TextField
                        variant="outlined"
                        size="small"
                        name="depth"
                        label={"Depth [mm] *"}
                        value={
                          furnaceData?.regeneratorDesign?.chamberDimensionsEFF
                            ?.depth
                        }
                        error={
                          stepError &&
                          !furnaceData?.regeneratorDesign?.chamberDimensionsEFF
                            ?.depth}
                        type="number"
                        onChange={(e) => {
                          const newValue = e.target.value;
                          if (newValue.length <= 10 && !isNaN(newValue)) {
                            onHandleChamberDimensions("depth", e);
                          }
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth={true}>
                      <TextField
                        variant="outlined"
                        size="small"
                        name="width"
                        label={"Width [mm] *"}
                        value={
                          furnaceData?.regeneratorDesign?.chamberDimensionsEFF
                            ?.width
                        }
                        error={
                          stepError &&
                          !furnaceData?.regeneratorDesign?.chamberDimensionsEFF
                            ?.width}
                        type="number"
                        onChange={(e) => {
                          const newValue = e.target.value;
                          if (newValue.length <= 10 && !isNaN(newValue)) {
                            onHandleChamberDimensions("width", e);
                          }
                        }}
                      />
                    </FormControl>
                  </Grid>
                </React.Fragment>
              ) : (
                getNumberOfPorts()
              )}
            </Grid>

            <CustomText
              value="Packing Properties"
              variant="subtitle1"
              style={{ color: "#2D2D2D", margin: "10px 0px 10px 0px" }}
              align="left"
            />
            <Grid container spacing={2}>
              {customerInfo.furnaceType === "End Fired" ? (
                <React.Fragment>
                  <Grid item xs={6}>
                    <FormControl fullWidth={true}>
                      <TextField
                        variant="outlined"
                        size="small"
                        name="thickness"
                        label={"Checkers thickness [mm] *"}
                        value={
                          furnaceData?.regeneratorDesign?.packingPropertiesEFF
                            ?.thickness
                        }
                        error={
                          stepError &&
                          !furnaceData?.regeneratorDesign?.packingPropertiesEFF
                            ?.thickness}
                        type="number"
                        onChange={(e) => {
                          const newValue = e.target.value;
                          if (newValue.length <= 10 && !isNaN(newValue)) {
                            onHandleChamberDimensions("thickness", e);
                          }
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth={true}>
                      <TextField
                        variant="outlined"
                        size="small"
                        name="size"
                        label={"Channel size [mm] *"}
                        value={
                          furnaceData?.regeneratorDesign?.packingPropertiesEFF
                            ?.size
                        }
                        error={
                          stepError &&
                          !furnaceData?.regeneratorDesign?.packingPropertiesEFF
                            ?.size}
                        type="number"
                        onChange={(e) => {
                          const newValue = e.target.value;
                          if (newValue.length <= 10 && !isNaN(newValue)) {
                            onHandleChamberDimensions("size", e);
                          }
                        }}
                      />
                    </FormControl>
                  </Grid>
                </React.Fragment>
              ) : (
                getNumberOfPortsofPacking()
              )}
            </Grid>
            <CustomText
              value="No of  Channels"
              variant="subtitle1"
              style={{ color: "#2D2D2D", margin: "10px 0px 10px 0px" }}
              align="left"
            />
            <Grid container spacing={2}>
              {customerInfo.furnaceType === "End Fired" ? (
                <React.Fragment>
                  <Grid item xs={6}>
                    <FormControl fullWidth={true}>
                      <TextField
                        variant="outlined"
                        size="small"
                        name="depthChannels"
                        label={"Depth [mm] *"}
                        value={
                          furnaceData?.regeneratorDesign?.noOfChannelsEFF
                            ?.depthChannels
                        }
                        error={
                          stepError &&
                          !furnaceData?.regeneratorDesign?.noOfChannelsEFF
                            ?.depthChannels}
                        type="number"
                        onChange={(e) => {
                          const newValue = e.target.value;
                          if (newValue.length <= 10 && !isNaN(newValue)) {
                            onHandleChamberDimensions("depthChannels", e);
                          }
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth={true}>
                      <TextField
                        variant="outlined"
                        size="small"
                        name="widthChannels"
                        label={"Width [mm] *"}
                        value={
                          furnaceData?.regeneratorDesign?.noOfChannelsEFF
                            ?.widthChannels
                        }
                        error={
                          stepError &&
                          !furnaceData?.regeneratorDesign?.noOfChannelsEFF
                            ?.widthChannels}
                        type="number"
                        onChange={(e) => {
                          const newValue = e.target.value;
                          if (newValue.length <= 10 && !isNaN(newValue)) {
                            onHandleChamberDimensions("widthChannels", e);
                          }
                        }}
                      />
                    </FormControl>
                  </Grid>
                </React.Fragment>
              ) : (
                getNumberOfPortsofChannels()
              )}
            </Grid>
          </Container>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default RegeneratorData;