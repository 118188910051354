import React from "react";
import { Outlet } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { drawerMode } from "../recoil/atoms";
import TopAppBar from "../components/app/TopAppBar";
import LeftDrawer from "../components/app/LeftDrawer";

export default function MyRegenTemplate() {
  const open = useRecoilValue(drawerMode);
  return (
    <div style={{ display: "flex" }}>
      <TopAppBar />
      <LeftDrawer />
      <main
        id="myRegen-main-element"
        style={{
          flexGrow: 1,
          minHeight: "calc(100vh - 70px)",
          height: "calc(100vh - 70px)",
          marginTop: "60px",
          overflow: "auto",
          maxWidth: open ? "90%" : "100%",
        }}
      >
        <Outlet />
      </main>
    </div>
  );
}
