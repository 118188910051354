import React from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import ErrorIcon from '@mui/icons-material/Error';

const SuccessDialog = (props) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={props.open}
        onClose={props.onClose}
        msg={props.msg}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogContent>
          {props.msg.includes("Unable to submit, please try again!") ?
            <ErrorIcon
              style={{ marginLeft: "40%", fill: "red" }}
              fontSize="large"
            />

            :
            <TaskAltIcon
              style={{ marginLeft: "40%" }}
              fontSize="large"
              color="success"
            />
          }
          <DialogContentText>
            <p style={{ color: props.msg.includes("Unable to submit, please try again!") ? "red" : "green", fontWeight: "bold" }}>
              {props.msg ? props.msg : "Form submitted successfully"}
            </p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={props.onClose} autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default SuccessDialog;