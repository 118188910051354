import configParam from "../../config";

const API = {
  GET_USER_DETAILS: configParam.API_URL + "user",
  GET_OTHER_USER_DETAILS: configParam.API_URL + "other_user",
  GET_ROLE_LIST: configParam.API_URL + "roles",
  GET_ZONE_LIST: configParam.API_URL + "zone",
  GET_USER: configParam.API_URL + "get_user",
  GET_TRIAL: configParam.API_URL + "get_trial",
  GET_ALL_TRIALS_LIST: configParam.API_URL + "get_all_trials",
  INSERT_USER: configParam.API_URL + "insert_user",
  INSERT_TRIALS: configParam.API_URL + "insert_trials",
  DELETE_USER: configParam.API_URL + "delete_user",
  DELETE_TRIALS: configParam.API_URL + "delete_trials",
  UPDATE_USER: configParam.API_URL + "update_user",
  UPDATE_TRIALS_LIST: configParam.API_URL + "update_trials_list",
  GET_TRIALS_COUNT: configParam.API_URL + "get_trials_count",
  GET_PUBLIC_KEY: configParam.API_URL + "get_public_key"
};
export default API;