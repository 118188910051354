import React from "react";
import NewTrials from "../../pages/trials/NewTrials";
import SavedTrials from "../../pages/trials/SavedTrials";
import { Tabs, Tab, Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles({
  customStyleOnTab: {
    fontSize: "15px",
    color: "000000",
    fontWeight: "400",
    textTransform: "none",
    marginLeft: 20,
  },
  activeTab: {
    fontSize: "16px",
    fontWeight: "600",
    color: "#000000",
    textTransform: "none",
  },
  tabsdiv: {
    textTransform: "none",
    padding: 0,
    width: "100%",
    height: "auto",
    border: "1px solid #E2E2E2",
    backgroundColor: "#FCFCFC",
    borderRadius: "4px",
  },
});

const Trials = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        style={{ width: "100%" }}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.tabsdiv}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          flexDirection: "column",
        }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          TabIndicatorProps={{
            style: {
              backgroundColor: "#8F908F",
              fontWeight: 500,
            },
          }}
          aria-label="basic tabs example"
          sx={{ marginRight: "auto" }}
        >
          <Tab
            label={
              <span
                className={
                  value === 0 ? classes.activeTab : classes.customStyleOnTab
                }
              >
                New Trials
              </span>
            }
          />
          <Tab
            label={
              <span
                className={
                  value === 1 ? classes.activeTab : classes.customStyleOnTab
                }
              >
                Saved Trials
              </span>
            }
          />
        </Tabs>
        <TabPanel value={value} index={0}>
          <NewTrials />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <SavedTrials />
        </TabPanel>
      </Box>
    </div>
  );
};
export default Trials;
