import React, { useState } from 'react';
import { Tooltip, Popper, Avatar, ClickAwayListener, Button, IconButton } from '@mui/material';
import { ReactComponent as LogoutIcon } from "../../assets/topappbar/logoutDark.svg";

export default function ProfileMenu() {
    const [anchorEl, setAnchorEl] = useState(null);

    const userDetails = localStorage.getItem('user');
    const userValue = JSON.parse(userDetails);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const Logout = () => {
        setAnchorEl(null);
        localStorage.setItem('user', {});
        window.location.href = "/login";
    };

    let role = userValue.role_id;
    let roleName = "";
    if (role === 1) {
        roleName = "Super Admin";
    } else if (role === 2) {
        roleName = "Admin";
    } else {
        roleName = "User";
    }

    return (
        <React.Fragment>
            <Tooltip title="Profile">
                <IconButton color="inherit" onClick={handleClick}>
                    <Avatar style={{ marginRight: 4, background: "#203468", border: "2px solid #203468" }}>{userValue ? userValue.first_name.charAt(0) + userValue.last_name.charAt(0) : "--"}</Avatar>
                </IconButton>
            </Tooltip>
            <div style={{ display: 'flex', marginLeft: "auto" }}>
                <Tooltip title="Logout">
                    <Button
                        variant="text"
                        size="small"
                        onClick={Logout}
                    >
                        <LogoutIcon style={{ fill: '#000' }} />
                    </Button>
                </Tooltip>
            </div>
            <Popper
                id={"profile-popper"}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                placement={"bottom-end"}
                style={{
                    transform: `translate3d(${(window.innerWidth || 0) - 220
                        }px, 56px, 0px)  !important`,
                    boxShadow: "0px 2px 4px rgba(51, 51, 51, 0.25)",
                    backgroundColor: "#FFFFFF",
                    border: "1px solid rgba(255,255,255,0.12)",
                    borderRadius: 2,
                    padding: "10px",
                    zIndex: 1200,
                    width: 250,
                    marginTop: "13px",

                }}>
                <ClickAwayListener onClickAway={() => {
                    setAnchorEl(null);
                }}>
                    <div
                        style={{
                            zIndex: 1000,
                            color: "#808080",
                            padding: "10px"
                        }}
                    >

                        <div style={{ display: "flex", marginLeft: 10, height: 55 }}>
                            <Avatar style={{ marginRight: 4, background: "#203468" }}>{userValue ? userValue.first_name.charAt(0) + " " + userValue.last_name.charAt(0) : "--"}</Avatar>
                            <div style={{ fontWeight: 400, fontSize: 14, lineHeight: 1.75 }} >
                                <div id="user_name">{userValue ? userValue.first_name + " " + userValue.last_name : "--"}</div>
                                <div id="user_role">{role ? roleName : "--"}</div>
                                {/* <div id="user_sg_id">{userValue ? userValue.sgid : "--"}</div> */}
                            </div>
                        </div>
                    </div>
                </ClickAwayListener>
            </Popper>
        </React.Fragment>
    );
}