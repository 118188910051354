import React, { useState, useEffect } from "react";
import { Grid, Skeleton } from "@mui/material";
import LeftContainer from "./LeftContainer";
import RightContainer from "./RightContainer";
import { useRecoilState } from "recoil";
import { menuList } from "../../recoil/atoms";

const UserProfile = () => {
  const [, setMenu] = useRecoilState(menuList);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setMenu("User Management");
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);

  return (
    <React.Fragment>
      {isLoading ? (
        <div className="skeleton-dashboard" style={{ width: "100%" }}>
          <Grid container>
            <Skeleton style={{ width: "100%", height: 180, transform: "none" }} />
          </Grid>
          <Grid container style={{ marginTop: "20px" }}>
            <Skeleton style={{ width: "100%", height: 430, transform: "none" }} />
          </Grid>
        </div>
      ) : (
        <Grid container>
          <Grid item xs={12}>
            <LeftContainer />
          </Grid>
          <Grid item xs={12}>
            <RightContainer />
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
};

export default UserProfile;