import React from 'react';
import { Helmet } from 'react-helmet';
import { AppBar, Toolbar, Box } from '@mui/material';
import LandImage from "../../assets/landImage.png";
import ProfileMenu from './ProfileMenu';

export default function Header() {
  return (
    <AppBar
      elevation={0}
      height={64}
      position="fixed"
      sx={{
        backgroundColor: "#FFFFFF",
        padding: "3px",
        boxShadow: "0px 2px 5px #00000026",
      }}
    >
      <Helmet>
        {" "}
        <meta charSet="utf-8" /> <title>{"MyRegen"}</title>{" "}
      </Helmet>

      <Toolbar variant="dense" disableGutters style={{ minHeight: 48 }}>
        {/* MYREGEN LOGO */}

        <Box sx={{ marginLeft: { sm: "5px", md: "5px", xl: "5px" } }}>
          <div>
            {
              <img
                src={LandImage}
                alt=""
                style={{
                  objectFit: "cover",
                  horizontalAlignment: "center",
                  verticalAlignment: "center",
                  width: "100%",
                  maxWidth: "160px"
                }}
              />
            }
          </div>
        </Box>

        {/* MYREGEN HEADING */}
        <div style={{ flexGrow: 1 }} />

        {/* PROFILE MENU */}
        <ProfileMenu />
      </Toolbar>
    </AppBar>
  );
}
