import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useSetRecoilState, useResetRecoilState } from "recoil";
import {
  activeStep,
  actionType,
  newVisitStatus,
  customerInfoData,
  fuelInfo,
  furnaceDetails,
  temparatures,
  fumesComposition,
  dustsAnalysis,
  error
} from "../../recoil/atoms";
import { Button, Grid, Skeleton, Tooltip } from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import { ReactComponent as Edit } from "../../assets/icons/Edit.svg";
import CustomText from "../../components/ui/Typography";
import {
  setCustDetails,
  setGasFuelDetails,
  setFurnaceDetails,
  setTemparaturesDetails,
  setFumesCompositionDetails,
  setDustsAnalysisDetails,
} from "../../recoil/selectors";
import configParam from "../../config";
import DataTable from "../../components/ui/DataTable/DataTable";
import DeleteDialog from "../../input/DeleteDialog";

const CustomerTable = () => {
  const sgid = localStorage.getItem("UserID");
  const visitNo = configParam.VISIT_NO;
  const getData = configParam.GET_DATA;
  const [table, setTable] = useState([]);
  const [, setType] = useRecoilState(actionType);
  const [tableData, setTableData] = useState([]);
  const [, setVisitStatus] = useRecoilState(newVisitStatus);
  const setCustomerDetails = useSetRecoilState(setCustDetails);
  const setFlowsDetails = useSetRecoilState(setGasFuelDetails);
  const setFurnaceDetail = useSetRecoilState(setFurnaceDetails);
  const setTempDetails = useSetRecoilState(setTemparaturesDetails);
  const setFumesCompDetails = useSetRecoilState(setFumesCompositionDetails);
  const setDustsDetails = useSetRecoilState(setDustsAnalysisDetails);
  const resetCustomerValue = useResetRecoilState(customerInfoData);
  const resetFlowValue = useResetRecoilState(fuelInfo);
  const resetFurnaceValue = useResetRecoilState(furnaceDetails);
  const resetTemparaturesValue = useResetRecoilState(temparatures);
  const resetFumesCompValue = useResetRecoilState(fumesComposition);
  const resetDustsValue = useResetRecoilState(dustsAnalysis);
  const [, setStepError] = useRecoilState(error);
  const [deleteRow, setDeleteRow] = useState(false);
  const [rowIndex, setRowIndex] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const myRegenFeatures = localStorage.getItem("myRegenVisitData");
    const value = JSON.parse(myRegenFeatures);
    setTable(value ? value[sgid] : []);
    console.log(table, "table")
    // eslint-disable-next-line
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);

  useEffect(() => {
    const myRegenFeatures = localStorage.getItem("myRegenVisitData");
    const value = JSON.parse(myRegenFeatures);
    setTable(value ? value[sgid] : []);
    processedrows(value ? value[sgid] : []);
    // eslint-disable-next-line
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);

  const processedrows = (value) => {
    let temptabledata = Object.keys(value).map(function (key) {
      const customerInfo = value[key]["customerInfo"];
      const customerInfoArray = Object.values(customerInfo);
      return customerInfoArray;
    });
    if (temptabledata.length > 0) {
      setTableData(formData(value));
    } else {
      setTableData([]);
    }
  };

  const [, setActiveStep] = useRecoilState(activeStep);
  const navigate = useNavigate();

  const columns = [
    { id: "sno", label: "S.No", minWidth: 170 },
    { id: "customer", label: "Name", minWidth: 100 },
    {
      id: "date",
      label: "Date",
      minWidth: 170,
      align: "right",
    },
    {
      id: "plant",
      label: "Plant",
      minWidth: 170,
      align: "right",
    },
    {
      id: "actions",
      label: "Actions",
    },
  ];

  const formData = (data) => {
    const dataArray = Object.values(data).map((item) => item.customerInfo);
    let resultArray = [];
    dataArray.map((val, index) => {
      const formatDate = new Date(val.date).toLocaleDateString("en-GB");
      let actions = (
        <div
          className="d-flex justify-content-between align-items-center"
          style={{ cursor: "pointer" }}
        >
          <Tooltip title="Edit">
            <Edit
              id={index}
              style={{ marginRight: "15px", cursor: "pointer" }}
              onClick={() => {
                onHandleEdit(index);
              }}
            />
          </Tooltip>
          <Tooltip title="Delete">
            <Delete
              style={{ cursor: "pointer", color: "#203468" }}
              onClick={() => onHandleDelete(index)}
            />
          </Tooltip>
        </div>
      );
      resultArray.push([
        index + 1,
        val.customerName,
        formatDate,
        val.plantName,
        actions,
      ]);
      return null;
    });
    return resultArray;
  };

  const onHandleCreate = () => {
    let visit = visitNo();
    localStorage.setItem("visitNumber", visit || 0);
    resetCustomerValue();
    resetFlowValue();
    resetFurnaceValue();
    resetTemparaturesValue();
    resetFumesCompValue();
    resetDustsValue();
    setActiveStep(0);
    setType("create");
    setVisitStatus(true);
    setStepError(false);
    navigate(`/AddCustomerInfo`);
  };

  const onHandleEdit = (id) => {
    localStorage.setItem("visitNumber", id);
    setActiveStep(0);
    setType("edit");
    setStepError(false);
    navigate(`/AddCustomerInfo`);

    let tVisitData = getData();
    let trialVisitData = tVisitData[id];
    if (trialVisitData.customerInfo) {
      setCustomerDetails();
    }
    if (trialVisitData.fuelData) {
      setFlowsDetails();
    }
    if (trialVisitData.furnaceDetails) {
      setFurnaceDetail();
    }
    if (trialVisitData.temparatures) {
      setTempDetails();
    }
    if (trialVisitData.fumesComposition) {
      setFumesCompDetails();
    }
    if (trialVisitData.dustsAnalysis) {
      setDustsDetails();
    }
  };

  const onHandleDelete = (id) => {
    setDeleteRow(true);
    setRowIndex(id);
  };

  const onHandleClosed = () => {
    setDeleteRow(false);
  }

  const rowDelete = (id) => {
    const myRegenFeatures = localStorage.getItem("myRegenVisitData");
    const value = JSON.parse(myRegenFeatures);

    let temptabledata = [];
    temptabledata = temptabledata.concat(
      Object.keys(value[sgid]).map(function (key) {
        return [value[sgid][key]];
      })
    );
    const data = temptabledata.filter((key, index) => index !== id);

    const tepArr = data.map((val, index) => {
      return data[index][0];
    });
    let target = { [sgid]: {} };
    Object.keys(tepArr).forEach((key) => (target[sgid][key] = tepArr[key]));
    localStorage.setItem("myRegenVisitData", JSON.stringify(target));
    processedrows(target[sgid]);
    onHandleClosed();
  }

  return (
    <React.Fragment>
      {isLoading ? (
        <div className="skeleton-dashboard" style={{ width: "100%" }}>
          <Grid container style={{ justifyContent: "flex-end" }}>
            <Skeleton style={{ width: "130px", height: 40, transform: "none" }} />
          </Grid>
          <Grid container style={{ marginTop: "20px" }}>
            <Skeleton style={{ width: "100%", height: 400, transform: "none" }} />
          </Grid>
        </div>
      ) : (
        <div className="skeleton-dashboard" style={{ width: "100%" }}>
          <Grid
            container
            sx={{
              border: "1px solid #F3F3F3",
              margin: "7px 0px",
              background: "#ffffff",
            }}
          >
            <Grid item xs={12} sm={8} xl={8}>
              <CustomText
                variant={"h6"}
                value="Customer Cases"
                align="left"
                style={{ margin: "10px" }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={4}
              xl={4}
              style={{
                display: "flex",
                height: "48px",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                startIcon={<Add />}
                style={{
                  height: "30px",
                  borderRadius: "2px",
                  margin: "10px",
                  wordWrap: "break-word",
                  backgroundColor: "#203468",
                  textTransform: "capitalize",
                }}
                onClick={onHandleCreate}
              >
                Create Case{" "}
              </Button>
            </Grid>
          </Grid>
          <DataTable
            data={tableData}
            columns={columns}
            noPagination={false}
            rowCount={10}
            noRowsPerPage={10}
          />
          <DeleteDialog
            open={deleteRow}
            onClose={onHandleClosed}
            handleDelete={() => rowDelete(rowIndex)}
          />
        </div>
      )}
    </React.Fragment>
  );
};

export default CustomerTable;