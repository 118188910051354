import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  Button,
} from "@mui/material";
import LandImage from "../../assets/landImage.png";
import CircularProgress from "@mui/material/CircularProgress";
import configParam from "../../config";
import axios from "axios";
import API from "../Services/API";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onHandleSSO = () => {
    const url = configParam.SSO_URL;
    window.location.href = `${url}`;
  };

  useEffect(() => {
    userDetails();
  }, []);

  const getUserDetails = async (id, token) => {
    token = token.replace(/"/g, "");
    id = id.replace(/"/g, "");
     try {
      await axios.get(`${API.GET_USER}?id=${id}`, {
        headers: {
          authorization: `Bearer ${token}`
        }
      }).then((res) => {
        if (res.status === 200) {
          let userData = res.data.data.user[0];
          userData["web_token"] = `Bearer ${token}`;
          localStorage.setItem("user", JSON.stringify(userData));
        }
      }).catch((error) => {
        console.log("ERROR", error);
      });
    } catch (error) {
      console.error(error);
    }
  }

  const userDetails = async () => {
    const url = window.location.href;
    const Response = decodeURIComponent(url);
    const myArray = Response.split("?");
    if (myArray.length > 1) {
      const queryParameters = new URLSearchParams(window.location.search)
      const UserDetails = queryParameters.get("UserData");
      const UserSGID = queryParameters.get("sgid");
      const token = queryParameters.get("token");
      const err = queryParameters.get("err");
      const currentUserDetails = JSON.parse(UserDetails)
      if (currentUserDetails && Object.keys(currentUserDetails).length > 0) {
        await getUserDetails(UserSGID, token);
        navigate(`/case`);
        // window.location.href = "/case";
      } else {
        localStorage.setItem("user", "");
        setError(err || "Access denied");
        setLoading(false);
      }
    }
  }

  return (
    <Grid container style={{ overflowY: "hidden", fontWeight: 600, height: "100vh", justifyContent: "center", alignItems: "center" }}>
      <Grid item md={6} xl={6} xs={12}>
        <div style={{ textAlign: "center" }}>
          {
            <img
              src={LandImage}
              alt=""
              style={{
                objectFit: "cover",
                horizontalAlignment: "center",
                verticalAlignment: "center",
                width: "100%",
                maxWidth: "600px"
              }}
            />
          }
        </div>
        <div style={{ textAlign: "center", marginTop: -10 }}>
          {!loading ? (
            <Button
              variant="contained"
              size="small"
              style={{
                width: "150px",
                backgroundColor: "#203468",
                textTransform: "capitalize",
                marginBottom: 25
              }}
              onClick={onHandleSSO}
            >
              Login
            </Button>
          ) : (
            <CircularProgress />
          )}
          <Typography
            variant="subtitle1"
            style={{ color: "red" }}
          >
            {error}&nbsp;
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
};

export default Login;