import React, { Suspense, useState } from 'react';
import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom';
import { CssBaseline, LinearProgress } from '@mui/material';
import Login from './pages/login/Login';
import MyRegenTemplate from './layouts/MyRegenTemplate';
import routes from './routes.js';
import './App.css';
import { AuthContext } from './Context';
import RouteMissing from './pages/UserManagement/RouteMissing';

export const switchRoutes = (
  <React.Fragment>
    {routes.mainRoutes.map((route, index) => {
      return route.component ? (
        <Route
          path={route.path}
          name={route.name}
          element={<route.component />}
          key={index}
        />
      ) : (
        <React.Fragment></React.Fragment>
      );
    })}

    <Route path="/" element={<Navigate replace to="/case" />} />
  </React.Fragment>
);

const PrivateOutlet = () => {
  const currentUser = localStorage.getItem("user");
  const user = JSON.parse(JSON.stringify(currentUser));
  return user ? <MyRegenTemplate /> : <Navigate to="/login" />;
};

function App() {
  const currentUser = localStorage.getItem("user");
  const user = JSON.parse(JSON.stringify(currentUser));

  const [userId,] = useState(user);

  return (
    <div>
      <AuthContext.Provider value={{ userId, setAuthTokens: userId }}>
        <CssBaseline />
        <BrowserRouter>
          <Suspense fallback={<LinearProgress />}>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="*" element={<RouteMissing />} />
              <Route path="/" element={<PrivateOutlet />}>
                {switchRoutes}
              </Route>
            </Routes>
          </Suspense>
        </BrowserRouter>
      </AuthContext.Provider>
    </div>
  );
}

export default App;