import React, { useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  FormControl,
  Container,
  TextField,
} from "@mui/material";
import CustomText from "../../../../components/ui/Typography";
import CloseIcon from "@mui/icons-material/Close";
import { useRecoilState } from "recoil";
import {
  customerInfoData,
  furnaceDetails,
  error,
  newVisitStatus,
} from "../../../../recoil/atoms";
import configParam from "../../../../config";

const BatchData = (props) => {
  const deepCopy = configParam.DEEP_COPY;
  const [customerInfo] = useRecoilState(customerInfoData);
  const [furnaceData, setFurnaceData] = useRecoilState(furnaceDetails);
  let chamberType = customerInfo.chamberType;
  const colLength = chamberType === "Both Side" ? 6 : 12;
  const numberOfPorts =
    customerInfo.furnaceType === "End Fired" ? 1 : customerInfo.numberOfPorts;
  const sgid = localStorage.getItem("UserID");
  const getItemNo = configParam.getVisitNumbers;
  const myRegenFeatures = localStorage.getItem("myRegenVisitData");
  const [stepError] = useRecoilState(error);
  const [visitStatus] = useRecoilState(newVisitStatus);
  let data = myRegenFeatures ? JSON.parse(myRegenFeatures) : {};

  const getNumberOfPorts = () => {
    const arr = [];
    for (let i = 0; i < numberOfPorts; i++) {
      let id = i + 1;
      const getValueLeft = () => {
        if (customerInfo.furnaceType === "End Fired") {
          return chamberType === "One Side"
            ? furnaceData?.batchData?.distributionEFF?.symmetricData
            : furnaceData?.batchData?.distributionEFF?.left;
        } else {
          return chamberType === "One Side"
            ? furnaceData?.batchData?.distributionCFF?.symmetricData[id]
            : furnaceData?.batchData?.distributionCFF?.left[id];
        }
      };
      const getValueRight = () => {
        if (customerInfo.furnaceType === "End Fired") {
          return chamberType === "One Side"
            ? furnaceData?.batchData?.distributionEFF?.symmetricData
            : furnaceData?.batchData?.distributionEFF?.right;
        } else {
          return chamberType === "One Side"
            ? furnaceData?.batchData?.distributionCFF?.symmetricData[id]
            : furnaceData?.batchData?.distributionCFF?.right[id];
        }
      };
      arr.push(
        <React.Fragment>
          <Grid item xs={colLength} md={colLength}>
            <TextField
              variant="outlined"
              size="small"
              name={id}
              label={"#" + id}
              type="number"
              value={getValueLeft()}
              error={stepError && !getValueLeft()}
              onChange={(e) => {
                const newValue = e.target.value;
                if (newValue.length <= 10 && !isNaN(newValue)) {
                  onHandleDistribution("left", e);
                }
              }}
            />
          </Grid>
          {chamberType === "Both Side" ? (
            <Grid item xs={6} md={6}>
              <TextField
                variant="outlined"
                size="small"
                name={id}
                label={"#" + id}
                type="number"
                value={getValueRight()}
                error={stepError && !getValueRight()}
                onChange={(e) => {
                  const newValue = e.target.value;
                  if (newValue.length <= 10 && !isNaN(newValue)) {
                    onHandleDistribution("right", e);
                  }
                }}
              />
            </Grid>
          ) : null}
        </React.Fragment>
      );
    }
    return arr;
  };

  function onChangeType(event) {
    let tmpObj = deepCopy(furnaceData);
    let inputValue = event.target.value;
    inputValue = inputValue.substring(0, 10);
    tmpObj["batchData"][event.target.name] = inputValue;
    setFurnaceData(tmpObj);
  }

  function onHandleDistribution(type, event) {
    let tmpObj = deepCopy(furnaceData);
    if (chamberType === "One Side") {
      if (customerInfo.furnaceType === "End Fired") {
        tmpObj["batchData"]["distributionEFF"]["symmetricData"] =
          event.target.value;
      } else {
        tmpObj["batchData"]["distributionCFF"]["symmetricData"][
          event.target.name
        ] = event.target.value;
      }
      setFurnaceData(tmpObj);
      let rowIndex = getItemNo();
      let obj = { furnaceDetails: furnaceData };
      let merged = { ...data[sgid][rowIndex || 0], ...obj };
      data[sgid][rowIndex] = merged;
      localStorage.setItem("myRegenVisitData", JSON.stringify(data));
    } else {
      if (customerInfo.furnaceType === "End Fired") {
        tmpObj["batchData"]["distributionEFF"][type] = event.target.value;
      } else {
        tmpObj["batchData"]["distributionCFF"][type][event.target.name] =
          event.target.value;
      }
      setFurnaceData(tmpObj);
      let rowIndex = getItemNo();
      let obj = { furnaceDetails: furnaceData };
      let merged = { ...data[sgid][rowIndex || 0], ...obj };
      data[sgid][rowIndex] = merged;
      localStorage.setItem("myRegenVisitData", JSON.stringify(data));
    }
  }

  useEffect(() => {
    if (visitStatus) {
      let rowIndex = getItemNo();
      let obj = { furnaceDetails: furnaceData };
      let merged = { ...data[sgid][rowIndex || 0], ...obj };
      data[sgid][rowIndex] = merged;
      localStorage.setItem("myRegenVisitData", JSON.stringify(data));
    }
  }, [furnaceData]);

  return (
    <Dialog open={props.open} onClose={props.handleClose} fullWidth={true}>
      <DialogTitle>
        Batch Data
        <CloseIcon
          sx={{
            position: "absolute",
            right: 20,
            top: 13,
            cursor: "pointer"
          }}
          onClick={props.handleClose}
        />
      </DialogTitle>

      <DialogContent
        style={{ borderTop: "1px solid #a8a8a8", paddingTop: "10px" }}
      >
        <DialogContentText>
          <Container>
            <CustomText
              value="Batch Details"
              variant="subtitle1"
              style={{ color: "#2D2D2D" }}
              align="left"
            />
            <FormControl fullWidth={true} style={{ marginTop: "10px" }}>
              <TextField
                variant="outlined"
                size="small"
                name="pullRate"
                label={"Pull Rate (t/d) *"}
                value={furnaceData["batchData"]["pullRate"]}
                error={stepError && !furnaceData["batchData"]["pullRate"]}
                type="number"
                inputProps={{ maxLength: 10 }}
                onChange={onChangeType}
              />
            </FormControl>
            <FormControl fullWidth={true} style={{ marginTop: "20px" }}>
              <TextField
                variant="outlined"
                size="small"
                name="cullet"
                label={"Cullet (%) *"}
                value={furnaceData["batchData"]["cullet"]}
                error={stepError && !furnaceData["batchData"]["cullet"]}
                type="number"
                inputProps={{ maxLength: 10 }}
                onChange={onChangeType}
              />
            </FormControl>
            <FormControl fullWidth={true} style={{ marginTop: "20px" }}>
              <TextField
                variant="outlined"
                size="small"
                name="h2oPercBatch"
                label={"H2O  in Batch (%) *"}
                value={furnaceData["batchData"]["h2oPercBatch"]}
                error={stepError && !furnaceData["batchData"]["h2oPercBatch"]}
                type="number"
                inputProps={{ maxLength: 10 }}
                onChange={onChangeType}
              />
            </FormControl>
            <FormControl fullWidth={true} style={{ marginTop: "20px" }}>
              <TextField
                variant="outlined"
                size="small"
                name="ignitionLoss"
                label={"Ignition Loss (%) *"}
                value={furnaceData["batchData"]["ignitionLoss"]}
                error={stepError && !furnaceData["batchData"]["ignitionLoss"]}
                type="number"
                inputProps={{ maxLength: 10 }}
                onChange={onChangeType}
              />
            </FormControl>
            {numberOfPorts > 0 ? (
              <React.Fragment>
                <CustomText
                  value="Batch Gases Distribution (%) *"
                  variant="h6"
                  style={{ color: "#2D2D2D", marginTop: "10px" }}
                  align="left"
                />
                <Grid container spacing={2}>
                  {chamberType === "Both Side" ? (
                    <React.Fragment>
                      <Grid item xs={colLength} md={colLength}>
                        <CustomText
                          value=" Left *"
                          variant="subtitle1"
                          align="left"
                          style={{ marginBottom: "10px" }}
                        />
                      </Grid>
                      <Grid item xs={6} md={6}>
                        <CustomText
                          value=" Right *"
                          variant="subtitle1"
                          align="left"
                          style={{ marginBottom: "10px" }}
                        />
                      </Grid>
                    </React.Fragment>
                  ) : (
                    <Grid item xs={6} md={6}>
                      <CustomText value=" " variant="subtitle1" align="left" />
                    </Grid>
                  )}
                </Grid>
                <Grid container spacing={2}>
                  {getNumberOfPorts()}
                </Grid>
              </React.Fragment>
            ) : null}
          </Container>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default BatchData;