import React, { useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CustomText from "../../../../../components/ui/Typography";
import { Container, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import { useRecoilState } from "recoil";
import {
  customerInfoData,
  fumesComposition,
  error,
  newVisitStatus,
} from "../../../../../recoil/atoms";
import configParam from "../../../../../config";

const FumesComposition = (props) => {
  const [customerInfo] = useRecoilState(customerInfoData);
  const [compositionData, setCompositionData] =
    useRecoilState(fumesComposition);
  let numberOfPorts = customerInfo.numberOfPorts;
  if (customerInfo.furnaceType === "End Fired") {
    numberOfPorts = 1;
  }
  let chamberType = customerInfo.chamberType;
  const colLength = chamberType === "Both Side" ? 6 : 12;
  const deepCopy = configParam.DEEP_COPY;
  const sgid = localStorage.getItem("UserID");
  const getItemNo = configParam.getVisitNumbers;
  const myRegenFeatures = localStorage.getItem("myRegenVisitData");
  let data = JSON.parse(myRegenFeatures ?? "{}");
  const [stepError] = useRecoilState(error);
  const [visitStatus] = useRecoilState(newVisitStatus);

  const handleInputChange = (e, type, side) => {
    const newValue = e.target.value;
    if (isValidInput(newValue)) {
      onHandleChange(type, side, e);
    }
  };

  const isValidInput = (value) => {
    return value.length <= 10 && !isNaN(value);
  };

  const getNumberOfPorts = (type) => {
    const arr = [];
    for (let i = 0; i < numberOfPorts; i++) {
      let id = i + 1;
      const getValueLeft = () => {
        if (customerInfo.furnaceType === "End Fired") {
          return chamberType === "One Side"
            ? compositionData["compositionEFF"][type]["symmetricData"]
            : compositionData["compositionEFF"][type]["left"];
        } else {
          return chamberType === "One Side"
            ? compositionData["compositionCFF"][type]["symmetricData"][id]
            : compositionData["compositionCFF"][type]["left"][id];
        }
      };
      const getErrorLeft = () => {
        if (type === "o2Bottom" || type === "o2Top") {
          const value = getValueLeft();
          return stepError && !value;
        }
        return false;
      };
      const getValueRight = () => {
        const isEndFired = customerInfo.furnaceType === "End Fired";
        const isOneSide = chamberType === "One Side";

        if (isEndFired) {
          return isOneSide
            ? compositionData["compositionEFF"][type]["symmetricData"]
            : compositionData["compositionEFF"][type]["right"];
        } else {
          return isOneSide
            ? compositionData["compositionCFF"][type]["symmetricData"][id]
            : compositionData["compositionCFF"][type]["right"][id];
        }
      };

      const isOxygenType = (type) => {
        return type === "o2Bottom" || type === "o2Top";
      };

      const hasErrorValue = (stepError, value) => {
        return stepError && !value;
      };

      const getErrorRight = () => {
        if (isOxygenType(type)) {
          const value = getValueRight();
          return hasErrorValue(stepError, value);
        }
        return false;
      };
      arr.push(
        <React.Fragment>
          <Grid item xs={colLength} md={colLength}>
            <TextField
              variant="outlined"
              size="small"
              name={id}
              label={"#" + id}
              style={{ width: "100%" }}
              type="number"
              value={getValueLeft()}
              error={getErrorLeft()}
              onChange={(e) => handleInputChange(e, type, "left")}
            />
          </Grid>
          {chamberType === "Both Side" && (
            <Grid item xs={6} md={6}>
              <TextField
                variant="outlined"
                size="small"
                name={id}
                label={"#" + id}
                type="number"
                style={{ width: "100%" }}
                value={getValueRight()}
                error={getErrorRight()}
                onChange={(e) => handleInputChange(e, type, "right")}
              />
            </Grid>
          )}
        </React.Fragment>
      );
    }
    return arr;
  };

  const onHandleChange = (type, subType, event) => {
    let tmpObj = deepCopy(compositionData);
    if (chamberType === "One Side") {
      if (customerInfo.furnaceType === "End Fired") {
        tmpObj["compositionEFF"][type]["symmetricData"] = event.target.value;
      } else {
        tmpObj["compositionCFF"][type]["symmetricData"][event.target.name] =
          event.target.value;
      }
    } else {
      if (customerInfo.furnaceType === "End Fired") {
        tmpObj["compositionEFF"][type][subType] = event.target.value;
      } else {
        tmpObj["compositionCFF"][type][subType][event.target.name] =
          event.target.value;
      }
    }
    setCompositionData(tmpObj);
    let rowIndex = getItemNo();
    let obj = { fumesComposition: compositionData };
    let merged = { ...data[sgid][rowIndex || 0], ...obj };
    data[sgid][rowIndex] = merged;
    localStorage.setItem("myRegenVisitData", JSON.stringify(data));
  };

  useEffect(() => {
    if (visitStatus) {
      let rowIndex = getItemNo();
      let obj = { fumesComposition: compositionData };
      let merged = { ...data[sgid][rowIndex || 0], ...obj };
      data[sgid][rowIndex] = merged;
      localStorage.setItem("myRegenVisitData", JSON.stringify(data));
    }
  });

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      fullWidth={true}
      maxWidth={"sm"}
    >
      <DialogTitle>
        Fumes Gas Composition
        <CloseIcon
          sx={{
            position: "absolute",
            right: 20,
            top: 20,
            cursor: "pointer"
          }}
          onClick={props.handleClose}
        />
      </DialogTitle>
      <DialogContent
        style={{
          borderTop: "1px solid #a8a8a8",
          padding: "0",
          marginBottom: "20px",
        }}
      >
        <DialogContentText>
          <Container>
            <Grid container spacing={2}>
              <Grid item xl={6} md={6} sm={12} xs={12}>
                {numberOfPorts > 0 && (
                  <React.Fragment>
                    <CustomText
                      value="O2 concentration [vol%]"
                      variant="subtitle1"
                      style={{
                        color: "#2D2D2D",
                        fontWeight: 500,
                        margin: "0 0 20px",
                        fontSize: "14px",
                      }}
                      align="left"
                    />
                    <Grid container spacing={2}>
                      {chamberType === "Both Side" && (
                        <React.Fragment>
                          <Grid item xs={colLength} md={colLength}>
                            <CustomText
                              value="Left"
                              variant="subtitle1"
                              style={{ marginBottom: "10px", fontSize: "14px" }}
                              align="left"
                            />
                          </Grid>
                          <Grid item xs={6} md={6}>
                            <CustomText
                              value="Right"
                              variant="subtitle1"
                              style={{ marginBottom: "10px", fontSize: "14px" }}
                              align="left"
                            />
                          </Grid>
                        </React.Fragment>
                      )}
                    </Grid>
                    <Grid container spacing={2}>
                      {getNumberOfPorts("o2")}
                    </Grid>
                  </React.Fragment>
                )}
              </Grid>
              <Grid item xl={6} md={6} sm={12} xs={12}>
                {numberOfPorts > 0 && (
                  <React.Fragment>
                    <CustomText
                      value="CO2 concentration [vol%]"
                      variant="subtitle1"
                      style={{
                        color: "#2D2D2D",
                        fontWeight: 500,
                        margin: "0 0 20px",
                        fontSize: "14px",
                      }}
                      align="left"
                    />
                    <Grid container spacing={2}>
                      {chamberType === "Both Side" && (
                        <React.Fragment>
                          <Grid item xs={colLength} md={colLength}>
                            <CustomText
                              value="Left"
                              variant="subtitle1"
                              style={{ marginBottom: "10px", fontSize: "14px" }}
                              align="left"
                            />
                          </Grid>
                          <Grid item xs={6} md={6}>
                            <CustomText
                              value="Right"
                              variant="subtitle1"
                              style={{ marginBottom: "10px", fontSize: "14px" }}
                              align="left"
                            />
                          </Grid>
                        </React.Fragment>
                      )}
                    </Grid>
                    <Grid container spacing={2}>
                      {getNumberOfPorts("co2")}
                    </Grid>
                  </React.Fragment>
                )}
              </Grid>
              <Grid item xl={6} md={6} sm={12} xs={12}>
                {numberOfPorts > 0 && (
                  <React.Fragment>
                    <CustomText
                      value="H2O concentration [vol%]"
                      variant="subtitle1"
                      style={{
                        color: "#2D2D2D",
                        fontWeight: 500,
                        margin: "0 0 20px",
                        fontSize: "14px",
                      }}
                      align="left"
                    />
                    <Grid container spacing={2}>
                      {chamberType === "Both Side" && (
                        <React.Fragment>
                          <Grid item xs={colLength} md={colLength}>
                            <CustomText
                              value="Left"
                              variant="subtitle1"
                              align="left"
                              style={{ marginBottom: "10px", fontSize: "14px" }}
                            />
                          </Grid>
                          <Grid item xs={6} md={6}>
                            <CustomText
                              value="Right"
                              variant="subtitle1"
                              align="left"
                              style={{ marginBottom: "10px", fontSize: "14px" }}
                            />
                          </Grid>
                        </React.Fragment>
                      )}
                    </Grid>
                    <Grid container spacing={2}>
                      {getNumberOfPorts("h2o")}
                    </Grid>
                  </React.Fragment>
                )}
              </Grid>
              <Grid item xl={6} md={6} sm={12} xs={12}>
                {numberOfPorts > 0 && (
                  <React.Fragment>
                    <CustomText
                      value="O2 measured bottom% *"
                      variant="subtitle1"
                      style={{
                        color: "#2D2D2D",
                        fontWeight: 500,
                        margin: "0 0 20px",
                        fontSize: "14px",
                      }}
                      align="left"
                    />
                    <Grid container spacing={2}>
                      {chamberType === "Both Side" && (
                        <React.Fragment>
                          <Grid item xs={colLength} md={colLength}>
                            <CustomText
                              value="Left *"
                              variant="subtitle1"
                              align="left"
                              style={{ marginBottom: "10px", fontSize: "14px" }}
                            />
                          </Grid>
                          <Grid item xs={6} md={6}>
                            <CustomText
                              value="Right *"
                              variant="subtitle1"
                              align="left"
                              style={{ marginBottom: "10px", fontSize: "14px" }}
                            />
                          </Grid>
                        </React.Fragment>
                      )}
                    </Grid>
                    <Grid container spacing={2}>
                      {getNumberOfPorts("o2Bottom")}
                    </Grid>
                  </React.Fragment>
                )}
              </Grid>
              <Grid item xl={6} md={6} sm={12} xs={12}>
                {numberOfPorts > 0 && (
                  <React.Fragment>
                    <CustomText
                      value="O2 Dry air Top% *"
                      variant="subtitle1"
                      style={{
                        color: "#2D2D2D",
                        fontWeight: 500,
                        margin: "0 0 20px",
                        fontSize: "14px",
                      }}
                      align="left"
                    />
                    <Grid container spacing={2}>
                      {chamberType === "Both Side" && (
                        <React.Fragment>
                          <Grid item xs={colLength} md={colLength}>
                            <CustomText
                              value="Left *"
                              variant="subtitle1"
                              align="left"
                              style={{ marginBottom: "10px", fontSize: "14px" }}
                            />
                          </Grid>
                          <Grid item xs={6} md={6}>
                            <CustomText
                              value="Right *"
                              variant="subtitle1"
                              align="left"
                              style={{ marginBottom: "10px", fontSize: "14px" }}
                            />
                          </Grid>
                        </React.Fragment>
                      )}
                    </Grid>
                    <Grid container spacing={2}>
                      {getNumberOfPorts("o2Top")}
                    </Grid>
                  </React.Fragment>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={2}></Grid>
          </Container>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default FumesComposition;