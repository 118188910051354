import React, { useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import { ReactComponent as Edit } from "../../../../assets/icons/Edit.svg";
import Grid from "@mui/material/Grid";
import Temperatures from "./Temperatures";
import FumesComposition from "./FumesComposition";
import IconButton from "@mui/material/IconButton";
import DustAnalysis from "../Dusts/DustAnalysis";
import {
  errorSection
} from "../../../../recoil/atoms";
import { useRecoilState } from "recoil";

const Efficiencies = () => {
  const [open, setOpen] = useState(false);
  const [openBatchData, setOpenBatchData] = useState(false);
  const [openRegeneratorData, setOpenRegeneratorData] = useState(false);
  const [sectionError] = useRecoilState(errorSection);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenBatchData(false);
    setOpenRegeneratorData(false);
  };

  const handleClickOpenBatchData = () => {
    setOpenBatchData(true);
  };

  const handleClickDusts = () => {
    setOpenRegeneratorData(true);
  };

  return (
    <div style={{ width: "100%" }}>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2} justifyContent={"space-around"}>
          <Grid item md={3} xl={3} xs={12}>
            <Card variant="outlined" onClick={handleClickOpenBatchData}>
              <CardHeader
                action={
                  <IconButton aria-label="settings">
                    <Edit />
                  </IconButton>
                }
                style={{
                  fontSize: "18px",
                  fontWeight: 400,
                  backgroundColor: sectionError === "air" ? "red" : ""
                }}
                title="Air & Fumes Temperatures"
              />
            </Card>
          </Grid>
          <Grid item md={3} xl={3} xs={12}>
            <Card variant="outlined" onClick={handleClickOpen}>
              <CardHeader
                action={
                  <IconButton aria-label="settings">
                    <Edit />
                  </IconButton>
                }
                style={{
                  fontSize: "18px",
                  fontWeight: 400,
                  backgroundColor: sectionError === "fumes" ? "red" : ""
                }}
                title="Fumes Gas Composition"
              />
            </Card>
          </Grid>
          <Grid item md={3} xl={3} xs={12}>
            <Card variant="outlined" onClick={handleClickDusts}>
              <CardHeader
                action={
                  <IconButton aria-label="settings">
                    <Edit />
                  </IconButton>
                }
                style={{
                  fontSize: "18px",
                  fontWeight: 400
                }}
                title="Dusts Analysis"
              />
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Temperatures open={openBatchData} handleClose={handleClose} />
      <FumesComposition open={open} handleClose={handleClose} />
      <DustAnalysis open={openRegeneratorData} handleClose={handleClose} />
    </div>
  );
};

export default Efficiencies;
