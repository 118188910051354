import React, { useState, useEffect } from "react";
import { Grid, Typography, Button, Box, Dialog, IconButton, Tooltip } from "@mui/material";
import {
  FlowsInputCard,
  FlowsOutputCard,
  EfficienciesInputCard,
  EfficienciesOutputCard,
  DustsInputCard,
  DustsOutputCard,
} from "../../../../components/ui/Summary";
import {
  calcGazData,
  calcFuelData,
  consumptionGasLeftData,
  consumptionFuelLeftData,
  consumptionGasRightData,
  consumptionFuelRightData,
  calcBatchLeftData,
  calcBatchRightData,
  regenDesignData,
  airFumesTempData,
  fumesGasCompLeftData,
  fumesGasCompRightData,
  dustsAnalysisData,
  calcExcelData,
} from "../../../../recoil/selectors";
import {
  customerInfoData,
  fuelInfo,
  furnaceDetails,
  temparatures,
  fumesComposition,
  fumesElemCorrelation,
  newVisitStatus,
  dustsAnalysis,
  getTrialDataID,
  savedTrialID,
  fuelStatus
} from "../../../../recoil/atoms";
import { useRecoilValue, useRecoilState } from "recoil";
import configParam from "../../../../config";
import axios from "axios";
import API from "../../../Services/API";
import generateExcelFile from "./GenerateExcel";
import InfoIcon from '@mui/icons-material/Info';
import { TableContainer, Table, TableRow, TableHead, TableBody, TableCell } from "@material-ui/core";

const Summary = (props) => {
  const [customerInfo] = useRecoilState(customerInfoData);
  const [fuelInform] = useRecoilState(fuelInfo);
  const [furnaceDet] = useRecoilState(furnaceDetails);
  const [airFumesCompo] = useRecoilState(temparatures);
  const [fumesCompo] = useRecoilState(fumesComposition);
  const [dusts] = useRecoilState(dustsAnalysis);
  const [fumesElemCorr] = useRecoilState(fumesElemCorrelation);
  const [visitStatus] = useRecoilState(newVisitStatus);
  const [trialDataID] = useRecoilState(getTrialDataID);
  const [trial_id] = useRecoilState(savedTrialID);
  const [, setIsGasOpen] = useState(false);
  const [, setIsFuelOpen] = useState(false);
  const fuelType = useRecoilValue(fuelStatus);
  const userDetails = localStorage.getItem("user");
  const userValue = JSON.parse(userDetails);

  const getSgId = configParam.GET_SG_ID;
  const getVisitNumber = configParam.getVisitNumbers;

  useEffect(() => {

  }, []);

  //Flows
  const curCalcGazData = useRecoilValue(calcGazData);
  const curCalcFuelData = useRecoilValue(calcFuelData);
  const consumpGasLeft = useRecoilValue(consumptionGasLeftData);
  const consumpGasRight = useRecoilValue(consumptionGasRightData);
  const consumpFuelLeft = useRecoilValue(consumptionFuelLeftData);
  const consumpFuelRight = useRecoilValue(consumptionFuelRightData);
  const batchLeft = useRecoilValue(calcBatchLeftData);
  const batchRight = useRecoilValue(calcBatchRightData);
  const regenerDesign = useRecoilValue(regenDesignData);

  //Efficiencies
  const airFumesTemp = useRecoilValue(airFumesTempData);

  const fumesGasCompLeft = useRecoilValue(fumesGasCompLeftData);
  const fumesGasCompRight = useRecoilValue(fumesGasCompRightData);

  //Dusts
  const tempDustsAnalysis = useRecoilValue(dustsAnalysisData);

  //Static Gas Data Calcualtions (Excel)
  const staticData = useRecoilValue(calcExcelData);

  //Flows
  const tempGazData = curCalcGazData.gazDetails;
  const tempFuelData = curCalcFuelData.fuelDetails;
  const tempConsumpGasLeft = consumpGasLeft.consumpGasLeftDetails;
  const tempConsumpGasRight = consumpGasRight.consumpGasRightDetails;
  const tempConsumpFuelLeft = consumpFuelLeft.consumpFuelLeftDetails;
  const tempConsumpFuelRight = consumpFuelRight.consumpFuelRightDetails;
  const tempBatchLeft = batchLeft.batchLeftDetails;
  const tempBatchRight = batchRight.batchRightDetails;
  const tempRegenerDesign = regenerDesign.regenDesignDetails;

  //Efficiencies
  const tempAirFumes = airFumesTemp.airFumesTempDetails;
  const tempFumesGasCompLeft = fumesGasCompLeft.concentrationLeftDetails;
  const tempFumesGasCompRight = fumesGasCompRight.concentrationRightDetails;

  //Dusts
  const dustsData = tempDustsAnalysis.dustsAnalysisDetails;

  //Static Gas Data Calcualtions (Excel)
  const staticExcelData = staticData.calcStaticExcelDetails;

  const getData = (val, cff, eff) => {
    const { furnaceType, chamberType } = customerInfo;

    if (furnaceType === "End Fired") {
      if (chamberType === "One Side") {
        return handleOneSide(val, eff);
      } else {
        return handleBothSides(val, eff);
      }
    } else {
      if (chamberType === "One Side") {
        return handleOneSide(val, cff);
      } else {
        return handleBothSides(val, cff);
      }
    }
  };

  const handleOneSide = (val, data) => {
    if (val === "singleValue") {
      return data;
    } else {
      return { symmetricData: data.symmetricData };
    }
  };

  const handleBothSides = (val, data) => {
    if (val === "singleValue") {
      return data;
    } else {
      return { left: data.left, right: data.right };
    }
  };


  //Common Function
  const commonFunction = (position, CFF, EFF, log) => {
    if (customerInfo.furnaceType === "End Fired") {
      if (customerInfo.chamberType === "One Side") {
        if (position === "singleValue") {
          return Object.values(EFF);
        } else {
          return Object.values(EFF.symmetricData);
        }
      } else {
        if (position === "left") {
          return Object.values(EFF.left);
        } else if (position === "singleValue") {
          return Object.values(EFF);
        } else {
          return Object.values(EFF.right);
        }
      }
    } else {
      let nonEmptyValues;
      if (customerInfo.chamberType === "One Side") {
        if (position === "singleValue") {
          const cffValues = Object.values(CFF);
          nonEmptyValues = cffValues.filter(value => value !== "");
          return nonEmptyValues != null && nonEmptyValues.length > 0 ? nonEmptyValues.join(", ") : "";
        } else {
          const symmetricCffValues = Object.values(CFF.symmetricData);
          nonEmptyValues = symmetricCffValues.filter(value => value !== "");
          return nonEmptyValues != null && nonEmptyValues.length > 0 ? nonEmptyValues.join(", ") : "";
        }
      } else {
        if (position === "left") {
          const cffLeftValues = Object.values(CFF.left);
          nonEmptyValues = cffLeftValues.filter(value => value !== "");
          return nonEmptyValues != null && nonEmptyValues.length > 0 ? nonEmptyValues.join(", ") : "";
        } else if (position === "singleValue") {
          const cffSingle = Object.values(CFF);
          nonEmptyValues = cffSingle.filter(value => value !== "");
          return nonEmptyValues != null && nonEmptyValues.length > 0 ? nonEmptyValues.join(", ") : "";
        } else {
          const cffRightValues = Object.values(CFF.right);
          nonEmptyValues = cffRightValues.filter(value => value !== "");
          return nonEmptyValues != null && nonEmptyValues.length > 0 ? nonEmptyValues.join(", ") : "";
        }
      }
    }
  };

  const fuelOrder = [
    "carbon", "hydrogen", "oxygen", "nitrogen", "sulphur", "humidity",
  ];
  const fuelAnalysisMass = fuelOrder && Array.isArray(fuelOrder)
    ? fuelOrder
      .map((key) => ({
        oil: key,
        analysisPercMass: fuelInform.oil && fuelInform.oil.analysisPercMass ? fuelInform.oil.analysisPercMass[key] : 0,
      }))
      .filter((item) => item.analysisPercMass !== undefined)
    : [];

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }
  const previewFlowsData = (type) => {
    if (type === "gas") {
      const gazOrder = [
        "h2", "co", "ch4", "c2h4", "c2h6", "c3h6", "c3h8", "c4h8", "c4h10", "c5h12", "co2", "n2", "o2",
      ];
      const gasAnalysisVol = gazOrder && gazOrder.length > 0
        ? gazOrder
          .map((key) => ({
            gas: key,
            volPercentage: fuelInform.gaz && fuelInform.gaz.volPercentage ? fuelInform.gaz.volPercentage[key] : 0,
          }))
          .filter((item) => item.volPercentage !== undefined)
        : [];
      return (
        <Tooltip
          title={
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ padding: 10, color: "#fff" }}>Fuel Analysis</TableCell>
                    <TableCell style={{ padding: 10, color: "#fff" }}>Mass(%)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody style={{ color: "#fff" }}>
                  {gasAnalysisVol.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell style={{ padding: 10, color: "#fff" }}>{item.gas.toUpperCase()}</TableCell>
                      <TableCell style={{ padding: 10, color: "#fff" }}>{item.volPercentage}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          }
          arrow
          placement="right"
        >
          <IconButton style={{ cursor: "pointer" }} title="Fuel Molecule">
            <InfoIcon />
          </IconButton>
        </Tooltip>
      )
    }
    else {
      if (!fuelAnalysisMass || !Array.isArray(fuelAnalysisMass)) {
        return null;
      }
      return (
        <div>
          <Tooltip
            title={
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ padding: 10, color: "#fff" }}>Fuel Analysis</TableCell>
                      <TableCell style={{ padding: 10, color: "#fff" }}>Mass(%)</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody style={{ color: "#fff" }}>
                    {fuelAnalysisMass.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell style={{ padding: 10, color: "#fff" }}>{capitalizeFirstLetter(item.oil)}</TableCell>
                        <TableCell style={{ padding: 10, color: "#fff" }}>{item.analysisPercMass}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            }
            arrow
          >
            <IconButton style={{ cursor: "pointer" }} title="Fuel Molecule">
              <InfoIcon />
            </IconButton>
          </Tooltip>
        </div >
      )
    }
  }

  //Flows Input Fields
  const fuelInputDetails = {};
  // "Gas Analysis(vol%)": tempGazData.gasAnalysisVol,
  if (fuelType.includes('0') && fuelType.includes('1')) {
    fuelInputDetails["Gas Analysis(vol%)"] = (
      <div>
        {previewFlowsData("gas")}
      </div>
    )
    if (customerInfo.chamberType === "One Side") {
      fuelInputDetails["Consumption Gas(Nm3/h)"] = commonFunction(
        "left",
        fuelInform.gaz.consumptionCFF,
        fuelInform.gaz.consumptionEFF
      );
    }
    else {
      fuelInputDetails["Consumption Gas(Nm3/h)(Left)"] = commonFunction(
        "left",
        fuelInform.gaz.consumptionCFF,
        fuelInform.gaz.consumptionEFF
      );
      fuelInputDetails["Consumption Gas(Nm3/h)(Right)"] = commonFunction(
        "right",
        fuelInform.gaz.consumptionCFF,
        fuelInform.gaz.consumptionEFF
      );
    }
    fuelInputDetails["Fuel Analysis(mass%)"] = (
      <div>
        {previewFlowsData("fuel")}
      </div>
    )
    if (customerInfo.chamberType === "One Side") {
      fuelInputDetails["Consumption Oil(kg/h)"] = commonFunction(
        "left",
        fuelInform.oil.consumptionCFF,
        fuelInform.oil.consumptionEFF
      );
    }
    else {
      fuelInputDetails["Consumption Oil(kg/h)(Left)"] = commonFunction(
        "left",
        fuelInform.oil.consumptionCFF,
        fuelInform.oil.consumptionEFF
      );
      fuelInputDetails["Consumption Oil(kg/h)(Right)"] = commonFunction(
        "right",
        fuelInform.oil.consumptionCFF,
        fuelInform.oil.consumptionEFF
      );
    }
  }
  else if (fuelType.includes('0')) {
    fuelInputDetails["Gas Analysis(vol%)"] = (
      <div>
        {previewFlowsData("gas")}
      </div>
    )
    if (customerInfo.chamberType === "One Side") {
      fuelInputDetails["Consumption Gas(Nm3/h)"] = commonFunction(
        "left",
        fuelInform.gaz.consumptionCFF,
        fuelInform.gaz.consumptionEFF
      );
    }
    else {
      fuelInputDetails["Consumption Gas(Nm3/h)(Left)"] = commonFunction(
        "left",
        fuelInform.gaz.consumptionCFF,
        fuelInform.gaz.consumptionEFF
      );
      fuelInputDetails["Consumption Gas(Nm3/h)(Right)"] = commonFunction(
        "right",
        fuelInform.gaz.consumptionCFF,
        fuelInform.gaz.consumptionEFF
      );
    }
  }
  // "Fuel Analysis(mass%)": tempFuelData.fuelAnalysisMass,
  else if (fuelType.includes('1')) {
    fuelInputDetails["Fuel Analysis(mass%)"] = (
      <div>
        {previewFlowsData("fuel")}
      </div>
    )
    if (customerInfo.chamberType === "One Side") {
      fuelInputDetails["Consumption Oil(kg/h)"] = commonFunction(
        "left",
        fuelInform.oil.consumptionCFF,
        fuelInform.oil.consumptionEFF
      );
    }
    else {
      fuelInputDetails["Consumption Oil(kg/h)(Left)"] = commonFunction(
        "left",
        fuelInform.oil.consumptionCFF,
        fuelInform.oil.consumptionEFF
      );
      fuelInputDetails["Consumption Oil(kg/h)(Right)"] = commonFunction(
        "right",
        fuelInform.oil.consumptionCFF,
        fuelInform.oil.consumptionEFF
      );
    }
  }
  //Flows Output Fields
  const fuelOutputDetails = {};
  if (fuelType.includes('0') && fuelType.includes('1')) {
    fuelOutputDetails["Total Vol%"] = tempGazData.gasAnalysisVolSum;
    fuelOutputDetails["Total Mass%"] = tempFuelData.fuelAnalysisMassSum;
    fuelOutputDetails["Fuel Carbon of Moles/kg"] = tempFuelData.fuelCarbonMoles;
    fuelOutputDetails["Fuel Hydrogen of Moles/kg"] = tempFuelData.fuelHydrogenMoles;
    fuelOutputDetails["Fuel Oxygen of Moles/kg"] = tempFuelData.fuelOxygenMoles;
    fuelOutputDetails["Fuel Nitrogen of Moles/kg"] = tempFuelData.fuelNitrogenMoles;
    fuelOutputDetails["Fuel Sulphur of Moles/kg"] = tempFuelData.fuelSulphurMoles;
    fuelOutputDetails["Fuel Humidity of Moles/kg"] = tempFuelData.fuelHumidityMoles;
    fuelOutputDetails["Total Fuel of Moles/kg"] = tempFuelData.totalFuelMoles;
    fuelOutputDetails["Fuel Oxygen Air(moles/kg)"] = staticExcelData.sumFuelAirMolesOxygen;
    fuelOutputDetails["Fuel Nitrogen Air(moles/kg)"] = staticExcelData.sumFuelAirMolesNitrogen;
    fuelOutputDetails["Total Fuel Air(moles/kg)"] = staticExcelData.sumFuelAirMoles;
    //Flows - Fuel - Gas & others  //Consumptions //Gas
    if (customerInfo.furnaceType === "Cross Fired") {
      if (customerInfo.chamberType === "One Side") {
        fuelOutputDetails["Total Consumption Gas"] =
          tempConsumpGasLeft.sumConsumpGasLeftSide;
        fuelOutputDetails["Total Consumption Oil"] =
          tempConsumpFuelLeft.sumConsumpFuelLeftSide;
      } else {
        fuelOutputDetails["Total Consumption Gas(Left)"] =
          tempConsumpGasLeft.sumConsumpGasLeftSide;
        fuelOutputDetails["Total Consumption Gas(Right)"] =
          tempConsumpGasRight.sumConsumpGasRightSide;
        fuelOutputDetails["Total Consumption Oil(Left)"] =
          tempConsumpFuelLeft.sumConsumpFuelLeftSide;
        fuelOutputDetails["Total Consumption Oil(Right)"] =
          tempConsumpFuelRight.sumConsumpFuelRightSide;
      }
    }
  }
  else if (fuelType.includes('0')) {
    fuelOutputDetails["Total Vol%"] = tempGazData.gasAnalysisVolSum;
    //Flows - Fuel - Gas & others  //Consumptions //Gas
    if (customerInfo.furnaceType === "Cross Fired") {
      if (customerInfo.chamberType === "One Side") {
        fuelOutputDetails["Total Consumption Gas"] =
          tempConsumpGasLeft.sumConsumpGasLeftSide;
      } else {
        fuelOutputDetails["Total Consumption Gas(Left)"] =
          tempConsumpGasLeft.sumConsumpGasLeftSide;
        fuelOutputDetails["Total Consumption Gas(Right)"] =
          tempConsumpGasRight.sumConsumpGasRightSide;
      }
    }
  }
  else if (fuelType.includes('1')) {
    fuelOutputDetails["Total Mass%"] = tempFuelData.fuelAnalysisMassSum;
    fuelOutputDetails["Fuel Carbon of Moles/kg"] = tempFuelData.fuelCarbonMoles;
    fuelOutputDetails["Fuel Hydrogen of Moles/kg"] = tempFuelData.fuelHydrogenMoles;
    fuelOutputDetails["Fuel Oxygen of Moles/kg"] = tempFuelData.fuelOxygenMoles;
    fuelOutputDetails["Fuel Nitrogen of Moles/kg"] = tempFuelData.fuelNitrogenMoles;
    fuelOutputDetails["Fuel Sulphur of Moles/kg"] = tempFuelData.fuelSulphurMoles;
    fuelOutputDetails["Fuel Humidity of Moles/kg"] = tempFuelData.fuelHumidityMoles;
    fuelOutputDetails["Total Fuel of Moles/kg"] = tempFuelData.totalFuelMoles;
    fuelOutputDetails["Fuel Oxygen Air(moles/kg)"] = staticExcelData.sumFuelAirMolesOxygen;
    fuelOutputDetails["Fuel Nitrogen Air(moles/kg)"] = staticExcelData.sumFuelAirMolesNitrogen;
    fuelOutputDetails["Total Fuel Air(moles/kg)"] = staticExcelData.sumFuelAirMoles;
    //Flows - Fuel - Gas & others  //Consumptions //Gas
    if (customerInfo.furnaceType === "Cross Fired") {
      if (customerInfo.chamberType === "One Side") {
        fuelOutputDetails["Total Consumption Oil"] =
          tempConsumpFuelLeft.sumConsumpFuelLeftSide;
      } else {
        fuelOutputDetails["Total Consumption Oil(Left)"] =
          tempConsumpFuelLeft.sumConsumpFuelLeftSide;
        fuelOutputDetails["Total Consumption Oil(Right)"] =
          tempConsumpFuelRight.sumConsumpFuelRightSide;
      }
    }
  }
  //Flows Batch Input Fields
  const batchInputDetails = {
    "Pull Rate (t/d)": furnaceDet.batchData.pullRate,
    "Cullet(%)": furnaceDet.batchData.cullet,
    "H2O(%) in batch": furnaceDet.batchData.h2oPercBatch,
    "Loss on Ignition(%)": furnaceDet.batchData.ignitionLoss,
    ...(customerInfo.chamberType === "One Side"
      ? {
        "DGC Distribution(%)": commonFunction(
          "left",
          furnaceDet.batchData.distributionCFF,
          furnaceDet.batchData.distributionEFF
        ),
      }
      : {
        "DGC Distribution(%)(Left)": commonFunction(
          "left",
          furnaceDet.batchData.distributionCFF,
          furnaceDet.batchData.distributionEFF
        ),
        "DGC Distribution(%)(Right)": commonFunction(
          "right",
          furnaceDet.batchData.distributionCFF,
          furnaceDet.batchData.distributionEFF
        ),
      }),
  };

  //Flows Batch Output Fields
  const batchOutputDetails = {
    ...(customerInfo.furnaceType === "Cross Fired"
      ? {
        ...(customerInfo.chamberType === "One Side"
          ? { "Total DGC Distribution(%)": tempBatchLeft.sumBatchLeftPercentage }
          : {
            "Total DGC Distribution(%)(Left)":
              tempBatchLeft.sumBatchLeftPercentage,
            "Total DGC Distribution(%)(Right)":
              tempBatchRight.sumBatchRightPercentage,
          }),
      }
      : {}),
  };

  //Flows Regenerator Design Input Fields
  const regenInputDetails = {
    "Chamber dimensions Depth(mm)": commonFunction(
      "singleValue",
      furnaceDet.regeneratorDesign.chamberDimensionsCFF.depth,
      furnaceDet.regeneratorDesign.chamberDimensionsEFF.depth
    ),
    "Chamber dimensions Width(mm)": commonFunction(
      "singleValue",
      furnaceDet.regeneratorDesign.chamberDimensionsCFF.width,
      furnaceDet.regeneratorDesign.chamberDimensionsEFF.width
    ),
    "Packing Properties Checkers thickness(mm)": commonFunction(
      "singleValue",
      furnaceDet.regeneratorDesign.packingPropertiesCFF.thickness,
      furnaceDet.regeneratorDesign.packingPropertiesEFF.thickness
    ),
    "Packing Properties Channel size(mm)": commonFunction(
      "singleValue",
      furnaceDet.regeneratorDesign.packingPropertiesCFF.size,
      furnaceDet.regeneratorDesign.packingPropertiesEFF.size
    ),
    "No of Channels Depth(mm)": commonFunction(
      "singleValue",
      furnaceDet.regeneratorDesign.noOfChannelsCFF.depthChannels,
      furnaceDet.regeneratorDesign.noOfChannelsEFF.depthChannels
    ),
    "No of Channels Width(mm)": commonFunction(
      "singleValue",
      furnaceDet.regeneratorDesign.noOfChannelsCFF.widthChannels,
      furnaceDet.regeneratorDesign.noOfChannelsEFF.widthChannels
    ),
  };

  //Flows Regenerator Design Output Fields
  const regenOutputDetails = {
    // "Number of Flues Depth": tempRegenerDesign.numberOfFluesB ? tempRegenerDesign.numberOfFluesB.join(", ") : "",
    // "Number of Flues Width": tempRegenerDesign.numberOfFluesA ? tempRegenerDesign.numberOfFluesA.join(", ") : "",
    "Available Area Depth(mm)": tempRegenerDesign.availableAreaB ? tempRegenerDesign.availableAreaB.join(", ") : "",
    "Available Area Width(mm)": tempRegenerDesign.availableAreaA ? tempRegenerDesign.availableAreaA.join(", ") : "",
  };

  //Efficiencies Air & Fumes Temperature Input Fields
  const airFumesTempInputDetails = {
    ...(customerInfo.chamberType === "One Side"
      ? {
        "Bottom(cold air)[°C]": commonFunction(
          "left",
          airFumesCompo.airTemperatureCFF.bottom,
          airFumesCompo.airTemperatureEFF.bottom
        ),
      }
      : {
        "Bottom(cold air)[°C](Left)": commonFunction(
          "left",
          airFumesCompo.airTemperatureCFF.bottom,
          airFumesCompo.airTemperatureEFF.bottom
        ),
        "Bottom(cold air)[°C](Right)": commonFunction(
          "right",
          airFumesCompo.airTemperatureCFF.bottom,
          airFumesCompo.airTemperatureEFF.bottom
        ),
      }),
    ...(customerInfo.chamberType === "One Side"
      ? {
        "Top(preheated air)[°C]": commonFunction(
          "left",
          airFumesCompo.airTemperatureCFF.top,
          airFumesCompo.airTemperatureEFF.top
        ),
      }
      : {
        "Top(preheated air)[°C](Left)": commonFunction(
          "left",
          airFumesCompo.airTemperatureCFF.top,
          airFumesCompo.airTemperatureEFF.top
        ),
        "Top(preheated air)[°C](Right)": commonFunction(
          "right",
          airFumesCompo.airTemperatureCFF.top,
          airFumesCompo.airTemperatureEFF.top
        ),
      }),
    ...(customerInfo.chamberType === "One Side"
      ? {
        "Bottom(cold fumes)[°C]": commonFunction(
          "left",
          airFumesCompo.fumesTemperatureCFF.bottom,
          airFumesCompo.fumesTemperatureEFF.bottom
        ),
      }
      : {
        "Bottom(cold fumes)[°C](Left)": commonFunction(
          "left",
          airFumesCompo.fumesTemperatureCFF.bottom,
          airFumesCompo.fumesTemperatureEFF.bottom
        ),
        "Bottom(cold fumes)[°C](Right)": commonFunction(
          "right",
          airFumesCompo.fumesTemperatureCFF.bottom,
          airFumesCompo.fumesTemperatureEFF.bottom
        ),
      }),
    ...(customerInfo.chamberType === "One Side"
      ? {
        "Top(hot fumes)[°C]": commonFunction(
          "left",
          airFumesCompo.fumesTemperatureCFF.top,
          airFumesCompo.fumesTemperatureEFF.top
        ),
      }
      : {
        "Top(hot fumes)[°C](Left)": commonFunction(
          "left",
          airFumesCompo.fumesTemperatureCFF.top,
          airFumesCompo.fumesTemperatureEFF.top
        ),
        "Top(hot fumes)[°C](Right)": commonFunction(
          "right",
          airFumesCompo.fumesTemperatureCFF.top,
          airFumesCompo.fumesTemperatureEFF.top
        ),
      }),
  };

  //Efficiencies Air & Fumes Temperature Output Fields
  const airFumesTempOutputDetails = {
    ...(customerInfo.chamberType === "One Side" ||
      (customerInfo.furnaceType === "End Fired" &&
        customerInfo.chamberType === "Both Side")
      ? { "Total Bottom(cold air)[°C]": tempAirFumes.bottomColdAirLeftAverage }
      : {
        "Total Bottom(cold air)[°C](Left)":
          tempAirFumes.bottomColdAirLeftAverage,
        "Total Bottom(cold air)[°C](Right)":
          tempAirFumes.bottomColdAirRightAverage,
      }),

    ...(customerInfo.chamberType === "One Side" ||
      (customerInfo.furnaceType === "End Fired" &&
        customerInfo.chamberType === "Both Side")
      ? {
        "Total Top(preheated air)[°C]":
          tempAirFumes.topPreheatedAirLeftAverage,
      }
      : {
        "Total Top(preheated air)[°C](Left)":
          tempAirFumes.topPreheatedAirLeftAverage,
        "Total Top(preheated air)[°C](Right)":
          tempAirFumes.topPreheatedAirRightAverage,
      }),

    ...(customerInfo.chamberType === "One Side" ||
      (customerInfo.furnaceType === "End Fired" &&
        customerInfo.chamberType === "Both Side")
      ? {
        "Total Bottom(cold fumes)[°C]":
          tempAirFumes.bottomColdFumesLeftAverage,
      }
      : {
        "Total Bottom(cold fumes)[°C](Left)":
          tempAirFumes.bottomColdFumesLeftAverage,
        "Total Bottom(cold fumes)[°C](Right)":
          tempAirFumes.bottomColdFumesRightAverage,
      }),

    ...(customerInfo.chamberType === "One Side" ||
      (customerInfo.furnaceType === "End Fired" &&
        customerInfo.chamberType === "Both Side")
      ? { "Total Top(hot fumes)[°C]": tempAirFumes.topHotFumesLeftAverage }
      : {
        "Total Top(hot fumes)[°C](Left)": tempAirFumes.topHotFumesLeftAverage,
        "Total Top(hot fumes)[°C](Right)":
          tempAirFumes.topHotFumesRightAverage,
      }),

    ...(customerInfo.chamberType === "One Side"
      ? {
        "Thermal Efficiency(%)":
          tempAirFumes.themalEfficiencyLeft ? tempAirFumes.themalEfficiencyLeft.join(", ") : "",
      }
      : {
        "Thermal Efficiency (%) (Left)":
          tempAirFumes.themalEfficiencyLeft ? tempAirFumes.themalEfficiencyLeft.join(", ") : "",
        "Thermal Efficiency (%) (Right)":
          tempAirFumes.themalEfficiencyRight ? tempAirFumes.themalEfficiencyRight.join(", ") : "",
      }),

    ...(customerInfo.furnaceType === "Cross Fired" ||
      (customerInfo.furnaceType === "End Fired" &&
        customerInfo.chamberType === "Both Side")
      ? {
        ...(customerInfo.chamberType === "One Side"
          ? {
            "Total Thermal Efficiency(%)":
              tempAirFumes.themalEffiTotalAvgLeft,
          }
          : {
            "Total Thermal Efficiency(%)(Left)":
              tempAirFumes.themalEffiTotalAvgLeft,
            "Total Thermal Efficiency(%)(Right)":
              tempAirFumes.themalEffiTotalAvgRight,
          }),
      }
      : {}),

    ...(customerInfo.chamberType === "One Side" ||
      (customerInfo.furnaceType === "End Fired" &&
        customerInfo.chamberType === "Both Side")
      ? { "EBM Energy Efficiency Definition(%)": staticExcelData.ebmEnergyEffDefLeft ? staticExcelData.ebmEnergyEffDefLeft.join(", ") : "" }
      : {
        "EBM Energy Efficiency Definition(%)(Left)": staticExcelData.ebmEnergyEffDefLeft ? staticExcelData.ebmEnergyEffDefLeft.join(", ") : "",
        "EBM Energy Efficiency Definition(%)(Right)":
          staticExcelData.ebmEnergyEffDefRight ? staticExcelData.ebmEnergyEffDefRight.join(", ") : "",
      }),
  };

  //Efficiencies Fumes Gas Composition Input Fields
  const fumesGasCompInputDetails = {
    ...(customerInfo.chamberType === "One Side"
      ? {
        "O2 concentration[vol%]": commonFunction(
          "left",
          fumesCompo.compositionCFF.o2,
          fumesCompo.compositionEFF.o2
        ),
      }
      : {
        "O2 concentration[vol%](Left)": commonFunction(
          "left",
          fumesCompo.compositionCFF.o2,
          fumesCompo.compositionEFF.o2
        ),
        "O2 concentration[vol%](Right)": commonFunction(
          "right",
          fumesCompo.compositionCFF.o2,
          fumesCompo.compositionEFF.o2
        ),
      }),
    ...(customerInfo.chamberType === "One Side"
      ? {
        "CO2 concentration[vol%]": commonFunction(
          "left",
          fumesCompo.compositionCFF.co2,
          fumesCompo.compositionEFF.co2
        ),
      }
      : {
        "CO2 concentration[vol%](Left)": commonFunction(
          "left",
          fumesCompo.compositionCFF.co2,
          fumesCompo.compositionEFF.co2
        ),
        "CO2 concentration[vol%](Right)": commonFunction(
          "right",
          fumesCompo.compositionCFF.co2,
          fumesCompo.compositionEFF.co2
        ),
      }),
    ...(customerInfo.chamberType === "One Side"
      ? {
        "H2O concentration[vol%]": commonFunction(
          "left",
          fumesCompo.compositionCFF.h2o,
          fumesCompo.compositionEFF.h2o
        ),
      }
      : {
        "H2O concentration[vol%](Left)": commonFunction(
          "left",
          fumesCompo.compositionCFF.h2o,
          fumesCompo.compositionEFF.h2o
        ),
        "H2O concentration[vol%](Right)": commonFunction(
          "right",
          fumesCompo.compositionCFF.h2o,
          fumesCompo.compositionEFF.h2o
        ),
      }),
    ...(customerInfo.chamberType === "One Side"
      ? {
        "O2 measured bottom%": commonFunction(
          "left",
          fumesCompo.compositionCFF.o2Bottom,
          fumesCompo.compositionEFF.o2Bottom
        ),
      }
      : {
        "O2 measured bottom%(Left)": commonFunction(
          "left",
          fumesCompo.compositionCFF.o2Bottom,
          fumesCompo.compositionEFF.o2Bottom
        ),
        "O2 measured bottom%(Right)": commonFunction(
          "right",
          fumesCompo.compositionCFF.o2Bottom,
          fumesCompo.compositionEFF.o2Bottom
        ),
      }),
    ...(customerInfo.chamberType === "One Side"
      ? {
        "O2 Dry air Top%": commonFunction(
          "left",
          fumesCompo.compositionCFF.o2Top,
          fumesCompo.compositionEFF.o2Top
        ),
      }
      : {
        "O2 Dry air Top%(Left)": commonFunction(
          "left",
          fumesCompo.compositionCFF.o2Top,
          fumesCompo.compositionEFF.o2Top
        ),
        "O2 Dry air Top%(Right)": commonFunction(
          "right",
          fumesCompo.compositionCFF.o2Top,
          fumesCompo.compositionEFF.o2Top
        ),
      }),
  };

  //Efficiencies Fumes Gas Composition Output Fields
  const fumesGasCompositionOutputDetails = {
    ...(customerInfo.furnaceType === "Cross Fired"
      ? {
        ...(customerInfo.chamberType === "One Side"
          ? {
            "O2 measured bottom(%)":
              tempFumesGasCompLeft.avgO2BottomConLeftSide,
          }
          : {
            "O2 measured bottom(%)(Left)":
              tempFumesGasCompLeft.avgO2BottomConLeftSide,
            "O2 measured bottom(%)(Right)":
              tempFumesGasCompRight.avgO2BottomConRightSide,
          }),
      }
      : {}),
    ...(customerInfo.furnaceType === "Cross Fired"
      ? {
        ...(customerInfo.chamberType === "One Side"
          ? {
            "O2 Dry air Top(%)": tempFumesGasCompLeft.avgO2TopConLeftSide
          }
          : {
            "O2 Dry air Top(%)(Left)":
              tempFumesGasCompLeft.avgO2TopConLeftSide,
            "O2 Dry air Top(%)(Right)":
              tempFumesGasCompRight.avgO2TopConRightSide,
          }),
      }
      : {}),
    ...(customerInfo.chamberType === "One Side"
      ? {
        "N2 (%)Vol":
          tempFumesGasCompLeft.sumN2ConcentrationLeft ? tempFumesGasCompLeft.sumN2ConcentrationLeft.join(", ") : "",
      }
      : {
        "N2 Vol(%)(Left)":
          tempFumesGasCompLeft.sumN2ConcentrationLeft ? tempFumesGasCompLeft.sumN2ConcentrationLeft.join(", ") : "",
        "N2 Vol(%)(Right)":
          tempFumesGasCompRight.sumN2ConcentrationRight ? tempFumesGasCompRight.sumN2ConcentrationRight.join(", ") : "",
      }),

    ...(customerInfo.chamberType === "One Side"
      ? {
        "Total (CO2, N2, H2O, O2)":
          tempFumesGasCompLeft.totalPercentageVolLeft ? tempFumesGasCompLeft.totalPercentageVolLeft.join(", ") : "",
      }
      : {
        "Total (CO2, N2, H2O, O2)(Left)":
          tempFumesGasCompLeft.totalPercentageVolLeft ? tempFumesGasCompLeft.totalPercentageVolLeft.join(", ") : "",
        "Total (CO2, N2, H2O, O2)(Right)":
          tempFumesGasCompRight.totalPercentageVolRight ? tempFumesGasCompRight.totalPercentageVolRight.join(", ") : "",
      }),

    ...(customerInfo.chamberType === "One Side"
      ? { "ρ(kg/m3)": tempFumesGasCompLeft.calFumesCompRLeft ? tempFumesGasCompLeft.calFumesCompRLeft.join(", ") : "" }
      : {
        "ρ(kg/m3)(Left)": tempFumesGasCompLeft.calFumesCompRLeft ? tempFumesGasCompLeft.calFumesCompRLeft.join(", ") : "",
        "ρ(kg/m3)(Right)":
          tempFumesGasCompRight.calFumesCompRRight ? tempFumesGasCompRight.calFumesCompRRight.join(", ") : "",
      }),

    ...(customerInfo.chamberType === "One Side"
      ? { "M(g/mol)": tempFumesGasCompLeft.calFumesCompMLeft ? tempFumesGasCompLeft.calFumesCompMLeft.join(", ") : "" }
      : {
        "M(g/mol)(Left)": tempFumesGasCompLeft.calFumesCompMLeft ? tempFumesGasCompLeft.calFumesCompMLeft.join(", ") : "",
        "M(g/mol)(Right)":
          tempFumesGasCompRight.calFumesCompMRight ? tempFumesGasCompRight.calFumesCompMRight.join(", ") : "",
      }),

    ...(customerInfo.chamberType === "One Side"
      ? {
        "CO2(Mass%)":
          tempFumesGasCompLeft.totalCO2PercentageMassLeft ? tempFumesGasCompLeft.totalCO2PercentageMassLeft.join(", ") : "",
      }
      : {
        "CO2(Mass%)(Left)":
          tempFumesGasCompLeft.totalCO2PercentageMassLeft ? tempFumesGasCompLeft.totalCO2PercentageMassLeft.join(", ") : "",
        "CO2(Mass%)(Right)":
          tempFumesGasCompRight.totalCO2PercentageMassRight ? tempFumesGasCompRight.totalCO2PercentageMassRight.join(", ") : "",
      }),

    ...(customerInfo.chamberType === "One Side"
      ? {
        "N2(Mass%)":
          tempFumesGasCompLeft.totalN2PercentageMassLeft ? tempFumesGasCompLeft.totalN2PercentageMassLeft.join(", ") : "",
      }
      : {
        "N2(Mass%)(Left)":
          tempFumesGasCompLeft.totalN2PercentageMassLeft ? tempFumesGasCompLeft.totalN2PercentageMassLeft.join(", ") : "",
        "N2(Mass%)(Right)":
          tempFumesGasCompRight.totalN2PercentageMassRight ? tempFumesGasCompRight.totalN2PercentageMassRight.join(", ") : "",
      }),

    ...(customerInfo.chamberType === "One Side"
      ? {
        "H2O(Mass%)":
          tempFumesGasCompLeft.totalH2OPercentageMassLeft ? tempFumesGasCompLeft.totalH2OPercentageMassLeft.join(", ") : "",
      }
      : {
        "H2O(Mass%)(Left)":
          tempFumesGasCompLeft.totalH2OPercentageMassLeft ? tempFumesGasCompLeft.totalH2OPercentageMassLeft.join(", ") : "",
        "H2O(Mass%)(Right)":
          tempFumesGasCompRight.totalH2OPercentageMassRight ? tempFumesGasCompRight.totalH2OPercentageMassRight.join(", ") : "",
      }),

    ...(customerInfo.chamberType === "One Side"
      ? {
        "O2(Mass%)":
          tempFumesGasCompLeft.totalO2PercentageMassLeft ? tempFumesGasCompLeft.totalO2PercentageMassLeft.join(", ") : "",
      }
      : {
        "O2(Mass%)(Left)":
          tempFumesGasCompLeft.totalO2PercentageMassLeft ? tempFumesGasCompLeft.totalO2PercentageMassLeft.join(", ") : "",
        "O2(Mass%)(Right)":
          tempFumesGasCompRight.totalO2PercentageMassRight ? tempFumesGasCompRight.totalO2PercentageMassRight.join(", ") : "",
      }),
  };

  //Dusts Analysis Input Fields
  const dustsAnalysisInputDetails = {
    "Carryovers [mg/Nm3] SiO2": commonFunction(
      "singleValue",
      dusts.carryoversCFF.sio2,
      dusts.carryoversEFF.sio2
    ),
    "Carryovers [mg/Nm3] CaO2": commonFunction(
      "singleValue",
      dusts.carryoversCFF.cao,
      dusts.carryoversEFF.cao
    ),
    "Carryovers [mg/Nm3] MgO": commonFunction(
      "singleValue",
      dusts.carryoversCFF.mgo,
      dusts.carryoversEFF.mgo
    ),
    "Carryovers [mg/Nm3] Al2O3": commonFunction(
      "singleValue",
      dusts.carryoversCFF.al2o3,
      dusts.carryoversEFF.al2o3
    ),
    "Alkaline Compounds [mg/Nm3] Na2O": commonFunction(
      "singleValue",
      dusts.alkalineCompoundsCFF.na2o,
      dusts.alkalineCompoundsEFF.na2o
    ),
    "Alkaline Compounds [mg/Nm3] K2O": commonFunction(
      "singleValue",
      dusts.alkalineCompoundsCFF.k2o,
      dusts.alkalineCompoundsEFF.k2o
    ),
    "Alkaline Compounds [mg/Nm3] SO4": commonFunction(
      "singleValue",
      dusts.alkalineCompoundsCFF.so4,
      dusts.alkalineCompoundsEFF.so4
    ),
    "Others [mg/Nm3] P2O5": commonFunction(
      "singleValue",
      dusts.othersCFF.p2o5,
      dusts.othersEFF.p2o5
    ),
    "Others [mg/Nm3] Cr2O3": commonFunction(
      "singleValue",
      dusts.othersCFF.cr2o3,
      dusts.othersEFF.cr2o3
    ),
    "Others [mg/Nm3] Fe2O3": commonFunction(
      "singleValue",
      dusts.othersCFF.fe2o3,
      dusts.othersEFF.fe2o3
    ),
    "Others [mg/Nm3] PbO": commonFunction(
      "singleValue",
      dusts.othersCFF.pbo,
      dusts.othersEFF.pbo
    ),
    "Others [mg/Nm3] V2O5": commonFunction(
      "singleValue",
      dusts.othersCFF.v2o5,
      dusts.othersEFF.v2o5
    ),
    "Others [mg/Nm3] Cl": commonFunction(
      "singleValue",
      dusts.othersCFF.cl,
      dusts.othersEFF.cl
    ),
    "Others [mg/Nm3] NO3": commonFunction(
      "singleValue",
      dusts.othersCFF.no3,
      dusts.othersEFF.no3
    ),
  };

  //Dusts Analysis Output Fields
  const dustsAnalysisOutputDetails = {
    "Total dusts": dustsData.sumDustsAnalysisArray ? dustsData.sumDustsAnalysisArray.join(", ") : "",
  };

  const getJsonb = (val) => {
    if (val === "naturalgas") {
      let result = {
        Natural_gas_properties: {
          status: fuelInform.gaz.status,
          "Vol(%)": {
            ...fuelInform.gaz.volPercentage,
            ...{ Total: tempGazData.gasAnalysisVolSum },
          },
          Density: {},
          "Air/combustible ratio (Air Nm3/Gas Nm3)": {},
          "Combustion Fumes (Nm3/Gas Nm3)": {
            CO2: {},
            H2O: {},
          },
          "Combustion Heat (kWh/Nm3)": {
            LHV: {},
            HHV: {},
          },
          "Smoke Index ([Nm3/Gas Nm3])": {
            Dry: {},
            Wet: {},
          },
          "Consumption Gas": {
            "Consumption Gas(Nm3/h)": getData(
              "consumption",
              fuelInform.gaz.consumptionCFF,
              fuelInform.gaz.consumptionEFF
            ),
          },
          "Total Consumption Gas": {
            ...(customerInfo.furnaceType === "Cross Fired"
              ? {
                ...(customerInfo.chamberType === "One Side"
                  ? {
                    "Total Consumption Gas": {
                      symmetricData: tempConsumpGasLeft.sumConsumpGasLeftSide,
                    },
                  }
                  : {
                    "Total Consumption Gas": {
                      left: tempConsumpGasLeft.sumConsumpGasLeftSide,
                      right: tempConsumpGasRight.sumConsumpGasRightSide,
                    },
                  }),
              }
              : {}),
          },
          "Consumption Gas(%)": {
            ...(customerInfo.furnaceType === "Cross Fired"
              ? {
                ...(customerInfo.chamberType === "One Side"
                  ? {
                    "Consumption Gas(%)": {
                      symmetricData:
                        tempConsumpGasLeft.gazLeftPercentageSide,
                    },
                  }
                  : {
                    "Consumption Gas(%)": {
                      left: tempConsumpGasLeft.gazLeftPercentageSide,
                      right:
                        tempConsumpGasRight.gazRightPercentageSide,
                    },
                  }),
              }
              : {}),
          },
          "Total Consumption Gas(%)": {
            ...(customerInfo.furnaceType === "Cross Fired"
              ? {
                ...(customerInfo.chamberType === "One Side"
                  ? {
                    "Total Consumption Gas(%)": {
                      symmetricData: tempConsumpGasLeft.gazLeftTotalAvgPercentage,
                    },
                  }
                  : {
                    "Total Consumption Gas(%)": {
                      left: tempConsumpGasLeft.gazLeftTotalAvgPercentage,
                      right: tempConsumpGasRight.gazRightTotalAvgPercentage,
                    },
                  }),
              }
              : {}),
          },
        },
      };
      if (fuelType.includes('0')) {
        result.Natural_gas_properties.Density = {
          ...fuelInform.gaz.density,
          densityTotal: staticExcelData.totalDensity,
        };
        result.Natural_gas_properties["Air/combustible ratio (Air Nm3/Gas Nm3)"] = {
          ...(customerInfo.furnaceType === "Cross Fired"
            ? {
              ...fuelInform.gaz.airCombustion,
              airCombustibleRatioTotal: staticExcelData.totalAirCombustible
            }
            : {
              ...fuelInform.gaz.airCombustionEFF,
              airCombustibleRatioEFFTotal: staticExcelData.totalAirCombustibleEFF
            }),
        };
        result.Natural_gas_properties["Combustion Fumes (Nm3/Gas Nm3)"] = {
          CO2: {
            ...fuelInform.gaz.combustionFumes.co2,
            combustionFumesCO2Total: staticExcelData.totalCombustionFumesCO2,
          },
          H2O: {
            ...fuelInform.gaz.combustionFumes.h2o,
            combustionFumesH2OTotal: staticExcelData.totalCombustionFumesH2O,
          },
        };
        result.Natural_gas_properties["Combustion Heat (kWh/Nm3)"] = {
          LHV: {
            ...fuelInform.gaz.combustionHeat.lhv,
            CombustionHeatLHVTotal: staticExcelData.totalCombustionHeatLHV,
          },
          HHV: {
            ...fuelInform.gaz.combustionHeat.hhv,
            CombustionHeatHHVTotal: staticExcelData.totalCombustionHeatHHV,
          },
        };
        result.Natural_gas_properties["Smoke Index ([Nm3/Gas Nm3])"] = {
          Dry: staticExcelData.sumGazSmokeIndexDry,
          Wet: staticExcelData.sumGazSmokeIndexWet,
        };
        result.Natural_gas_properties["Consumption Gas"] = {
          "Consumption Gas(Nm3/h)": getData(
            "consumption",
            fuelInform.gaz.consumptionCFF,
            fuelInform.gaz.consumptionEFF
          ),
        };
        result.Natural_gas_properties["Total Consumption Gas"] = {
          ...(customerInfo.furnaceType === "Cross Fired"
            ? {
              ...(customerInfo.chamberType === "One Side"
                ? {
                  "Total Consumption Gas": {
                    symmetricData: tempConsumpGasLeft.sumConsumpGasLeftSide,
                  },
                }
                : {
                  "Total Consumption Gas": {
                    left: tempConsumpGasLeft.sumConsumpGasLeftSide,
                    right: tempConsumpGasRight.sumConsumpGasRightSide,
                  },
                }),
            }
            : {}),
        };
        result.Natural_gas_properties["Consumption Gas(%)"] = {
          ...(customerInfo.furnaceType === "Cross Fired"
            ? {
              ...(customerInfo.chamberType === "One Side"
                ? {
                  "Consumption Gas(%)": {
                    symmetricData: tempConsumpGasLeft.gazLeftPercentageSide,
                  },
                }
                : {
                  "Consumption Gas(%)": {
                    left: tempConsumpGasLeft.gazLeftPercentageSide,
                    right: tempConsumpGasRight.gazRightPercentageSide,
                  },
                }),
            }
            : {}),
        };
        result.Natural_gas_properties["Total Consumption Gas(%)"] = {
          ...(customerInfo.furnaceType === "Cross Fired"
            ? {
              ...(customerInfo.chamberType === "One Side"
                ? {
                  "Total Consumption Gas(%)": {
                    symmetricData: tempConsumpGasLeft.gazLeftTotalAvgPercentage,
                  },
                }
                : {
                  "Total Consumption Gas(%)": {
                    left: tempConsumpGasLeft.gazLeftTotalAvgPercentage,
                    right: tempConsumpGasRight.gazRightTotalAvgPercentage,
                  },
                }),
            }
            : {}),
        };
      }
      return JSON.stringify(result);
    } else if (val === "heavyoil") {
      let result = {
        Heavy_Oil_Properties: {
          status: fuelInform.oil.status,
          Analysis: {
            "Mass%": {
              ...fuelInform.oil.analysisPercMass,
              ...{ Total: tempFuelData.fuelAnalysisMassSum },
            },
            "Moles / kg": {
              carbon: tempFuelData.fuelCarbonMoles,
              hydrogen: tempFuelData.fuelHydrogenMoles,
              oxygen: tempFuelData.fuelOxygenMoles,
              nitrogen: tempFuelData.fuelNitrogenMoles,
              sulphur: tempFuelData.fuelSulphurMoles,
              humidity: tempFuelData.fuelHumidityMoles,
              moleskgTotal: tempFuelData.totalFuelMoles,
            },
          },
          "Air (moles/kg)": {
            carbon: {},
            hydrogen: {},
            oxygen: {},
            nitrogen: {},
            sulphur: {},
            humidity: {},
            airTotal: {},
          },
          // Air/combustible ratio
          "Air/combustible ratio": {
            "Pouvoir comburivore(Nm3air/kg)": {},
            "Combustion Fumes CO2(Nm3/kg fuel)": {},
            "Combustion Fumes H2O(Nm3/kg fuel)": {},
            "Combustion Fumes N2(Nm3/kg fuel)": {},
            "Combustion Fumes SO2(Nm3/kg fuel)": {},
            "Smoke Index Dry(Nm3/kg fuel)": {},
            "Smoke Index Wet(Nm3/kg fuel)": {},
            "Combustion Heat HHV(kwh/kg)": {},
            "Combustion Heat LHV(kwh/kg)": {},
          },
          // Fuel Data Calculated Datas
          "Calculated Datas": {
            "Gas Smoke Index(Nm3/Nm3 or kg)": {},
            "Fuel Smoke Index(Nm3/Nm3 or kg)": {},
            "Gas Air/combustible ratio(Nm3 air/u comb)": {},
            "Fuel Air/combustible ratio(Nm3 air/u comb)": {},
          },
          "Consumption Oil": {
            "Consumption Oil(kg/h)": getData(
              "consumption",
              fuelInform.oil.consumptionCFF,
              fuelInform.oil.consumptionEFF
            ),
          },
          "Total Consumption Oil": {
            ...(customerInfo.furnaceType === "Cross Fired"
              ? {
                ...(customerInfo.chamberType === "One Side"
                  ? {
                    "Total Consumption Oil": {
                      symmetricData: tempConsumpFuelLeft.sumConsumpFuelLeftSide,
                    },
                  }
                  : {
                    "Total Consumption Oil": {
                      left: tempConsumpFuelLeft.sumConsumpFuelLeftSide,
                      right: tempConsumpFuelRight.sumConsumpFuelRightSide,
                    },
                  }),
              }
              : {}),
          },
          "Consumption Oil(%)": {
            ...(customerInfo.furnaceType === "Cross Fired"
              ? {
                ...(customerInfo.chamberType === "One Side"
                  ? {
                    "Consumption Oil(%)": {
                      symmetricData:
                        tempConsumpFuelLeft.fuelLeftPercentageSide,
                    },
                  }
                  : {
                    "Consumption Oil(%)": {
                      left: tempConsumpFuelLeft.fuelLeftPercentageSide,
                      right:
                        tempConsumpFuelRight.fuelRightPercentageSide,
                    },
                  }),
              }
              : {}),
          },
          "Total Consumption Oil(%)": {
            ...(customerInfo.furnaceType === "Cross Fired"
              ? {
                ...(customerInfo.chamberType === "One Side"
                  ? {
                    "Total Consumption Oil(%)": {
                      symmetricData:
                        tempConsumpFuelLeft.fuelLeftTotalAvgPercentage,
                    },
                  }
                  : {
                    "Total Consumption Oil(%)": {
                      left: tempConsumpFuelLeft.fuelLeftTotalAvgPercentage,
                      right: tempConsumpFuelRight.fuelRightTotalAvgPercentage,
                    },
                  }),
              }
              : {}),
          },
        },
      };
      if (fuelType.includes('1')) {
        result.Heavy_Oil_Properties["Air (moles/kg)"] = {
          carbon: "0",
          hydrogen: "0",
          oxygen: staticExcelData.sumFuelAirMolesOxygen,
          nitrogen: staticExcelData.sumFuelAirMolesNitrogen,
          sulphur: "0",
          humidity: "0",
          airTotal: staticExcelData.sumFuelAirMoles,
        };
        result.Heavy_Oil_Properties["Air/combustible ratio"] = {
          "Pouvoir comburivore(Nm3air/kg)": staticExcelData.airPouvoir,
          "Combustion Fumes CO2(Nm3/kg fuel)": staticExcelData.combustionFumesCO2,
          "Combustion Fumes H2O(Nm3/kg fuel)": staticExcelData.sumCombustionFumesH2O,
          "Combustion Fumes N2(Nm3/kg fuel)": staticExcelData.sumCombustionFumesN2,
          "Combustion Fumes SO2(Nm3/kg fuel)": staticExcelData.combustionFumesSO2,
          "Smoke Index Dry(Nm3/kg fuel)": staticExcelData.fuelSmokeIndexDry,
          "Smoke Index Wet(Nm3/kg fuel)": staticExcelData.fuelSmokeIndexWet,
          "Combustion Heat HHV(kwh/kg)": staticExcelData.sumCombustionHeatHHV,
          "Combustion Heat LHV(kwh/kg)": staticExcelData.sumCombustionHeatLHV,
        };
        result.Heavy_Oil_Properties["Calculated Datas"] = {
          "Gas Smoke Index(Nm3/Nm3 or kg)": staticExcelData.sumGazSmokeIndexWet,
          "Fuel Smoke Index(Nm3/Nm3 or kg)": staticExcelData.fuelSmokeIndexWet,
          ...(customerInfo.furnaceType === "Cross Fired"
            ? {
              "Gas Air/combustible ratio(Nm3 air/u comb)": staticExcelData.totalAirCombustible,
            }
            : {
              "Gas Air/combustible ratio(Nm3 air/u comb)": staticExcelData.totalAirCombustibleEFF,
            }),
          "Fuel Air/combustible ratio(Nm3 air/u comb)": staticExcelData.airPouvoir,
        };
      }
      return JSON.stringify(result);
    } else if (val === "batchinfo") {
      let result = {
        BatchData_Inputs: {
          "Pull rate (t/d)": furnaceDet.batchData.pullRate,
          "Cullet (%)": furnaceDet.batchData.cullet,
          "H2O in batch (%)": furnaceDet.batchData.h2oPercBatch,
          "Loss on ignition (%)": furnaceDet.batchData.ignitionLoss,
          "DGC Distribution(%)": getData(
            "batch",
            furnaceDet.batchData.distributionCFF,
            furnaceDet.batchData.distributionEFF
          ),
        },
        BatchData_Outputs: {
          ...(customerInfo.furnaceType === "Cross Fired"
            ? {
              ...(customerInfo.chamberType === "One Side"
                ? {
                  "Total DGC Distribution(%)": {
                    symmetricData: tempBatchLeft.sumBatchLeftPercentage,
                  },
                }
                : {
                  "Total DGC Distribution(%)": {
                    left: tempBatchLeft.sumBatchLeftPercentage,
                    right: tempBatchRight.sumBatchRightPercentage,
                  },
                }),
            }
            : {}),
        },
      };
      return JSON.stringify(result);
    } else if (val === "regenerator") {
      let result = {
        Chamber_Dimensions: {
          "depth(mm)": getData(
            "singleValue",
            furnaceDet.regeneratorDesign.chamberDimensionsCFF.depth,
            furnaceDet.regeneratorDesign.chamberDimensionsEFF.depth
          ),
          "width(mm)": getData(
            "singleValue",
            furnaceDet.regeneratorDesign.chamberDimensionsCFF.width,
            furnaceDet.regeneratorDesign.chamberDimensionsEFF.width
          ),
        },
        Packing_Properties_Inputs: {
          "Checkers thickness(mm)": getData(
            "singleValue",
            furnaceDet.regeneratorDesign.packingPropertiesCFF.thickness,
            furnaceDet.regeneratorDesign.packingPropertiesEFF.thickness
          ),
          "Channel size(mm)": getData(
            "singleValue",
            furnaceDet.regeneratorDesign.packingPropertiesCFF.size,
            furnaceDet.regeneratorDesign.packingPropertiesEFF.size
          ),
        },
        NoOfChannels_Inputs: {
          "depthChannels(mm)": getData(
            "singleValue",
            furnaceDet.regeneratorDesign.noOfChannelsCFF.depthChannels,
            furnaceDet.regeneratorDesign.noOfChannelsEFF.depthChannels
          ),
          "widthChannels(mm)": getData(
            "singleValue",
            furnaceDet.regeneratorDesign.noOfChannelsCFF.widthChannels,
            furnaceDet.regeneratorDesign.noOfChannelsEFF.widthChannels
          ),
        },
        Packing_Properties_Outputs: {
          // "Number of channels": {
          //   "Width (mm)": tempRegenerDesign.numberOfFluesA ? tempRegenerDesign.numberOfFluesA.join(", ") : "",
          //   "Depth (mm)": tempRegenerDesign.numberOfFluesB ? tempRegenerDesign.numberOfFluesB.join(", ") : "",
          // },
          "Free flow area (m2)": {
            "Width (mm)": tempRegenerDesign.availableAreaA ? tempRegenerDesign.availableAreaA.join(", ") : "",
            "Depth (mm)": tempRegenerDesign.availableAreaB ? tempRegenerDesign.availableAreaB.join(", ") : "",
          },
        },
      };
      return JSON.stringify(result);
    } else if (val === "temperatures") {
      let result = {
        Air_Temperatures: {
          "Bottom(cold air)[°C]": getData(
            "temperatures",
            airFumesCompo.airTemperatureCFF.bottom,
            airFumesCompo.airTemperatureEFF.bottom
          ),
          "Top(preheated air)[°C]": getData(
            "temperatures",
            airFumesCompo.airTemperatureCFF.top,
            airFumesCompo.airTemperatureEFF.top
          ),
          ...(customerInfo.chamberType === "One Side"
            ? {
              "Total Bottom(cold air)[°C]": {
                symmetricData: tempAirFumes.bottomColdAirLeftAverage,
              },
            }
            : {
              "Total Bottom(cold air)[°C]": {
                left: tempAirFumes.bottomColdAirLeftAverage,
                right: tempAirFumes.bottomColdAirRightAverage,
              },
            }),

          ...(customerInfo.chamberType === "One Side"
            ? {
              "Total Top(preheated air)[°C]": {
                symmetricData: tempAirFumes.topPreheatedAirLeftAverage,
              },
            }
            : {
              "Total Top(preheated air)[°C]": {
                left: tempAirFumes.topPreheatedAirLeftAverage,
                right: tempAirFumes.topPreheatedAirRightAverage,
              },
            }),
        },
        ...(customerInfo.chamberType === "One Side"
          ? {
            "Thermal Efficiency(%)": {
              symmetricData:
                tempAirFumes.themalEfficiencyLeft ? tempAirFumes.themalEfficiencyLeft.join(", ") : "",
            },
          }
          : {
            "Thermal Efficiency(%)": {
              left:
                tempAirFumes.themalEfficiencyLeft ? tempAirFumes.themalEfficiencyLeft.join(", ") : "",
              right:
                tempAirFumes.themalEfficiencyRight ? tempAirFumes.themalEfficiencyRight.join(", ") : "",
            },
          }),

        ...(customerInfo.furnaceType === "Cross Fired" ||
          (customerInfo.furnaceType === "End Fired" &&
            customerInfo.chamberType === "Both Side")
          ? {
            ...(customerInfo.chamberType === "One Side"
              ? {
                "Total Thermal Efficiency(%)": {
                  symmetricData:
                    tempAirFumes.themalEffiTotalAvgLeft,
                },
              }
              : {
                "Total Thermal Efficiency(%)": {
                  left: tempAirFumes.themalEffiTotalAvgLeft,
                  right: tempAirFumes.themalEffiTotalAvgRight,
                },
              }),
          }
          : {}),
        Fumes_Temperature: {
          "Bottom(cold fumes)[°C]": getData(
            "temperatures",
            airFumesCompo.fumesTemperatureCFF.bottom,
            airFumesCompo.fumesTemperatureEFF.bottom
          ),
          "Top(hot fumes)[°C]": getData(
            "temperatures",
            airFumesCompo.fumesTemperatureCFF.top,
            airFumesCompo.fumesTemperatureEFF.top
          ),
          ...(customerInfo.chamberType === "One Side"
            ? {
              "Total Bottom(cold fumes)[°C]": {
                symmetricData: tempAirFumes.bottomColdFumesLeftAverage,
              },
            }
            : {
              "Total Bottom(cold fumes)[°C]": {
                left: tempAirFumes.bottomColdFumesLeftAverage,
                right: tempAirFumes.bottomColdFumesRightAverage,
              },
            }),

          ...(customerInfo.chamberType === "One Side"
            ? {
              "Total Top(hot fumes)[°C]": {
                symmetricData: tempAirFumes.topHotFumesLeftAverage,
              },
            }
            : {
              "Total Top(hot fumes)[°C]": {
                left: tempAirFumes.topHotFumesLeftAverage,
                right: tempAirFumes.topHotFumesRightAverage,
              },
            }),
        },
        Air_Fumes_Temperature_Outputs: {
          //DAT(Nm3/h)
          ...(customerInfo.chamberType === "One Side"
            ? {
              "DAT(Nm3/h)": {
                symmetricData: staticExcelData.flowsDATDanDexaLeft ? staticExcelData.flowsDATDanDexaLeft.join(", ") : "",
              },
            }
            : {
              "DAT(Nm3/h)": {
                left: staticExcelData.flowsDATDanDexaLeft ? staticExcelData.flowsDATDanDexaLeft.join(", ") : "",
                right: staticExcelData.flowsDATDanDexaRight ? staticExcelData.flowsDATDanDexaRight.join(", ") : "",
              },
            }),

          ...(customerInfo.chamberType === "One Side"
            ? {
              "Total DAT(Nm3/h)": {
                symmetricData: staticExcelData.flowsTotalDATDanDexaLeft,
              },
            }
            : {
              "Total DAT(Nm3/h)": {
                left: staticExcelData.flowsTotalDATDanDexaLeft,
                right: staticExcelData.flowsTotalDATDanDexaRight,
              },
            }),

          //DFT(Nm3/h)
          ...(customerInfo.chamberType === "One Side"
            ? {
              "DFT(Nm3/h)": {
                symmetricData: staticExcelData.effDFTNm3Left ? staticExcelData.effDFTNm3Left.join(", ") : "",
              },
            }
            : {
              "DFT(Nm3/h)": {
                left: staticExcelData.effDFTNm3Left ? staticExcelData.effDFTNm3Left.join(", ") : "",
                right: staticExcelData.effDFTNm3Right ? staticExcelData.effDFTNm3Right.join(", ") : "",
              },
            }),

          ...(customerInfo.chamberType === "One Side"
            ? {
              "Total DFT(Nm3/h)": {
                symmetricData: staticExcelData.effTotalDFTNm3Left,
              },
            }
            : {
              "Total DFT(Nm3/h)": {
                left: staticExcelData.effTotalDFTNm3Left,
                right: staticExcelData.effTotalDFTNm3Right,
              },
            }),

          //Fumes/Air Ratio
          ...(customerInfo.chamberType === "One Side"
            ? {
              "Fumes/Air Ratio": {
                symmetricData:
                  staticExcelData.effFumesAirRatioLeft ? staticExcelData.effFumesAirRatioLeft.join(", ") : "",
              },
            }
            : {
              "Fumes/Air Ratio": {
                left: staticExcelData.effFumesAirRatioLeft ? staticExcelData.effFumesAirRatioLeft.join(", ") : "",
                right: staticExcelData.effFumesAirRatioRight ? staticExcelData.effFumesAirRatioRight.join(", ") : "",
              },
            }),

          ...(customerInfo.chamberType === "One Side"
            ? {
              "Total Fumes/Air Ratio": {
                symmetricData: staticExcelData.effTotalFumesAirRatioLeft,
              },
            }
            : {
              "Total Fumes/Air Ratio": {
                left: staticExcelData.effTotalFumesAirRatioLeft,
                right: staticExcelData.effTotalFumesAirRatioRight,
              },
            }),

          //Air Speed(m/s)
          ...(customerInfo.chamberType === "One Side"
            ? {
              "Air Speed(m/s)": {
                symmetricData: staticExcelData.effAirSpeedLeft ? staticExcelData.effAirSpeedLeft.join(", ") : "",
              },
            }
            : {
              "Air Speed(m/s)": {
                left: staticExcelData.effAirSpeedLeft ? staticExcelData.effAirSpeedLeft.join(", ") : "",
                right: staticExcelData.effAirSpeedRight ? staticExcelData.effAirSpeedRight.join(", ") : "",
              },
            }),

          ...(customerInfo.chamberType === "One Side"
            ? {
              "Total Air Speed(m/s)": {
                symmetricData: staticExcelData.effTotalAirSpeedLeft,
              },
            }
            : {
              "Total Air Speed(m/s)": {
                left: staticExcelData.effTotalAirSpeedLeft,
                right: staticExcelData.effTotalAirSpeedRight,
              },
            }),

          //Fumes Speed(m/s)
          ...(customerInfo.chamberType === "One Side"
            ? {
              "Fumes Speed(m/s)": {
                symmetricData: staticExcelData.effFumesSpeedLeft ? staticExcelData.effFumesSpeedLeft.join(", ") : "",
              },
            }
            : {
              "Fumes Speed(m/s)": {
                left: staticExcelData.effFumesSpeedLeft ? staticExcelData.effFumesSpeedLeft.join(", ") : "",
                right: staticExcelData.effFumesSpeedRight ? staticExcelData.effFumesSpeedRight.join(", ") : "",
              },
            }),

          ...(customerInfo.chamberType === "One Side"
            ? {
              "Total Fumes Speed(m/s)": {
                symmetricData: staticExcelData.effTotalFumesSpeedLeft,
              },
            }
            : {
              "Total Fumes Speed(m/s)": {
                left: staticExcelData.effTotalFumesSpeedLeft,
                right: staticExcelData.effTotalFumesSpeedRight,
              },
            }),
          //Efficiencies Measured Fumes Correlation (Left side of Excel sheet)
          //Cp Air (J/kg.°K) at TSA
          ...(customerInfo.chamberType === "One Side"
            ? {
              "Cp Air (J/kg.°K) at TSA": {
                symmetricData: staticExcelData.cpAirTSALeft ? staticExcelData.cpAirTSALeft.join(", ") : "",
              },
            }
            : {
              "Cp Air (J/kg.°K) at TSA": {
                left: staticExcelData.cpAirTSALeft ? staticExcelData.cpAirTSALeft.join(", ") : "",
                right: staticExcelData.cpAirTSARight ? staticExcelData.cpAirTSARight.join(", ") : "",
              },
            }),

          ...(customerInfo.chamberType === "One Side"
            ? {
              "Total Cp Air (J/kg.°K) at TSA": {
                symmetricData: staticExcelData.totalCPAirTSALeft,
              },
            }
            : {
              "Total Cp Air (J/kg.°K) at TSA": {
                left: staticExcelData.totalCPAirTSALeft,
                right: staticExcelData.totalCPAirTSARight,
              },
            }),

          //Cp Air (KJ/kg.°K) at TEA
          ...(customerInfo.chamberType === "One Side"
            ? {
              "Cp Air (KJ/kg.°K) at TEA": {
                symmetricData: staticExcelData.cpAirTEALeft ? staticExcelData.cpAirTEALeft.join(", ") : "",
              },
            }
            : {
              "Cp Air (KJ/kg.°K) at TEA": {
                left: staticExcelData.cpAirTEALeft ? staticExcelData.cpAirTEALeft.join(", ") : "",
                right: staticExcelData.cpAirTEARight ? staticExcelData.cpAirTEARight.join(", ") : "",
              },
            }),

          ...(customerInfo.chamberType === "One Side"
            ? {
              "Total Cp Air (KJ/kg.°K) at TEA": {
                symmetricData: staticExcelData.totalCPAirTEALeft,
              },
            }
            : {
              "Total Cp Air (KJ/kg.°K) at TEA": {
                left: staticExcelData.totalCPAirTEALeft,
                right: staticExcelData.totalCPAirTEARight,
              },
            }),

          //Cp Fumes(kJ/ kg.°K) at TEF(Measured Fumes Correlation)
          ...(customerInfo.chamberType === "One Side"
            ? {
              "Cp Fumes (kJ/kg.°K) at TEF": {
                symmetricData: staticExcelData.cpFumesTEFLeft ? staticExcelData.cpFumesTEFLeft.join(", ") : "",
              },
            }
            : {
              "Cp Fumes (kJ/kg.°K) at TEF": {
                left: staticExcelData.cpFumesTEFLeft ? staticExcelData.cpFumesTEFLeft.join(", ") : "",
                right: staticExcelData.cpFumesTEFRight ? staticExcelData.cpFumesTEFRight.join(", ") : "",
              },
            }),

          ...(customerInfo.chamberType === "One Side"
            ? {
              "Total Cp Fumes (kJ/kg.°K) at TEF": {
                symmetricData: staticExcelData.totalCPFumesTEFLeft,
              },
            }
            : {
              "Total Cp Fumes (kJ/kg.°K) at TEF": {
                left: staticExcelData.totalCPFumesTEFLeft,
                right: staticExcelData.totalCPFumesTEFRight,
              },
            }),

          //Cp Fumes (kJ/kg.°K) at TEA(Measured Fumes Correlation)
          ...(customerInfo.chamberType === "One Side"
            ? {
              "Cp Fumes (kJ/kg.°K) at TEA": {
                symmetricData: staticExcelData.cpFumesTEALeft ? staticExcelData.cpFumesTEALeft.join(", ") : "",
              },
            }
            : {
              "Cp Fumes (kJ/kg.°K) at TEA": {
                left: staticExcelData.cpFumesTEALeft ? staticExcelData.cpFumesTEALeft.join(", ") : "",
                right: staticExcelData.cpFumesTEARight ? staticExcelData.cpFumesTEARight.join(", ") : "",
              },
            }),

          ...(customerInfo.chamberType === "One Side"
            ? {
              "Total Cp Fumes (kJ/kg.°K) at TEA": {
                symmetricData: staticExcelData.totalCPFumesTEALeft,
              },
            }
            : {
              "Total Cp Fumes (kJ/kg.°K) at TEA": {
                left: staticExcelData.totalCPFumesTEALeft,
                right: staticExcelData.totalCPFumesTEARight,
              },
            }),

          //Gained Air Energy (Wh)
          ...(customerInfo.chamberType === "One Side"
            ? {
              "Gained Air Energy (Wh)": {
                symmetricData: staticExcelData.gainedAirEnergyLeft ? staticExcelData.gainedAirEnergyLeft.join(", ") : "",
              },
            }
            : {
              "Gained Air Energy (Wh)": {
                left: staticExcelData.gainedAirEnergyLeft ? staticExcelData.gainedAirEnergyLeft.join(", ") : "",
                right: staticExcelData.gainedAirEnergyRight ? staticExcelData.gainedAirEnergyRight.join(", ") : "",
              },
            }),

          ...(customerInfo.chamberType === "One Side"
            ? {
              "Total Gained Air Energy (Wh)": {
                symmetricData: staticExcelData.totalGainedAirEnergyLeft,
              },
            }
            : {
              "Total Gained Air Energy (Wh)": {
                left: staticExcelData.totalGainedAirEnergyLeft,
                right: staticExcelData.totalGainedAirEnergyRight,
              },
            }),

          //Available Air Energy (Wh)
          ...(customerInfo.chamberType === "One Side"
            ? {
              "Available Air Energy (Wh)": {
                symmetricData: staticExcelData.availableAirEnLeft ? staticExcelData.availableAirEnLeft.join(", ") : "",
              },
            }
            : {
              "Available Air Energy (Wh)": {
                left: staticExcelData.availableAirEnLeft ? staticExcelData.availableAirEnLeft.join(", ") : "",
                right: staticExcelData.availableAirEnRight ? staticExcelData.availableAirEnRight.join(", ") : "",
              },
            }),

          ...(customerInfo.chamberType === "One Side"
            ? {
              "Total Available Air Energy (Wh)": {
                symmetricData: staticExcelData.totalAvailableAirEnergyLeft,
              },
            }
            : {
              "Total Available Air Energy (Wh)": {
                left: staticExcelData.totalAvailableAirEnergyLeft,
                right: staticExcelData.totalAvailableAirEnergyRight,
              },
            }),

          //Furnace Air Energy (Wh)
          ...(customerInfo.chamberType === "One Side"
            ? {
              "Furnace Air Energy (Wh)": {
                symmetricData: staticExcelData.furnaceAirEnLeft ? staticExcelData.furnaceAirEnLeft.join(", ") : "",
              },
            }
            : {
              "Furnace Air Energy (Wh)": {
                left: staticExcelData.furnaceAirEnLeft ? staticExcelData.furnaceAirEnLeft.join(", ") : "",
                right: staticExcelData.furnaceAirEnRight ? staticExcelData.furnaceAirEnRight.join(", ") : "",
              },
            }),

          ...(customerInfo.chamberType === "One Side"
            ? {
              "Total Furnace Air Energy (Wh)": {
                symmetricData: staticExcelData.totalFurnaceAirEnergyLeft,
              },
            }
            : {
              "Total Furnace Air Energy (Wh)": {
                left: staticExcelData.totalFurnaceAirEnergyLeft,
                right: staticExcelData.totalFurnaceAirEnergyRight,
              },
            }),

          //Max Furnace Air Energy (Wh)
          ...(customerInfo.chamberType === "One Side"
            ? {
              "Max Furnace Air Energy (Wh)": {
                symmetricData: staticExcelData.maxFurnaceAirEnLeft ? staticExcelData.maxFurnaceAirEnLeft.join(", ") : "",
              },
            }
            : {
              "Max Furnace Air Energy (Wh)": {
                left: staticExcelData.maxFurnaceAirEnLeft ? staticExcelData.maxFurnaceAirEnLeft.join(", ") : "",
                right: staticExcelData.maxFurnaceAirEnRight ? staticExcelData.maxFurnaceAirEnRight.join(", ") : "",
              },
            }),

          ...(customerInfo.chamberType === "One Side"
            ? {
              "Total Max Furnace Air Energy (Wh)": {
                symmetricData: staticExcelData.totalMaxFurnaceAirEnergyLeft,
              },
            }
            : {
              "Total Max Furnace Air Energy (Wh)": {
                left: staticExcelData.totalMaxFurnaceAirEnergyLeft,
                right: staticExcelData.totalMaxFurnaceAirEnergyRight,
              },
            }),

          //Gaz & Fuel Energy (Wh)
          ...(customerInfo.chamberType === "One Side"
            ? {
              "Gaz & Fuel Energy (Wh)": {
                symmetricData: staticExcelData.gazFuelEnergyLeft ? staticExcelData.gazFuelEnergyLeft.join(", ") : "",
              },
            }
            : {
              "Gaz & Fuel Energy (Wh)": {
                left: staticExcelData.gazFuelEnergyLeft ? staticExcelData.gazFuelEnergyLeft.join(", ") : "",
                right: staticExcelData.gazFuelEnergyRight ? staticExcelData.gazFuelEnergyRight.join(", ") : "",
              },
            }),

          ...(customerInfo.chamberType === "One Side"
            ? {
              "Total Gaz & Fuel Energy (Wh)": {
                symmetricData: staticExcelData.totalGazFuelEnergyLeft,
              },
            }
            : {
              "Total Gaz & Fuel Energy (Wh)": {
                left: staticExcelData.totalGazFuelEnergyLeft,
                right: staticExcelData.totalGazFuelEnergyRight,
              },
            }),

          //Fumes Energy (Wh)(Measured Fumes Correlation)
          ...(customerInfo.chamberType === "One Side"
            ? {
              "Fumes Energy (Wh)": {
                symmetricData: staticExcelData.fumesEnergyLeft ? staticExcelData.fumesEnergyLeft.join(", ") : "",
              },
            }
            : {
              "Fumes Energy (Wh)": {
                left: staticExcelData.fumesEnergyLeft ? staticExcelData.fumesEnergyLeft.join(", ") : "",
                right: staticExcelData.fumesEnergyRight ? staticExcelData.fumesEnergyRight.join(", ") : "",
              },
            }),

          ...(customerInfo.chamberType === "One Side"
            ? {
              "Total Fumes Energy (Wh)": {
                symmetricData: staticExcelData.totalFumesEnergyLeft,
              },
            }
            : {
              "Total Fumes Energy (Wh)": {
                left: staticExcelData.totalFumesEnergyLeft,
                right: staticExcelData.totalFumesEnergyRight,
              },
            }),

          //Furnace Fumes Energy (Wh)(Measured Fumes Correlation)
          ...(customerInfo.chamberType === "One Side"
            ? {
              "Furnace Fumes Energy (Wh)": {
                symmetricData: staticExcelData.furnaceFumesEnLeft ? staticExcelData.furnaceFumesEnLeft.join(", ") : "",
              },
            }
            : {
              "Furnace Fumes Energy (Wh)": {
                left: staticExcelData.furnaceFumesEnLeft ? staticExcelData.furnaceFumesEnLeft.join(", ") : "",
                right: staticExcelData.furnaceFumesEnRight ? staticExcelData.furnaceFumesEnRight.join(", ") : "",
              },
            }),

          ...(customerInfo.chamberType === "One Side"
            ? {
              "Total Furnace Fumes Energy (Wh)": {
                symmetricData: staticExcelData.totalFurnaceFumesEnLeft,
              },
            }
            : {
              "Total Furnace Fumes Energy (Wh)": {
                left: staticExcelData.totalFurnaceFumesEnLeft,
                right: staticExcelData.totalFurnaceFumesEnRight,
              },
            }),

          //Energy Loss (Wh)(Measured Fumes Correlation)
          ...(customerInfo.chamberType === "One Side"
            ? {
              "Energy Loss (Wh)": {
                symmetricData: staticExcelData.energyLossLeft ? staticExcelData.energyLossLeft.join(", ") : "",
              },
            }
            : {
              "Energy Loss (Wh)": {
                left: staticExcelData.energyLossLeft ? staticExcelData.energyLossLeft.join(", ") : "",
                right: staticExcelData.energyLossRight ? staticExcelData.energyLossRight.join(", ") : "",
              },
            }),

          ...(customerInfo.chamberType === "One Side"
            ? {
              "Total Energy Loss (Wh)": {
                symmetricData: staticExcelData.totalEnergyLossLeft,
              },
            }
            : {
              "Total Energy Loss (Wh)": {
                left: staticExcelData.totalEnergyLossLeft,
                right: staticExcelData.totalEnergyLossRight,
              },
            }),

          //Energy Loss%(Measured Fumes Correlation)
          ...(customerInfo.chamberType === "One Side"
            ? {
              "Energy Loss%": {
                symmetricData:
                  staticExcelData.energyLossPercentLeft ? staticExcelData.energyLossPercentLeft.join(", ") : "",
              },
            }
            : {
              "Energy Loss%": {
                left: staticExcelData.energyLossPercentLeft ? staticExcelData.energyLossPercentLeft.join(", ") : "",
                right: staticExcelData.energyLossPercentRight ? staticExcelData.energyLossPercentRight.join(", ") : "",
              },
            }),

          ...(customerInfo.chamberType === "One Side"
            ? {
              "Total Energy Loss%": {
                symmetricData: staticExcelData.totalEnergyLossPercentLeft,
              },
            }
            : {
              "Total Energy Loss%": {
                left: staticExcelData.totalEnergyLossPercentLeft,
                right: staticExcelData.totalEnergyLossPercentRight,
              },
            }),

          //Chamber Efficiency%(Measured Fumes Correlation)
          ...(customerInfo.chamberType === "One Side"
            ? {
              "Chamber Efficiency%": {
                symmetricData: staticExcelData.chamberEfficiLeft ? staticExcelData.chamberEfficiLeft.join(", ") : "",
              },
            }
            : {
              "Chamber Efficiency%": {
                left: staticExcelData.chamberEfficiLeft ? staticExcelData.chamberEfficiLeft.join(", ") : "",
                right: staticExcelData.chamberEfficiRight ? staticExcelData.chamberEfficiRight.join(", ") : "",
              },
            }),

          ...(customerInfo.chamberType === "One Side"
            ? {
              "Total Chamber Efficiency%": {
                symmetricData: staticExcelData.totalChamberEfficiLeft,
              },
            }
            : {
              "Total Chamber Efficiency%": {
                left: staticExcelData.totalChamberEfficiLeft,
                right: staticExcelData.totalChamberEfficiRight,
              },
            }),

          //Max Chamber Efficiency%(Measured Fumes Correlation)
          ...(customerInfo.chamberType === "One Side"
            ? {
              "Max Chamber Efficiency%": {
                symmetricData: staticExcelData.maxChamberEffLeft ? staticExcelData.maxChamberEffLeft.join(", ") : "",
              },
            }
            : {
              "Max Chamber Efficiency%": {
                left: staticExcelData.maxChamberEffLeft ? staticExcelData.maxChamberEffLeft.join(", ") : "",
                right: staticExcelData.maxChamberEffRight ? staticExcelData.maxChamberEffRight.join(", ") : "",
              },
            }),

          ...(customerInfo.chamberType === "One Side"
            ? {
              "Total Max Chamber Efficiency%": {
                symmetricData: staticExcelData.totalMaxChamberEfficiLeft,
              },
            }
            : {
              "Total Max Chamber Efficiency%": {
                left: staticExcelData.totalMaxChamberEfficiLeft,
                right: staticExcelData.totalMaxChamberEfficiRight,
              },
            }),

          //Bay Efficiency%(Measured Fumes Correlation)
          ...(customerInfo.chamberType === "One Side"
            ? {
              "Bay Efficiency%": {
                symmetricData: staticExcelData.bayEfficiLeft ? staticExcelData.bayEfficiLeft.join(", ") : "",
              },
            }
            : {
              "Bay Efficiency%": {
                left: staticExcelData.bayEfficiLeft ? staticExcelData.bayEfficiLeft.join(", ") : "",
                right: staticExcelData.bayEfficiRight ? staticExcelData.bayEfficiRight.join(", ") : "",
              },
            }),

          ...(customerInfo.chamberType === "One Side"
            ? {
              "Total Bay Efficiency%": {
                symmetricData: staticExcelData.totalBayEfficiLeft,
              },
            }
            : {
              "Total Bay Efficiency%": {
                left: staticExcelData.totalBayEfficiLeft,
                right: staticExcelData.totalBayEfficiRight,
              },
            }),

          //Max Furnace Efficiency%(Measured Fumes Correlation)
          ...(customerInfo.chamberType === "One Side"
            ? {
              "Max Furnace Efficiency%": {
                symmetricData:
                  staticExcelData.maxFurnaceEfficiLeft ? staticExcelData.maxFurnaceEfficiLeft.join(", ") : "",
              },
            }
            : {
              "Max Furnace Efficiency%": {
                left: staticExcelData.maxFurnaceEfficiLeft ? staticExcelData.maxFurnaceEfficiLeft.join(", ") : "",
                right: staticExcelData.maxFurnaceEfficiRight ? staticExcelData.maxFurnaceEfficiRight.join(", ") : "",
              },
            }),

          ...(customerInfo.chamberType === "One Side"
            ? {
              "Total Max Furnace Efficiency%": {
                symmetricData: staticExcelData.totalMaxFurnaceEfficiLeft,
              },
            }
            : {
              "Total Max Furnace Efficiency%": {
                left: staticExcelData.totalMaxFurnaceEfficiLeft,
                right: staticExcelData.totalMaxFurnaceEfficiRight,
              },
            }),

          //EBM Energy Efficiency Definition(%)(Measured Fumes Correlation)
          ...(customerInfo.chamberType === "One Side"
            ? {
              "EBM Energy Efficiency Definition(%)": {
                symmetricData: staticExcelData.ebmEnergyEffDefLeft ? staticExcelData.ebmEnergyEffDefLeft.join(", ") : "",
              },
            }
            : {
              "EBM Energy Efficiency Definition(%)": {
                left: staticExcelData.ebmEnergyEffDefLeft ? staticExcelData.ebmEnergyEffDefLeft.join(", ") : "",
                right: staticExcelData.ebmEnergyEffDefRight ? staticExcelData.ebmEnergyEffDefRight.join(", ") : "",
              },
            }),

          ...(customerInfo.chamberType === "One Side"
            ? {
              "Total EBM Energy Efficiency Definition(%)": {
                symmetricData: staticExcelData.totalEBMEnergyEffDefLeft,
              },
            }
            : {
              "Total EBM Energy Efficiency Definition(%)": {
                left: staticExcelData.totalEBMEnergyEffDefLeft,
                right: staticExcelData.totalEBMEnergyEffDefRight,
              },
            }),
        },
      };
      return JSON.stringify(result);
    } else if (val === "combustion") {
      let result = {
        "DFN Gas": {
          ...(customerInfo.chamberType === "One Side"
            ? {
              "DFN Gas(Nm3/h)": {
                symmetricData: staticExcelData.flowsDFNGazLeft ? staticExcelData.flowsDFNGazLeft.join(", ") : "",
              },
            }
            : {
              "DFN Gas(Nm3/h)": {
                left: staticExcelData.flowsDFNGazLeft ? staticExcelData.flowsDFNGazLeft.join(", ") : "",
                right: staticExcelData.flowsDFNGazRight ? staticExcelData.flowsDFNGazRight.join(", ") : "",
              },
            }),
        },
        "Total DFN Gas": {
          ...(customerInfo.furnaceType === "Cross Fired"
            ? {
              ...(customerInfo.chamberType === "One Side"
                ? {
                  "Total DFN Gas(Nm3/h)": {
                    symmetricData: staticExcelData.totalFlowsDFNGazLeft,
                  },
                }
                : {
                  "Total DFN Gas(Nm3/h)": {
                    left: staticExcelData.totalFlowsDFNGazLeft,
                    right: staticExcelData.totalFlowsDFNGazRight,
                  },
                }),
            }
            : {}),
        },
        "DFN Oil": {
          ...(customerInfo.chamberType === "One Side"
            ? {
              "DFN Oil(Nm3/h)": {
                symmetricData: staticExcelData.flowsDFNFuelLeft ? staticExcelData.flowsDFNFuelLeft.join(", ") : "",
              },
            }
            : {
              "DFN Oil(Nm3/h)": {
                left: staticExcelData.flowsDFNFuelLeft ? staticExcelData.flowsDFNFuelLeft.join(", ") : "",
                right: staticExcelData.flowsDFNFuelRight ? staticExcelData.flowsDFNFuelRight.join(", ") : "",
              },
            }),
        },
        "Total DFN Oil": {
          ...(customerInfo.furnaceType === "Cross Fired"
            ? {
              ...(customerInfo.chamberType === "One Side"
                ? {
                  "Total DFN Oil(Nm3/h)": {
                    symmetricData: staticExcelData.totalFlowsDFNFuelLeft,
                  },
                }
                : {
                  "Total DFN Oil(Nm3/h)": {
                    left: staticExcelData.totalFlowsDFNFuelLeft,
                    right: staticExcelData.totalFlowsDFNFuelRight,
                  },
                }),
            }
            : {}),
        },
        "DFN Total": {
          ...(customerInfo.chamberType === "One Side"
            ? {
              "DFN Total(Nm3/h)": {
                symmetricData: staticExcelData.flowsDFNTotalLeft ? staticExcelData.flowsDFNTotalLeft.join(", ") : "",
              },
            }
            : {
              "DFN Total(Nm3/h)": {
                left: staticExcelData.flowsDFNTotalLeft ? staticExcelData.flowsDFNTotalLeft.join(", ") : "",
                right: staticExcelData.flowsDFNTotalRight ? staticExcelData.flowsDFNTotalRight.join(", ") : "",
              },
            }),
        },
        "Total DFN Total": {
          ...(customerInfo.furnaceType === "Cross Fired"
            ? {
              ...(customerInfo.chamberType === "One Side"
                ? {
                  "Total DFN Total(Nm3/h)": {
                    symmetricData: staticExcelData.flowsTotalDFNTotalLeft,
                  },
                }
                : {
                  "Total DFN Total(Nm3/h)": {
                    left: staticExcelData.flowsTotalDFNTotalLeft,
                    right: staticExcelData.flowsTotalDFNTotalRight,
                  },
                }),
            }
            : {}),
        },
        "DAN Gas": {
          ...(customerInfo.chamberType === "One Side"
            ? {
              "DAN Gas(Nm3/h)": {
                symmetricData: staticExcelData.flowsDANGazLeft ? staticExcelData.flowsDANGazLeft.join(", ") : "",
              },
            }
            : {
              "DAN Gas(Nm3/h)": {
                left: staticExcelData.flowsDANGazLeft ? staticExcelData.flowsDANGazLeft.join(", ") : "",
                right: staticExcelData.flowsDANGazRight ? staticExcelData.flowsDANGazRight.join(", ") : "",
              },
            }),
        },
        "Total DAN Gas": {
          ...(customerInfo.furnaceType === "Cross Fired"
            ? {
              ...(customerInfo.chamberType === "One Side"
                ? {
                  "Total DAN Gas(Nm3/h)": {
                    symmetricData: staticExcelData.flowsDANTotalGazLeft,
                  },
                }
                : {
                  "Total DAN Gas(Nm3/h)": {
                    left: staticExcelData.flowsDANTotalGazLeft,
                    right: staticExcelData.flowsDANTotalGazRight,
                  },
                }),
            }
            : {}),
        },
        "DAN Oil": {
          ...(customerInfo.chamberType === "One Side"
            ? {
              "DAN Oil(Nm3/h)": {
                symmetricData: staticExcelData.flowsDANFuelLeft ? staticExcelData.flowsDANFuelLeft.join(", ") : "",
              },
            }
            : {
              "DAN Oil(Nm3/h)": {
                left: staticExcelData.flowsDANFuelLeft ? staticExcelData.flowsDANFuelLeft.join(", ") : "",
                right: staticExcelData.flowsDANFuelRight ? staticExcelData.flowsDANFuelRight.join(", ") : "",
              },
            }),
        },
        "Total DAN Oil": {
          ...(customerInfo.furnaceType === "Cross Fired"
            ? {
              ...(customerInfo.chamberType === "One Side"
                ? {
                  "Total DAN Oil(Nm3/h)": {
                    symmetricData: staticExcelData.flowsDANTotalFuelLeft,
                  },
                }
                : {
                  "Total DAN Oil(Nm3/h)": {
                    left: staticExcelData.flowsDANTotalFuelLeft,
                    right: staticExcelData.flowsDANTotalFuelLeft,
                  },
                }),
            }
            : {}),
        },
        "DAN Total": {
          ...(customerInfo.chamberType === "One Side"
            ? {
              "DAN Total(Nm3/h)": {
                symmetricData: staticExcelData.flowsDANTotalLeft ? staticExcelData.flowsDANTotalLeft.join(", ") : "",
              },
            }
            : {
              "DAN Total(Nm3/h)": {
                left: staticExcelData.flowsDANTotalLeft ? staticExcelData.flowsDANTotalLeft.join(", ") : "",
                right: staticExcelData.flowsDANTotalRight ? staticExcelData.flowsDANTotalRight.join(", ") : "",
              },
            }),
        },
        "Total DAN Total": {
          ...(customerInfo.furnaceType === "Cross Fired"
            ? {
              ...(customerInfo.chamberType === "One Side"
                ? {
                  "Total DAN Total(Nm3/h)": {
                    symmetricData: staticExcelData.flowsTotalDANTotalLeft,
                  },
                }
                : {
                  "Total DAN Total(Nm3/h)": {
                    left: staticExcelData.flowsTotalDANTotalLeft,
                    right: staticExcelData.flowsTotalDANTotalRight,
                  },
                }),
            }
            : {}),
        },
        //DGC (Batch Gas Flow)
        DGC: {
          ...(customerInfo.chamberType === "One Side"
            ? {
              "DGC Dehydration(kg/batch)": {
                symmetricData:
                  staticExcelData.flowsDGCDehydrationkGLeft ? staticExcelData.flowsDGCDehydrationkGLeft.join(", ") : "",
              },
            }
            : {
              "DGC Dehydration(kg/batch)": {
                left: staticExcelData.flowsDGCDehydrationkGLeft ? staticExcelData.flowsDGCDehydrationkGLeft.join(", ") : "",
                right: staticExcelData.flowsDGCDehydrationkGRight ? staticExcelData.flowsDGCDehydrationkGRight.join(", ") : "",
              },
            }),

          ...(customerInfo.furnaceType === "Cross Fired"
            ? {
              ...(customerInfo.chamberType === "One Side"
                ? {
                  "Total DGC Dehydration(kg/batch)": {
                    symmetricData: staticExcelData.totalFlowsDGCDehydrationkGLeft,
                  },
                }
                : {
                  "Total DGC Dehydration(kg/batch)": {
                    left: staticExcelData.totalFlowsDGCDehydrationkGLeft,
                    right: staticExcelData.totalFlowsDGCDehydrationkGRight,
                  },
                }),
            }
            : {}),

          ...(customerInfo.chamberType === "One Side"
            ? {
              "DGC Dehydration(Nm3/h)": {
                symmetricData:
                  staticExcelData.flowsDGCDehydrationNM3Left ? staticExcelData.flowsDGCDehydrationNM3Left.join(", ") : "",
              },
            }
            : {
              "DGC Dehydration(Nm3/h)": {
                left: staticExcelData.flowsDGCDehydrationNM3Left ? staticExcelData.flowsDGCDehydrationNM3Left.join(", ") : "",
                right: staticExcelData.flowsDGCDehydrationNM3Right ? staticExcelData.flowsDGCDehydrationNM3Right.join(", ") : "",
              },
            }),

          ...(customerInfo.furnaceType === "Cross Fired"
            ? {
              ...(customerInfo.chamberType === "One Side"
                ? {
                  "Total DGC Dehydration(Nm3/h)": {
                    symmetricData:
                      staticExcelData.totalFlowsDGCDehydrationNM3Left,
                  },
                }
                : {
                  "Total DGC Dehydration(Nm3/h)": {
                    left: staticExcelData.totalFlowsDGCDehydrationNM3Left,
                    right: staticExcelData.totalFlowsDGCDehydrationNM3Right,
                  },
                }),
            }
            : {}),

          ...(customerInfo.chamberType === "One Side"
            ? {
              "DGC Decarbonation(kg)": {
                symmetricData:
                  staticExcelData.flowsDGCDecarbonationKGLeft ? staticExcelData.flowsDGCDecarbonationKGLeft.join(", ") : "",
              },
            }
            : {
              "DGC Decarbonation(kg)": {
                left: staticExcelData.flowsDGCDecarbonationKGLeft ? staticExcelData.flowsDGCDecarbonationKGLeft.join(", ") : "",
                right:
                  staticExcelData.flowsDGCDecarbonationKGRight ? staticExcelData.flowsDGCDecarbonationKGRight.join(", ") : "",
              },
            }),

          ...(customerInfo.furnaceType === "Cross Fired"
            ? {
              ...(customerInfo.chamberType === "One Side"
                ? {
                  "Total DGC Decarbonation(kg)": {
                    symmetricData:
                      staticExcelData.totalFlowsDGCDecarbonationKGLeft,
                  },
                }
                : {
                  "Total DGC Decarbonation(kg)": {
                    left: staticExcelData.totalFlowsDGCDecarbonationKGLeft,
                    right: staticExcelData.totalFlowsDGCDecarbonationKGRight,
                  },
                }),
            }
            : {}),

          ...(customerInfo.chamberType === "One Side"
            ? {
              "DGC Decarbonation(Nm3/h)": {
                symmetricData:
                  staticExcelData.flowsDGCDecarbonationNM3Left ? staticExcelData.flowsDGCDecarbonationNM3Left.join(", ") : "",
              },
            }
            : {
              "DGC Decarbonation(Nm3/h)": {
                left: staticExcelData.flowsDGCDecarbonationNM3Left ? staticExcelData.flowsDGCDecarbonationNM3Left.join(", ") : "",
                right:
                  staticExcelData.flowsDGCDecarbonationNM3Right ? staticExcelData.flowsDGCDecarbonationNM3Right.join(", ") : "",
              },
            }),

          ...(customerInfo.furnaceType === "Cross Fired"
            ? {
              ...(customerInfo.chamberType === "One Side"
                ? {
                  "Total DGC Decarbonation(Nm3/h)": {
                    symmetricData:
                      staticExcelData.totalFlowsDGCDecarbonationNM3Left,
                  },
                }
                : {
                  "Total DGC Decarbonation(Nm3/h)": {
                    left: staticExcelData.totalFlowsDGCDecarbonationNM3Left,
                    right: staticExcelData.totalFlowsDGCDecarbonationNM3Right,
                  },
                }),
            }
            : {}),

          ...(customerInfo.chamberType === "One Side"
            ? {
              "DGC Total(Nm3/h)": {
                symmetricData: staticExcelData.flowsDGCTotalLeft ? staticExcelData.flowsDGCTotalLeft.join(", ") : "",
              },
            }
            : {
              "DGC Total(Nm3/h)": {
                left: staticExcelData.flowsDGCTotalLeft ? staticExcelData.flowsDGCTotalLeft.join(", ") : "",
                right: staticExcelData.flowsDGCTotalRight ? staticExcelData.flowsDGCTotalRight.join(", ") : "",
              },
            }),

          ...(customerInfo.furnaceType === "Cross Fired"
            ? {
              ...(customerInfo.chamberType === "One Side"
                ? {
                  "Total DGC Total(Nm3/h)": {
                    symmetricData: staticExcelData.flowsTotalDGCTotalLeft,
                  },
                }
                : {
                  "Total DGC Total(Nm3/h)": {
                    left: staticExcelData.flowsTotalDGCTotalLeft,
                    right: staticExcelData.flowsTotalDGCTotalRight,
                  },
                }),
            }
            : {}),
        },

        //Combustion Correlation
        //Air/Energy ratio
        combustionCorrelation: {
          ...(customerInfo.chamberType === "One Side"
            ? {
              "Air/Energy ratio(Mean for stoechio)": {
                symmetricData:
                  staticExcelData.flowsCombustionCorrAirLeft ? staticExcelData.flowsCombustionCorrAirLeft.join(", ") : "",
              },
            }
            : {
              "Air/Energy ratio(Mean for stoechio)": {
                left: staticExcelData.flowsCombustionCorrAirLeft ? staticExcelData.flowsCombustionCorrAirLeft.join(", ") : "",
                right: staticExcelData.flowsCombustionCorrAirRight ? staticExcelData.flowsCombustionCorrAirRight.join(", ") : "",
              },
            }),

          ...(customerInfo.furnaceType === "Cross Fired"
            ? {
              ...(customerInfo.chamberType === "One Side"
                ? {
                  "Total Air/Energy ratio(Mean for stoechio)": {
                    symmetricData:
                      staticExcelData.flowsTotalCombustionCorrAirLeft,
                  },
                }
                : {
                  "Total Air/Energy ratio(Mean for stoechio)": {
                    left: staticExcelData.flowsTotalCombustionCorrAirLeft,
                    right: staticExcelData.flowsTotalCombustionCorrAirRight,
                  },
                }),
            }
            : {}),
        },

        //Correlation Input
        correlationInput: {
          Correlation: getData(
            "correlation",
            furnaceDet.batchData.correlationCFF,
            furnaceDet.batchData.correlationEFF
          ),
        },

        //Correlation Output
        correlationOutput: {
          ...(customerInfo.furnaceType === "Cross Fired"
            ? {
              ...(customerInfo.chamberType === "One Side"
                ? {
                  "Total Correlation": {
                    symmetricData: staticExcelData.flowsTotalAvgCorrelationLeft,
                  },
                }
                : {
                  "Total Correlation": {
                    left: staticExcelData.flowsTotalAvgCorrelationLeft,
                    right: staticExcelData.flowsTotalAvgCorrelationRight,
                  },
                }),
            }
            : {}),
        },

        //Resulting Air Flows
        //Dexa (Excess Air Flow)(Nm3/h)
        resultingDexa: {
          ...(customerInfo.chamberType === "One Side"
            ? {
              "Dexa (Excess Air Flow)(Nm3/h)": {
                symmetricData: staticExcelData.flowsDexaLeft ? staticExcelData.flowsDexaLeft.join(", ") : "",
              },
            }
            : {
              "Dexa (Excess Air Flow)(Nm3/h)": {
                left: staticExcelData.flowsDexaLeft ? staticExcelData.flowsDexaLeft.join(", ") : "",
                right: staticExcelData.flowsDexaRight ? staticExcelData.flowsDexaRight.join(", ") : "",
              },
            }),

          ...(customerInfo.furnaceType === "Cross Fired"
            ? {
              ...(customerInfo.chamberType === "One Side"
                ? {
                  "Total Dexa (Excess Air Flow)(Nm3/h)": {
                    symmetricData: staticExcelData.flowsCorrelationTotalLeft,
                  },
                }
                : {
                  "Total Dexa (Excess Air Flow)(Nm3/h)": {
                    left: staticExcelData.flowsCorrelationTotalLeft,
                    right: staticExcelData.flowsCorrelationTotalRight,
                  },
                }),
            }
            : {}),

          ...(customerInfo.chamberType === "One Side"
            ? {
              "DAP(Parasitic Air Flow)(Nm3/h)": {
                symmetricData:
                  staticExcelData.flowsDAPParasiticLeft ? staticExcelData.flowsDAPParasiticLeft.join(", ") : "",
              },
            }
            : {
              "DAP(Parasitic Air Flow)(Nm3/h)": {
                left: staticExcelData.flowsDAPParasiticLeft ? staticExcelData.flowsDAPParasiticLeft.join(", ") : "",
                right: staticExcelData.flowsDAPParasiticRight ? staticExcelData.flowsDAPParasiticRight.join(", ") : "",
              },
            }),

          ...(customerInfo.furnaceType === "Cross Fired"
            ? {
              ...(customerInfo.chamberType === "One Side"
                ? {
                  "Total DAP(Parasitic Air Flow)(Nm3/h)": {
                    symmetricData: staticExcelData.flowsDAPParasiticTotalLeft,
                  },
                }
                : {
                  "Total DAP(Parasitic Air Flow)(Nm3/h)": {
                    left: staticExcelData.flowsDAPParasiticTotalLeft,
                    right: staticExcelData.flowsDAPParasiticTotalRight,
                  },
                }),
            }
            : {}),
        },

        //DFT(Total Fumes Flow)
        //DFN+DGC+DEXA (Nm3/h)
        DFT: {
          ...(customerInfo.chamberType === "One Side"
            ? {
              "DFN+DGC+DEXA (Nm3/h)": {
                symmetricData:
                  staticExcelData.flowsDFTDfnDgcDexaLeft ? staticExcelData.flowsDFTDfnDgcDexaLeft.join(", ") : "",
              },
            }
            : {
              "DFN+DGC+DEXA (Nm3/h)": {
                left: staticExcelData.flowsDFTDfnDgcDexaLeft ? staticExcelData.flowsDFTDfnDgcDexaLeft.join(", ") : "",
                right: staticExcelData.flowsDFTDfnDgcDexaRight ? staticExcelData.flowsDFTDfnDgcDexaRight.join(", ") : "",
              },
            }),

          ...(customerInfo.furnaceType === "Cross Fired"
            ? {
              ...(customerInfo.chamberType === "One Side"
                ? {
                  "Total DFN+DGC+DEXA (Nm3/h)": {
                    symmetricData: staticExcelData.flowsTotalDFTDfnDgcDexaLeft,
                  },
                }
                : {
                  "Total DFN+DGC+DEXA (Nm3/h)": {
                    left: staticExcelData.flowsTotalDFTDfnDgcDexaLeft,
                    right: staticExcelData.flowsTotalDFTDfnDgcDexaRight,
                  },
                }),
            }
            : {}),
        },

        //Previous+DAP (Nm3/h)
        previousDAP: {
          ...(customerInfo.chamberType === "One Side"
            ? {
              "DFT Previous+DAP (Nm3/h)": {
                symmetricData:
                  staticExcelData.flowsDFTPreviousDAPLeft ? staticExcelData.flowsDFTPreviousDAPLeft.join(", ") : "",
              },
            }
            : {
              "DFT Previous+DAP (Nm3/h)": {
                left: staticExcelData.flowsDFTPreviousDAPLeft ? staticExcelData.flowsDFTPreviousDAPLeft.join(", ") : "",
                right: staticExcelData.flowsDFTPreviousDAPRight ? staticExcelData.flowsDFTPreviousDAPRight.join(", ") : "",
              },
            }),

          ...(customerInfo.furnaceType === "Cross Fired"
            ? {
              ...(customerInfo.chamberType === "One Side"
                ? {
                  "Total DFT Previous+DAP (Nm3/h)": {
                    symmetricData: staticExcelData.flowsDFTPreviousDAPTotalLeft,
                  },
                }
                : {
                  "Total DFT Previous+DAP (Nm3/h)": {
                    left: staticExcelData.flowsDFTPreviousDAPTotalLeft,
                    right: staticExcelData.flowsDFTPreviousDAPTotalRight,
                  },
                }),
            }
            : {}),
        },

        //DAT(Total Air Flow)
        //DAN + DEXA(Nm3/h)
        DAT: {
          ...(customerInfo.chamberType === "One Side"
            ? {
              "DAT(Total Air Flow)(DAN + DEXA)(Nm3/h)": {
                symmetricData: staticExcelData.flowsDATDanDexaLeft ? staticExcelData.flowsDATDanDexaLeft.join(", ") : "",
              },
            }
            : {
              "DAT(Total Air Flow)(DAN + DEXA)(Nm3/h)": {
                left: staticExcelData.flowsDATDanDexaLeft ? staticExcelData.flowsDATDanDexaLeft.join(", ") : "",
                right: staticExcelData.flowsDATDanDexaRight ? staticExcelData.flowsDATDanDexaRight.join(", ") : "",
              },
            }),

          ...(customerInfo.furnaceType === "Cross Fired"
            ? {
              ...(customerInfo.chamberType === "One Side"
                ? {
                  "Total DAT(Total Air Flow)(DAN + DEXA)(Nm3/h)": {
                    symmetricData: staticExcelData.flowsTotalDATDanDexaLeft,
                  },
                }
                : {
                  "Total DAT(Total Air Flow)(DAN + DEXA)(Nm3/h)": {
                    left: staticExcelData.flowsTotalDATDanDexaLeft,
                    right: staticExcelData.flowsTotalDATDanDexaRight,
                  },
                }),
            }
            : {}),
        },

        //Measured at top CO2
        measuredCO2: {
          ...(customerInfo.chamberType === "One Side"
            ? {
              "Measured at top CO2(Dry air%)": {
                symmetricData:
                  staticExcelData.flowsMeasuredTopCO2Left ? staticExcelData.flowsMeasuredTopCO2Left.join(", ") : "",
              },
            }
            : {
              "Measured at top CO2(Dry air%)": {
                left: staticExcelData.flowsMeasuredTopCO2Left ? staticExcelData.flowsMeasuredTopCO2Left.join(", ") : "",
                right: staticExcelData.flowsMeasuredTopCO2Right ? staticExcelData.flowsMeasuredTopCO2Right.join(", ") : "",
              },
            }),

          ...(customerInfo.furnaceType === "Cross Fired"
            ? {
              ...(customerInfo.chamberType === "One Side"
                ? {
                  "Total Measured at top CO2(Dry air%)": {
                    symmetricData: staticExcelData.flowsAvgMeasuredTopCO2Left,
                  },
                }
                : {
                  "Total Measured at top CO2(Dry air%)": {
                    left: staticExcelData.flowsAvgMeasuredTopCO2Left,
                    right: staticExcelData.flowsAvgMeasuredTopCO2Right,
                  },
                }),
            }
            : {}),
        },

        //Calculated at top
        //O2
        calculatedTopO2: {
          ...(customerInfo.chamberType === "One Side"
            ? {
              "Calculated at top O2(Dry air)": {
                symmetricData: staticExcelData.flowsCalTopO2Left ? staticExcelData.flowsCalTopO2Left.join(", ") : "",
              },
            }
            : {
              "Calculated at top O2(Dry air)": {
                left: staticExcelData.flowsCalTopO2Left ? staticExcelData.flowsCalTopO2Left.join(", ") : "",
                right: staticExcelData.flowsCalTopO2Right ? staticExcelData.flowsCalTopO2Right.join(", ") : "",
              },
            }),

          ...(customerInfo.furnaceType === "Cross Fired"
            ? {
              ...(customerInfo.chamberType === "One Side"
                ? {
                  "Total Calculated at top O2(Dry air)": {
                    symmetricData: staticExcelData.flowsAvgCalTopO2Left,
                  },
                }
                : {
                  "Total Calculated at top O2(Dry air)": {
                    left: staticExcelData.flowsAvgCalTopO2Left,
                    right: staticExcelData.flowsAvgCalTopO2Right,
                  },
                }),
            }
            : {}),
        },

        //CO2
        calculatedTopCO2: {
          ...(customerInfo.chamberType === "One Side"
            ? {
              "Calculated at top CO2(Dry air)": {
                symmetricData: staticExcelData.flowsCalTopCO2Left ? staticExcelData.flowsCalTopCO2Left.join(", ") : "",
              },
            }
            : {
              "Calculated at top CO2(Dry air)": {
                left: staticExcelData.flowsCalTopCO2Left ? staticExcelData.flowsCalTopCO2Left.join(", ") : "",
                right: staticExcelData.flowsCalTopCO2Right ? staticExcelData.flowsCalTopCO2Right.join(", ") : "",
              },
            }),

          ...(customerInfo.furnaceType === "Cross Fired"
            ? {
              ...(customerInfo.chamberType === "One Side"
                ? {
                  "Total Calculated at top CO2(Dry air)": {
                    symmetricData: staticExcelData.flowsAvgCalTopCO2Left,
                  },
                }
                : {
                  "Total Calculated at top CO2(Dry air)": {
                    left: staticExcelData.flowsAvgCalTopCO2Left,
                    right: staticExcelData.flowsAvgCalTopCO2Right,
                  },
                }),
            }
            : {}),
        },

        //Calculated Fumes O2
        calculatedFumesO2: {
          ...(customerInfo.chamberType === "One Side"
            ? {
              "Calculated Fumes O2(Wet air)": {
                symmetricData: staticExcelData.flowsCalFumesO2Left ? staticExcelData.flowsCalFumesO2Left.join(", ") : "",
              },
            }
            : {
              "Calculated Fumes O2(Wet air)": {
                left: staticExcelData.flowsCalFumesO2Left ? staticExcelData.flowsCalFumesO2Left.join(", ") : "",
                right: staticExcelData.flowsCalFumesO2Right ? staticExcelData.flowsCalFumesO2Right.join(", ") : "",
              },
            }),

          ...(customerInfo.furnaceType === "Cross Fired"
            ? {
              ...(customerInfo.chamberType === "One Side"
                ? {
                  "Total Calculated Fumes O2(Wet air)": {
                    symmetricData: staticExcelData.flowsAvgCalFumesO2Left,
                  },
                }
                : {
                  "Total Calculated Fumes O2(Wet air)": {
                    left: staticExcelData.flowsAvgCalFumesO2Left,
                    right: staticExcelData.flowsAvgCalFumesO2Right,
                  },
                }),
            }
            : {}),
        },

        //Calculated Fumes CO2
        calculatedFumesCO2: {
          ...(customerInfo.chamberType === "One Side"
            ? {
              "Calculated Fumes CO2(Wet air)": {
                symmetricData: staticExcelData.flowsCalculFumesCO2Left ? staticExcelData.flowsCalculFumesCO2Left.join(", ") : "",
              },
            }
            : {
              "Calculated Fumes CO2(Wet air)": {
                left: staticExcelData.flowsCalculFumesCO2Left ? staticExcelData.flowsCalculFumesCO2Left.join(", ") : "",
                right: staticExcelData.flowsCalculFumesCO2Right ? staticExcelData.flowsCalculFumesCO2Right.join(", ") : "",
              },
            }),

          ...(customerInfo.furnaceType === "Cross Fired"
            ? {
              ...(customerInfo.chamberType === "One Side"
                ? {
                  "Total Calculated Fumes CO2(Wet air)": {
                    symmetricData: staticExcelData.flowsAvgCalFumesCO2Left,
                  },
                }
                : {
                  "Total Calculated Fumes CO2(Wet air)": {
                    left: staticExcelData.flowsAvgCalFumesCO2Left,
                    right: staticExcelData.flowsAvgCalFumesCO2Right,
                  },
                }),
            }
            : {}),
        },

        //Calculated Fumes H2O
        calculatedFumesH2O: {
          ...(customerInfo.chamberType === "One Side"
            ? {
              "Calculated Fumes H2O(Wet air)": {
                symmetricData:
                  staticExcelData.flowsCalculFumesH2OLeft ? staticExcelData.flowsCalculFumesH2OLeft.join(", ") : "",
              },
            }
            : {
              "Calculated Fumes H2O(Wet air)": {
                left: staticExcelData.flowsCalculFumesH2OLeft ? staticExcelData.flowsCalculFumesH2OLeft.join(", ") : "",
                right: staticExcelData.flowsCalculFumesH2ORight ? staticExcelData.flowsCalculFumesH2ORight.join(", ") : "",
              },
            }),

          ...(customerInfo.furnaceType === "Cross Fired"
            ? {
              ...(customerInfo.chamberType === "One Side"
                ? {
                  "Total Calculated Fumes H2O(Wet air)": {
                    symmetricData: staticExcelData.flowsAvgCalFumesH2OLeft,
                  },
                }
                : {
                  "Total Calculated Fumes H2O(Wet air)": {
                    left: staticExcelData.flowsAvgCalFumesH2OLeft,
                    right: staticExcelData.flowsAvgCalFumesH2ORight,
                  },
                }),
            }
            : {}),
        },

        //Calculated Fumes N2
        calculatedFumesN2: {
          ...(customerInfo.chamberType === "One Side"
            ? {
              "Calculated Fumes N2(Wet air)": {
                symmetricData:
                  staticExcelData.flowsCalCulFumesN2Left ? staticExcelData.flowsCalCulFumesN2Left.join(", ") : "",
              },
            }
            : {
              "Calculated Fumes N2(Wet air)": {
                left: staticExcelData.flowsCalCulFumesN2Left ? staticExcelData.flowsCalCulFumesN2Left.join(", ") : "",
                right: staticExcelData.flowsCalCulFumesN2Right ? staticExcelData.flowsCalCulFumesN2Right.join(", ") : "",
              },
            }),

          ...(customerInfo.furnaceType === "Cross Fired"
            ? {
              ...(customerInfo.chamberType === "One Side"
                ? {
                  "Total Calculated Fumes N2(Wet air)": {
                    symmetricData: staticExcelData.flowsAvgCalFumesN2Left,
                  },
                }
                : {
                  "Total Calculated Fumes N2(Wet air)": {
                    left: staticExcelData.flowsAvgCalFumesN2Left,
                    right: staticExcelData.flowsAvgCalFumesN2Right,
                  },
                }),
            }
            : {}),
        },

        //Calculated Fumes Verification
        calculatedFumesVerify: {
          ...(customerInfo.chamberType === "One Side"
            ? {
              "Calculated Fumes Verification(Wet air)": {
                symmetricData:
                  staticExcelData.flowsCalculVerifyLeft ? staticExcelData.flowsCalculVerifyLeft.join(", ") : "",
              },
            }
            : {
              "Calculated Fumes Verification(Wet air)": {
                left: staticExcelData.flowsCalculVerifyLeft ? staticExcelData.flowsCalculVerifyLeft.join(", ") : "",
                right: staticExcelData.flowsCalculVerifyRight ? staticExcelData.flowsCalculVerifyRight.join(", ") : "",
              },
            }),

          ...(customerInfo.furnaceType === "Cross Fired"
            ? {
              ...(customerInfo.chamberType === "One Side"
                ? {
                  "Total Calculated Fumes Verification(Wet air)": {
                    symmetricData: staticExcelData.flowsAvgCalFumesVerifyLeft,
                  },
                }
                : {
                  "Total Calculated Fumes Verification(Wet air)": {
                    left: staticExcelData.flowsAvgCalFumesVerifyLeft,
                    right: staticExcelData.flowsAvgCalFumesVerifyRight,
                  },
                }),
            }
            : {}),
        },
      };
      return JSON.stringify(result);
    } else if (val === "calculatedfumescorrelation") {
      let result = {
        "Calculated Fumes Correlation": {
          //Cp Fumes (kJ/kg.°K) at TEF(Calculated Fumes Correlation)
          ...(customerInfo.chamberType === "One Side"
            ? {
              "Cp Fumes (kJ/kg.°K) at TEF": {
                symmetricData: staticExcelData.calcCPFumesTEFLeft ? staticExcelData.calcCPFumesTEFLeft.join(", ") : "",
              },
            }
            : {
              "Cp Fumes (kJ/kg.°K) at TEF": {
                left: staticExcelData.calcCPFumesTEFLeft ? staticExcelData.calcCPFumesTEFLeft.join(", ") : "",
                right: staticExcelData.calcCPFumesTEFRight ? staticExcelData.calcCPFumesTEFRight.join(", ") : "",
              },
            }),

          ...(customerInfo.chamberType === "One Side"
            ? {
              "Total Cp Fumes (kJ/kg.°K) at TEF": {
                symmetricData: staticExcelData.totalCalcCPFumesTEFLeft,
              },
            }
            : {
              "Total Cp Fumes (kJ/kg.°K) at TEF": {
                left: staticExcelData.totalCalcCPFumesTEFLeft,
                right: staticExcelData.totalCalcCPFumesTEFRight,
              },
            }),

          //Cp Fumes (kJ/kg.°K) at TEA(Calculated Fumes Correlation)
          ...(customerInfo.chamberType === "One Side"
            ? {
              "Cp Fumes (kJ/kg.°K) at TEA": {
                symmetricData: staticExcelData.calcCPFumesTEALeft ? staticExcelData.calcCPFumesTEALeft.join(", ") : "",
              },
            }
            : {
              "Cp Fumes (kJ/kg.°K) at TEA": {
                left: staticExcelData.calcCPFumesTEALeft ? staticExcelData.calcCPFumesTEALeft.join(", ") : "",
                right: staticExcelData.calcCPFumesTEARight ? staticExcelData.calcCPFumesTEARight.join(", ") : "",
              },
            }),

          ...(customerInfo.chamberType === "One Side"
            ? {
              "Total Cp Fumes (kJ/kg.°K) at TEA": {
                symmetricData: staticExcelData.totalCalcCPFumesTEALeft,
              },
            }
            : {
              "Total Cp Fumes (kJ/kg.°K) at TEA": {
                left: staticExcelData.totalCalcCPFumesTEALeft,
                right: staticExcelData.totalCalcCPFumesTEARight,
              },
            }),

          //Fumes Energy(Wh)(Calculated Fumes Correlation)
          ...(customerInfo.chamberType === "One Side"
            ? {
              "Fumes Energy(Wh)": {
                symmetricData: staticExcelData.calcFumesEnergyLeft ? staticExcelData.calcFumesEnergyLeft.join(", ") : "",
              },
            }
            : {
              "Fumes Energy(Wh)": {
                left: staticExcelData.calcFumesEnergyLeft ? staticExcelData.calcFumesEnergyLeft.join(", ") : "",
                right: staticExcelData.calcFumesEnergyRight ? staticExcelData.calcFumesEnergyRight.join(", ") : "",
              },
            }),

          ...(customerInfo.chamberType === "One Side"
            ? {
              "Total Fumes Energy(Wh)": {
                symmetricData: staticExcelData.totalCalcFumesEnergyLeft,
              },
            }
            : {
              "Total Fumes Energy(Wh)": {
                left: staticExcelData.totalCalcFumesEnergyLeft,
                right: staticExcelData.totalCalcFumesEnergyRight,
              },
            }),

          //Furnace Fumes Energy (Wh)(Calculated Fumes Correlation)
          ...(customerInfo.chamberType === "One Side"
            ? {
              "Furnace Fumes Energy (Wh)": {
                symmetricData: staticExcelData.calcFumesEnergyLeft ? staticExcelData.calcFumesEnergyLeft.join(", ") : "",
              },
            }
            : {
              "Furnace Fumes Energy (Wh)": {
                left: staticExcelData.calcFumesEnergyLeft ? staticExcelData.calcFumesEnergyLeft.join(", ") : "",
                right: staticExcelData.calcFumesEnergyRight ? staticExcelData.calcFumesEnergyRight.join(", ") : "",
              },
            }),

          ...(customerInfo.chamberType === "One Side"
            ? {
              "Total Furnace Fumes Energy (Wh)": {
                symmetricData: staticExcelData.totalCalcFumesEnergyLeft,
              },
            }
            : {
              "Total Furnace Fumes Energy (Wh)": {
                left: staticExcelData.totalCalcFumesEnergyLeft,
                right: staticExcelData.totalCalcFumesEnergyRight,
              },
            }),

          //Furnace Fumes Energy (Wh)(Calculated Fumes Correlation)
          ...(customerInfo.chamberType === "One Side"
            ? {
              "Furnace Fumes Energy (Wh)": {
                symmetricData:
                  staticExcelData.calcFurnaceFumesEnLeft ? staticExcelData.calcFurnaceFumesEnLeft.join(", ") : "",
              },
            }
            : {
              "Furnace Fumes Energy (Wh)": {
                left: staticExcelData.calcFurnaceFumesEnLeft ? staticExcelData.calcFurnaceFumesEnLeft.join(", ") : "",
                right: staticExcelData.calcFurnaceFumesEnRight ? staticExcelData.calcFurnaceFumesEnRight.join(", ") : "",
              },
            }),

          ...(customerInfo.chamberType === "One Side"
            ? {
              "Total Furnace Fumes Energy (Wh)": {
                symmetricData: staticExcelData.totalCalcFurnaceFumesEnLeft,
              },
            }
            : {
              "Total Furnace Fumes Energy (Wh)": {
                left: staticExcelData.totalCalcFurnaceFumesEnLeft,
                right: staticExcelData.totalCalcFurnaceFumesEnRight,
              },
            }),

          //Energy Loss(Wh)(Calculated Fumes Correlation)
          ...(customerInfo.chamberType === "One Side"
            ? {
              "Energy Loss(Wh)": {
                symmetricData:
                  staticExcelData.calcEnergyLossWhLeft ? staticExcelData.calcEnergyLossWhLeft.join(", ") : "",
              },
            }
            : {
              "Energy Loss(Wh)": {
                left: staticExcelData.calcEnergyLossWhLeft ? staticExcelData.calcEnergyLossWhLeft.join(", ") : "",
                right: staticExcelData.calcEnergyLossWhRight ? staticExcelData.calcEnergyLossWhRight.join(", ") : "",
              },
            }),

          ...(customerInfo.chamberType === "One Side"
            ? {
              "Total Energy Loss(Wh)": {
                symmetricData: staticExcelData.totalCalcEnergyLossWhLeft,
              },
            }
            : {
              "Total Energy Loss(Wh)": {
                left: staticExcelData.totalCalcEnergyLossWhLeft,
                right: staticExcelData.totalCalcEnergyLossWhRight,
              },
            }),

          //Energy Loss%(Calculated Fumes Correlation)
          ...(customerInfo.chamberType === "One Side"
            ? {
              "Energy Loss%": {
                symmetricData:
                  staticExcelData.calcEnergyLossPercentLeft ? staticExcelData.calcEnergyLossPercentLeft.join(", ") : "",
              },
            }
            : {
              "Energy Loss%": {
                left: staticExcelData.calcEnergyLossPercentLeft ? staticExcelData.calcEnergyLossPercentLeft.join(", ") : "",
                right: staticExcelData.calcEnergyLossPercentRight ? staticExcelData.calcEnergyLossPercentRight.join(", ") : "",
              },
            }),

          ...(customerInfo.chamberType === "One Side"
            ? {
              "Total Energy Loss(%)": {
                symmetricData: staticExcelData.totalCalcEnergyLossPercentLeft,
              },
            }
            : {
              "Total Energy Loss(%)": {
                left: staticExcelData.totalCalcEnergyLossPercentLeft,
                right: staticExcelData.totalCalcEnergyLossPercentRight,
              },
            }),

          //Chamber Efficiency%(Calculated Fumes Correlation)
          ...(customerInfo.chamberType === "One Side"
            ? {
              "Chamber Efficiency%": {
                symmetricData:
                  staticExcelData.calcChamberEfficiLeft ? staticExcelData.calcChamberEfficiLeft.join(", ") : "",
              },
            }
            : {
              "Chamber Efficiency%": {
                left: staticExcelData.calcChamberEfficiLeft ? staticExcelData.calcChamberEfficiLeft.join(", ") : "",
                right: staticExcelData.calcChamberEfficiRight ? staticExcelData.calcChamberEfficiRight.join(", ") : "",
              },
            }),

          ...(customerInfo.chamberType === "One Side"
            ? {
              "Total Chamber Efficiency%": {
                symmetricData: staticExcelData.totalCalcChamberEfficiLeft,
              },
            }
            : {
              "Total Chamber Efficiency%": {
                left: staticExcelData.totalCalcChamberEfficiLeft,
                right: staticExcelData.totalCalcChamberEfficiRight,
              },
            }),

          //Max Chamber Efficiency%(Calculated Fumes Correlation)
          ...(customerInfo.chamberType === "One Side"
            ? {
              "Max Chamber Efficiency%": {
                symmetricData:
                  staticExcelData.calcMaxChamberEfficiLeft ? staticExcelData.calcMaxChamberEfficiLeft.join(", ") : "",
              },
            }
            : {
              "Max Chamber Efficiency%": {
                left: staticExcelData.calcMaxChamberEfficiLeft ? staticExcelData.calcMaxChamberEfficiLeft.join(", ") : "",
                right: staticExcelData.calcMaxChamberEfficiRight ? staticExcelData.calcMaxChamberEfficiRight.join(", ") : "",
              },
            }),

          ...(customerInfo.chamberType === "One Side"
            ? {
              "Total Max Chamber Efficiency%": {
                symmetricData: staticExcelData.totalMaxCalcChamberEfficiLeft,
              },
            }
            : {
              "Total Max Chamber Efficiency%": {
                left: staticExcelData.totalMaxCalcChamberEfficiLeft,
                right: staticExcelData.totalMaxCalcChamberEfficiRight,
              },
            }),

          //Bay Efficiency%(Calculated Fumes Correlation)
          ...(customerInfo.chamberType === "One Side"
            ? {
              "Bay Efficiency%": {
                symmetricData: staticExcelData.calcBayEfficiLeft ? staticExcelData.calcBayEfficiLeft.join(", ") : "",
              },
            }
            : {
              "Bay Efficiency%": {
                left: staticExcelData.calcBayEfficiLeft ? staticExcelData.calcBayEfficiLeft.join(", ") : "",
                right: staticExcelData.calcBayEfficiRight ? staticExcelData.calcBayEfficiRight.join(", ") : "",
              },
            }),

          ...(customerInfo.chamberType === "One Side"
            ? {
              "Total Bay Efficiency%": {
                symmetricData: staticExcelData.totalCalcBayEfficiLeft,
              },
            }
            : {
              "Total Bay Efficiency%": {
                left: staticExcelData.totalCalcBayEfficiLeft,
                right: staticExcelData.totalCalcBayEfficiRight,
              },
            }),

          //Max Furnace Efficiency%(Calculated Fumes Correlation)
          ...(customerInfo.chamberType === "One Side"
            ? {
              "Max Furnace Efficiency%": {
                symmetricData:
                  staticExcelData.calcMaxFurnaceEfficiLeft ? staticExcelData.calcMaxFurnaceEfficiLeft.join(", ") : "",
              },
            }
            : {
              "Max Furnace Efficiency%": {
                left: staticExcelData.calcMaxFurnaceEfficiLeft ? staticExcelData.calcMaxFurnaceEfficiLeft.join(", ") : "",
                right: staticExcelData.calcMaxFurnaceEfficiRight ? staticExcelData.calcMaxFurnaceEfficiRight.join(", ") : "",
              },
            }),

          ...(customerInfo.chamberType === "One Side"
            ? {
              "Total Max Furnace Efficiency%": {
                symmetricData: staticExcelData.totalCalcMaxFurnaceEfficiLeft,
              },
            }
            : {
              "Total Max Furnace Efficiency%": {
                left: staticExcelData.totalCalcMaxFurnaceEfficiLeft,
                right: staticExcelData.totalCalcMaxFurnaceEfficiRight,
              },
            }),

          //EBM Energy Efficiency Definition(%)(Calculated Fumes Correlation)
          ...(customerInfo.chamberType === "One Side"
            ? {
              "EBM Energy Efficiency Definition(%)": {
                symmetricData:
                  staticExcelData.calcEBMEnergyEffDefLeft ? staticExcelData.calcEBMEnergyEffDefLeft.join(", ") : "",
              },
            }
            : {
              "EBM Energy Efficiency Definition(%)": {
                left: staticExcelData.calcEBMEnergyEffDefLeft ? staticExcelData.calcEBMEnergyEffDefLeft.join(", ") : "",
                right: staticExcelData.calcEBMEnergyEffDefRight ? staticExcelData.calcEBMEnergyEffDefRight.join(", ") : "",
              },
            }),

          ...(customerInfo.chamberType === "One Side"
            ? {
              "Total EBM Energy Efficiency Definition(%)": {
                symmetricData: staticExcelData.totalCalcEBMEnergyEffDefLeft,
              },
            }
            : {
              "Total EBM Energy Efficiency Definition(%)": {
                left: staticExcelData.totalCalcEBMEnergyEffDefLeft,
                right: staticExcelData.totalCalcEBMEnergyEffDefRight,
              },
            }),
        },
      };
      return JSON.stringify(result);
    } else if (val === "fumescorrelation") {
      let result = {
        //Efficiencies Fumes Elementary correlation
        "Correlation A CO2": fumesElemCorr.co2.A,
        "Correlation B CO2": fumesElemCorr.co2.B,
        "Correlation C CO2": fumesElemCorr.co2.C,
        "Correlation D CO2": fumesElemCorr.co2.D,
        "Correlation A N2": fumesElemCorr.n2.A,
        "Correlation B N2": fumesElemCorr.n2.B,
        "Correlation C N2": fumesElemCorr.n2.C,
        "Correlation D N2": fumesElemCorr.n2.D,
        "Correlation A H2O": fumesElemCorr.h2o.A,
        "Correlation B H2O": fumesElemCorr.h2o.B,
        "Correlation C H2O": fumesElemCorr.h2o.C,
        "Correlation D H2O": fumesElemCorr.h2o.D,
        "Correlation A O2": fumesElemCorr.o2.A,
        "Correlation B O2": fumesElemCorr.o2.B,
        "Correlation C O2": fumesElemCorr.o2.C,
        "Correlation D O2": fumesElemCorr.o2.D,

        //Efficiencies Air Correlation
        "Air Correlation A": fumesElemCorr.airCorrelation.A,
        "Air Correlation B": fumesElemCorr.airCorrelation.B,
        "Air Correlation C": fumesElemCorr.airCorrelation.C,
        "Air Correlation D": fumesElemCorr.airCorrelation.D,

        //Efficiencies Correlation Measured Fumes Correlation (Right side of Excel sheet)
        ...(customerInfo.chamberType === "Both Side"
          ? {
            "Measured Fumes Correlation A": {
              left: staticExcelData.corrALeft,
              right: staticExcelData.corrARight,
            },
          }
          : {}),

        ...(customerInfo.chamberType === "Both Side"
          ? {
            "Measured Fumes Correlation B": {
              left: staticExcelData.corrBLeft,
              right: staticExcelData.corrBRight,
            },
          }
          : {}),

        ...(customerInfo.chamberType === "Both Side"
          ? {
            "Measured Fumes Correlation C": {
              left: staticExcelData.corrCLeft,
              right: staticExcelData.corrCRight,
            },
          }
          : {}),

        ...(customerInfo.chamberType === "Both Side"
          ? {
            "Measured Fumes Correlation D": {
              left: staticExcelData.corrDLeft,
              right: staticExcelData.corrDRight,
            },
          }
          : {}),

        //Efficiencies Correlation Calculated Fumes Correlation (Right side of Excel sheet)
        ...(customerInfo.chamberType === "Both Side"
          ? {
            "Calculated Fumes Correlation A": {
              left: staticExcelData.corrA1Left,
              right: staticExcelData.corrA1Right,
            },
          }
          : {}),
        ...(customerInfo.chamberType === "Both Side"
          ? {
            "Calculated Fumes Correlation B": {
              left: staticExcelData.corrB1Left,
              right: staticExcelData.corrB1Right,
            },
          }
          : {}),
        ...(customerInfo.chamberType === "Both Side"
          ? {
            "Calculated Fumes Correlation C": {
              left: staticExcelData.corrC1Left,
              right: staticExcelData.corrC1Right,
            },
          }
          : {}),
        ...(customerInfo.chamberType === "Both Side"
          ? {
            "Calculated Fumes Correlation D": {
              left: staticExcelData.corrD1Left,
              right: staticExcelData.corrD1Right,
            },
          }
          : {}),
      };
      return JSON.stringify(result);
    } else if (val === "composition") {
      let result = {
        Concentration_Inputs: {
          "O2 concentration[vol%]": getData(
            "o2",
            fumesCompo.compositionCFF.o2,
            fumesCompo.compositionEFF.o2
          ),
          "CO2 concentration[vol%]": getData(
            "co2",
            fumesCompo.compositionCFF.co2,
            fumesCompo.compositionEFF.co2
          ),
          "H2O concentration[vol%]": getData(
            "h2o",
            fumesCompo.compositionCFF.h2o,
            fumesCompo.compositionEFF.h2o
          ),
          "O2 measured bottom%": getData(
            "o2Bottom",
            fumesCompo.compositionCFF.o2Bottom,
            fumesCompo.compositionEFF.o2Bottom
          ),
          "O2 Dry air Top%": getData(
            "o2Top",
            fumesCompo.compositionCFF.o2Top,
            fumesCompo.compositionEFF.o2Top
          ),
        },
        Concentration_Outputs: {
          //DAP (Parasitic Air Flow)(O2 Measured Bottom%) Output
          "O2 measured bottom(%)": {
            ...(customerInfo.furnaceType === "Cross Fired"
              ? {
                ...(customerInfo.chamberType === "One Side"
                  ? {
                    "O2 measured bottom(%)": {
                      symmetricData: tempFumesGasCompLeft.avgO2BottomConLeftSide,
                    },
                  }
                  : {
                    "O2 measured bottom(%)": {
                      left: tempFumesGasCompLeft.avgO2BottomConLeftSide,
                      right: tempFumesGasCompRight.avgO2BottomConRightSide,
                    },
                  }),
              }
              : {}),
          },
          //Measured at top O2 (O2 Dry air %)Output
          "O2 Dry air Top(%)": {
            ...(customerInfo.furnaceType === "Cross Fired"
              ? {
                ...(customerInfo.chamberType === "One Side"
                  ? {
                    "O2 Dry air Top(%)": {
                      symmetricData: tempFumesGasCompLeft.avgO2TopConLeftSide,
                    },
                  }
                  : {
                    "O2 Dry air Top(%)": {
                      left: tempFumesGasCompLeft.avgO2TopConLeftSide,
                      right: tempFumesGasCompRight.avgO2TopConRightSide,
                    },
                  }),
              }
              : {}),
          },
          ...(customerInfo.chamberType === "One Side"
            ? {
              "N2 (%)Vol": {
                symmetricData:
                  tempFumesGasCompLeft.sumN2ConcentrationLeft ? tempFumesGasCompLeft.sumN2ConcentrationLeft.join(", ") : "",
              },
            }
            : {
              "N2 (%)Vol": {
                left:
                  tempFumesGasCompLeft.sumN2ConcentrationLeft ? tempFumesGasCompLeft.sumN2ConcentrationLeft.join(", ") : "",
                right:
                  tempFumesGasCompRight.sumN2ConcentrationRight ? tempFumesGasCompRight.sumN2ConcentrationRight.join(", ") : "",
              },
            }),

          ...(customerInfo.chamberType === "One Side"
            ? {
              "Total (CO2, N2, H2O, O2)": {
                symmetricData:
                  tempFumesGasCompLeft.totalPercentageVolLeft ? tempFumesGasCompLeft.totalPercentageVolLeft.join(", ") : "",
              },
            }
            : {
              "Total (CO2, N2, H2O, O2)": {
                left:
                  tempFumesGasCompLeft.totalPercentageVolLeft ? tempFumesGasCompLeft.totalPercentageVolLeft.join(", ") : "",
                right:
                  tempFumesGasCompRight.totalPercentageVolRight ? tempFumesGasCompRight.totalPercentageVolRight.join(", ") : "",
              },
            }),

          ...(customerInfo.chamberType === "One Side"
            ? {
              "ρ(kg/m3)": {
                symmetricData:
                  tempFumesGasCompLeft.calFumesCompRLeft ? tempFumesGasCompLeft.calFumesCompRLeft.join(", ") : "",
              },
            }
            : {
              "ρ(kg/m3)": {
                left: tempFumesGasCompLeft.calFumesCompRLeft ? tempFumesGasCompLeft.calFumesCompRLeft.join(", ") : "",
                right: tempFumesGasCompRight.calFumesCompRRight ? tempFumesGasCompRight.calFumesCompRRight.join(", ") : "",
              },
            }),

          ...(customerInfo.chamberType === "One Side"
            ? {
              "M(g/mol)": {
                symmetricData:
                  tempFumesGasCompLeft.calFumesCompMLeft ? tempFumesGasCompLeft.calFumesCompMLeft.join(", ") : "",
              },
            }
            : {
              "M(g/mol)": {
                left: tempFumesGasCompLeft.calFumesCompMLeft ? tempFumesGasCompLeft.calFumesCompMLeft.join(", ") : "",
                right: tempFumesGasCompRight.calFumesCompMRight ? tempFumesGasCompRight.calFumesCompMRight.join(", ") : "",
              },
            }),

          ...(customerInfo.chamberType === "One Side"
            ? {
              "CO2(Mass%)": {
                symmetricData:
                  tempFumesGasCompLeft.totalCO2PercentageMassLeft ? tempFumesGasCompLeft.totalCO2PercentageMassLeft.join(", ") : "",
              },
            }
            : {
              "CO2(Mass%)": {
                left:
                  tempFumesGasCompLeft.totalCO2PercentageMassLeft ? tempFumesGasCompLeft.totalCO2PercentageMassLeft.join(", ") : "",
                right:
                  tempFumesGasCompRight.totalCO2PercentageMassRight ? tempFumesGasCompRight.totalCO2PercentageMassRight.join(", ") : "",
              },
            }),

          ...(customerInfo.chamberType === "One Side"
            ? {
              "N2(Mass%)": {
                symmetricData:
                  tempFumesGasCompLeft.totalN2PercentageMassLeft ? tempFumesGasCompLeft.totalN2PercentageMassLeft.join(", ") : "",
              },
            }
            : {
              "N2(Mass%)": {
                left:
                  tempFumesGasCompLeft.totalN2PercentageMassLeft ? tempFumesGasCompLeft.totalN2PercentageMassLeft.join(", ") : "",
                right:
                  tempFumesGasCompRight.totalN2PercentageMassRight ? tempFumesGasCompRight.totalN2PercentageMassRight.join(", ") : "",
              },
            }),

          ...(customerInfo.chamberType === "One Side"
            ? {
              "H2O(Mass%)": {
                symmetricData:
                  tempFumesGasCompLeft.totalH2OPercentageMassLeft ? tempFumesGasCompLeft.totalH2OPercentageMassLeft.join(", ") : "",
              },
            }
            : {
              "H2O(Mass%)": {
                left:
                  tempFumesGasCompLeft.totalH2OPercentageMassLeft ? tempFumesGasCompLeft.totalH2OPercentageMassLeft.join(", ") : "",
                right:
                  tempFumesGasCompRight.totalH2OPercentageMassRight ? tempFumesGasCompRight.totalH2OPercentageMassRight.join(", ") : "",
              },
            }),

          ...(customerInfo.chamberType === "One Side"
            ? {
              "O2(Mass%)": {
                symmetricData:
                  tempFumesGasCompLeft.totalO2PercentageMassLeft ? tempFumesGasCompLeft.totalO2PercentageMassLeft.join(", ") : "",
              },
            }
            : {
              "O2(Mass%)": {
                left:
                  tempFumesGasCompLeft.totalO2PercentageMassLeft ? tempFumesGasCompLeft.totalO2PercentageMassLeft.join(", ") : "",
                right:
                  tempFumesGasCompRight.totalO2PercentageMassRight ? tempFumesGasCompRight.totalO2PercentageMassRight.join(", ") : "",
              },
            }),
        },
      };
      return JSON.stringify(result);
    } else if (val === "calculatedfumescomposition") {
      let result = {
        Calculated_Fumes_Composition: {
          //Efficiencies Fumes Composition Calculated Fumes CO2(vol%)
          ...(customerInfo.chamberType === "One Side"
            ? {
              "Calculated Fumes CO2(vol%)": {
                symmetricData: staticExcelData.effCalFumesCO2Left ? staticExcelData.effCalFumesCO2Left.join(", ") : "",
              },
            }
            : {
              "Calculated Fumes CO2(vol%)": {
                left: staticExcelData.effCalFumesCO2Left ? staticExcelData.effCalFumesCO2Left.join(", ") : "",
                right: staticExcelData.effCalFumesCO2Right ? staticExcelData.effCalFumesCO2Right.join(", ") : "",
              },
            }),

          //Efficiencies Fumes Composition Calculated Fumes N2(vol%)
          ...(customerInfo.chamberType === "One Side"
            ? {
              "Calculated Fumes N2(vol%)": {
                symmetricData: staticExcelData.effCalFumesN2Left ? staticExcelData.effCalFumesN2Left.join(", ") : "",
              },
            }
            : {
              "Calculated Fumes N2(vol%)": {
                left: staticExcelData.effCalFumesN2Left ? staticExcelData.effCalFumesN2Left.join(", ") : "",
                right: staticExcelData.effCalFumesN2Right ? staticExcelData.effCalFumesN2Right.join(", ") : "",
              },
            }),

          //Efficiencies Fumes Composition Calculated Fumes H2O(vol%)
          ...(customerInfo.chamberType === "One Side"
            ? {
              "Calculated Fumes H2O(vol%)": {
                symmetricData: staticExcelData.effCalFumesH2OLeft ? staticExcelData.effCalFumesH2OLeft.join(", ") : "",
              },
            }
            : {
              "Calculated Fumes H2O(vol%)": {
                left: staticExcelData.effCalFumesH2OLeft ? staticExcelData.effCalFumesH2OLeft.join(", ") : "",
                right: staticExcelData.effCalFumesH2ORight ? staticExcelData.effCalFumesH2ORight.join(", ") : "",
              },
            }),

          //Efficiencies Fumes Composition Calculated Fumes O2(vol%)
          ...(customerInfo.chamberType === "One Side"
            ? {
              "Calculated Fumes O2(vol%)": {
                symmetricData: staticExcelData.effCalFumesO2Left ? staticExcelData.effCalFumesO2Left.join(", ") : "",
              },
            }
            : {
              "Calculated Fumes O2(vol%)": {
                left: staticExcelData.effCalFumesO2Left ? staticExcelData.effCalFumesO2Left.join(", ") : "",
                right: staticExcelData.effCalFumesO2Right ? staticExcelData.effCalFumesO2Right.join(", ") : "",
              },
            }),

          //Efficiencies Fumes Composition Calculated Fumes Total
          ...(customerInfo.chamberType === "One Side"
            ? {
              "Total Calculated Fumes vol%": {
                symmetricData:
                  staticExcelData.totalFumesCompoPercentVolLeft ? staticExcelData.totalFumesCompoPercentVolLeft.join(", ") : "",
              },
            }
            : {
              "Total Calculated Fumes vol%": {
                left:
                  staticExcelData.totalFumesCompoPercentVolLeft ? staticExcelData.totalFumesCompoPercentVolLeft.join(", ") : "",
                right:
                  staticExcelData.totalFumesCompoPercentVolRight ? staticExcelData.totalFumesCompoPercentVolRight.join(", ") : "",
              },
            }),

          //Efficiencies Fumes Composition Calculated Fumes //ρ(kg/m3)
          ...(customerInfo.chamberType === "One Side"
            ? {
              "Total Calculated Fumes ρ(kg/m3)": {
                symmetricData: staticExcelData.effCalFumesCompRLeft,
              },
            }
            : {
              "Total Calculated Fumes ρ(kg/m3)": {
                left: staticExcelData.effCalFumesCompRLeft,
                right: staticExcelData.effCalFumesCompRRight,
              },
            }),

          //Efficiencies Fumes Composition Calculated Fumes //M(g/mol)
          ...(customerInfo.chamberType === "One Side"
            ? {
              "Total Calculated Fumes M(g/mol)": {
                symmetricData: staticExcelData.effCalFumesCompMLeft,
              },
            }
            : {
              "Total Calculated Fumes M(g/mol)": {
                left: staticExcelData.effCalFumesCompMLeft,
                right: staticExcelData.effCalFumesCompMRight,
              },
            }),

          //Efficiencies Fumes Composition Calculated Fumes(Mass%)
          ...(customerInfo.chamberType === "One Side"
            ? {
              "Total Calculated Fumes CO2(Mass%)": {
                symmetricData: staticExcelData.totalCalCO2PercentageMassLeft,
              },
            }
            : {
              "Total Calculated Fumes CO2(Mass%)": {
                left: staticExcelData.totalCalCO2PercentageMassLeft,
                right: staticExcelData.totalCalCO2PercentageMassRight,
              },
            }),

          ...(customerInfo.chamberType === "One Side"
            ? {
              "Total Calculated Fumes N2(Mass%)": {
                symmetricData: staticExcelData.totalCalN2PercentageMassLeft,
              },
            }
            : {
              "Total Calculated Fumes N2(Mass%)": {
                left: staticExcelData.totalCalN2PercentageMassLeft,
                right: staticExcelData.totalCalN2PercentageMassRight,
              },
            }),

          ...(customerInfo.chamberType === "One Side"
            ? {
              "Total Calculated Fumes H2O(Mass%)": {
                symmetricData: staticExcelData.totalCalH2OPercentageMassLeft,
              },
            }
            : {
              "Total Calculated Fumes H2O(Mass%)": {
                left: staticExcelData.totalCalH2OPercentageMassLeft,
                right: staticExcelData.totalCalH2OPercentageMassRight,
              },
            }),

          ...(customerInfo.chamberType === "One Side"
            ? {
              "Total Calculated Fumes O2(Mass%)": {
                symmetricData: staticExcelData.totalCalO2PercentageMassLeft,
              },
            }
            : {
              "Total Calculated Fumes O2(Mass%)": {
                left: staticExcelData.totalCalO2PercentageMassLeft,
                right: staticExcelData.totalCalO2PercentageMassRight,
              },
            }),
        },
      };
      return JSON.stringify(result);
    } else if (val === "measurements") {
      let result = {
        mesurements_by_port: {
          Carryovers: {
            sio2: getData(
              "singleValue",
              dusts.carryoversCFF.sio2,
              dusts.carryoversEFF.sio2
            ),
            cao: getData(
              "singleValue",
              dusts.carryoversCFF.cao,
              dusts.carryoversEFF.cao
            ),
            mgo: getData(
              "singleValue",
              dusts.carryoversCFF.mgo,
              dusts.carryoversEFF.mgo
            ),
            al2o3: getData(
              "singleValue",
              dusts.carryoversCFF.al2o3,
              dusts.carryoversEFF.al2o3
            ),
          },
          AlkalineCompounds: {
            na2o: getData(
              "singleValue",
              dusts.alkalineCompoundsCFF.na2o,
              dusts.alkalineCompoundsEFF.na2o
            ),
            k2o: getData(
              "singleValue",
              dusts.alkalineCompoundsCFF.k2o,
              dusts.alkalineCompoundsEFF.k2o
            ),
            so4: getData(
              "singleValue",
              dusts.alkalineCompoundsCFF.so4,
              dusts.alkalineCompoundsEFF.so4
            ),
          },
          OthersCompounds: {
            p2o5: getData(
              "singleValue",
              dusts.othersCFF.p2o5,
              dusts.othersEFF.p2o5
            ),
            cr2o3: getData(
              "singleValue",
              dusts.othersCFF.cr2o3,
              dusts.othersEFF.cr2o3
            ),
            fe2o3: getData(
              "singleValue",
              dusts.othersCFF.fe2o3,
              dusts.othersEFF.fe2o3
            ),
            pbo: getData(
              "singleValue",
              dusts.othersCFF.pbo,
              dusts.othersEFF.pbo
            ),
            v2o5: getData(
              "singleValue",
              dusts.othersCFF.v2o5,
              dusts.othersEFF.v2o5
            ),
            cl: getData("singleValue", dusts.othersCFF.cl, dusts.othersEFF.cl),
            no3: getData(
              "singleValue",
              dusts.othersCFF.no3,
              dusts.othersEFF.no3
            ),
          },
          Total: {
            1: dustsData.sumDustsAnalysisArray[0],
            2: dustsData.sumDustsAnalysisArray[1],
            3: dustsData.sumDustsAnalysisArray[2],
            4: dustsData.sumDustsAnalysisArray[3],
            5: dustsData.sumDustsAnalysisArray[4],
            6: dustsData.sumDustsAnalysisArray[5],
            7: dustsData.sumDustsAnalysisArray[6],
            8: dustsData.sumDustsAnalysisArray[7],
          },
        },
        //Generates per hour (Gr/h)
        GeneratesPerHour: {
          Carryovers: {
            sio2: staticExcelData.dustsGeneCarryoversSIO2,
            cao: staticExcelData.dustsGeneCarryoversCAO,
            mgo: staticExcelData.dustsGeneCarryoversMGO,
            al2o3: staticExcelData.dustsGeneCarryoversAL2O3,
          },
          AlkalineCompounds: {
            na2o: staticExcelData.dustsGeneAlkalineNA2O,
            k2o: staticExcelData.dustsGeneAlkalineK2O,
            so4: staticExcelData.dustsGeneAlkalineSO4,
          },
          OthersCompounds: {
            p2o5: staticExcelData.dustsGeneOthersP2O5,
            cr2o3: staticExcelData.dustsGeneOthersCR2O3,
            fe2o3: staticExcelData.dustsGeneOthersFE2O3,
            pbo: staticExcelData.dustsGeneOthersPBO,
            v2o5: staticExcelData.dustsGeneOthersV2O5,
            cl: staticExcelData.dustsGeneOthersCL,
            no3: staticExcelData.dustsGeneOthersNO3,
          },
          Total: staticExcelData.sumDustsAnalysisArrays,
        },
        //Generates per year(t/y)
        GeneratesPerYear: {
          Carryovers: {
            sio2: staticExcelData.dustsYearCarryoversSIO2,
            cao: staticExcelData.dustsYearCarryoversCAO,
            mgo: staticExcelData.dustsYearCarryoversMGO,
            al2o3: staticExcelData.dustsYearCarryoversAL2O3,
          },
          AlkalineCompounds: {
            na2o: staticExcelData.dustsYearAlkalineNA2O,
            k2o: staticExcelData.dustsYearAlkalineK2O,
            so4: staticExcelData.dustsYearAlkalineSO4,
          },
          OthersCompounds: {
            p2o5: staticExcelData.dustsYearOthersP2O5,
            cr2o3: staticExcelData.dustsYearOthersCR2O3,
            fe2o3: staticExcelData.dustsYearOthersFE2O3,
            pbo: staticExcelData.dustsYearOthersPBO,
            v2o5: staticExcelData.dustsYearOthersV2O5,
            cl: staticExcelData.dustsYearOthersCL,
            no3: staticExcelData.dustsYearOthersNO3,
          },
          Total: staticExcelData.dustsAnalysisYearArrays,
        },
        //Average of generates per year(t/y)
        AverageYear: {
          Carryovers: {
            sio2: staticExcelData.effAverageCarryoversSIO2,
            cao: staticExcelData.effAverageCarryoversCAO,
            mgo: staticExcelData.effAverageCarryoversMGO,
            al2o3: staticExcelData.effAverageCarryoversAL2O3,
          },
          AlkalineCompounds: {
            na2o: staticExcelData.effAverageAlkalineNA2O,
            k2o: staticExcelData.effAverageAlkalineK2O,
            so4: staticExcelData.effAverageAlkalineSO4,
          },
          OthersCompounds: {
            p2o5: staticExcelData.effAverageOthersP2O5,
            cr2o3: staticExcelData.effAverageOthersCR2O3,
            fe2o3: staticExcelData.effAverageOthersFE2O3,
            pbo: staticExcelData.effAverageOthersPBO,
            v2o5: staticExcelData.effAverageOthersV2O5,
            cl: staticExcelData.effAverageOthersCL,
            no3: staticExcelData.effAverageOthersNO3,
          },
          Total: staticExcelData.effOverallAverageTotal,
        },
        //Total generates per year(t/y)
        TotalYear: {
          Carryovers: {
            sio2: staticExcelData.effSumSIO2,
            cao: staticExcelData.effSumCAO,
            mgo: staticExcelData.effSumMGO,
            al2o3: staticExcelData.effSumAL2O3,
          },
          AlkalineCompounds: {
            na2o: staticExcelData.effSumNA2O,
            k2o: staticExcelData.effSumK2O,
            so4: staticExcelData.effSumSO4,
          },
          OthersCompounds: {
            p2o5: staticExcelData.effSumP2O5,
            cr2o3: staticExcelData.effSumCR2O3,
            fe2o3: staticExcelData.effSumFE2O3,
            pbo: staticExcelData.effSumPBO,
            v2o5: staticExcelData.effSumV2O5,
            cl: staticExcelData.effSumCL,
            no3: staticExcelData.effSumNO3,
          },
          Total: staticExcelData.effTotalSum,
        },
        OverallDusts: {
          //Carryovers Si+Ca+Al+Mg
          "Carryovers Si+Ca+Al+Mg(Mean/Chamber(t/y))":
            staticExcelData.effCarryoversSum,
          //Alkaline Compounds  Na+K+SO
          "Alkaline Compounds Na+K+SO(Mean/Chamber(t/y))":
            staticExcelData.effAlkalineCompundsSum,
          //Others
          "Others(Mean/Chamber(t/y))": staticExcelData.effOthersSum,
          //Overall
          "Overall(Mean/Chamber(t/y))": staticExcelData.effOverallSum,
        },
      };
      return JSON.stringify(result);
    } else {
      return "{}";
    }
  };

  const excelGeneration = (trial_id) => {
    axios
      .get(`${API.GET_TRIAL}?trial_id=${trial_id}`, {
        headers: {
          authorization: userValue.web_token
        }
      })
      .then(function (response) {
        console.log(response, "excel")
        // handle success
        if (response.status === 200) {
          if (
            response.data &&
            response.data.data.trials &&
            response.data.data.trials.length > 0
          ) {
            generateExcelFile(response.data);
          }
        }
      });
  };

  const insertTrials = async (
    updated_at,
    created_by,
    customer_name,
    plant,
    trial_date,
    furnace_name,
    furnace_type,
    no_of_port,
    chamber_type,
    country,
    natual_gas_properties,
    heavy_oil_properties,
    batch_info,
    regenerator_properties,
    temperatures,
    combustion,
    calculated_fumes_correlation,
    fumes_correlation,
    composition,
    calculated_fumes_composition,
    mesurements_by_port,
    cumulative
  ) => {
    try {
      const formInsertData = new FormData();
      formInsertData.append("updated_at", updated_at);
      formInsertData.append("created_by", created_by);
      formInsertData.append("customer_name", customer_name);
      formInsertData.append("plant", plant);
      formInsertData.append("trial_date", trial_date);
      formInsertData.append("furnace_name", furnace_name);
      formInsertData.append("furnace_type", furnace_type);
      formInsertData.append("no_of_port", no_of_port);
      formInsertData.append("chamber_type", chamber_type);
      formInsertData.append("country", country);
      formInsertData.append("heavy_oil_properties", heavy_oil_properties);
      formInsertData.append("natual_gas_properties", natual_gas_properties);
      formInsertData.append("batch_info", batch_info);
      formInsertData.append("regenerator_properties", regenerator_properties);
      formInsertData.append(
        "calculated_fumes_correlation",
        calculated_fumes_correlation
      );
      formInsertData.append("combustion", combustion);
      formInsertData.append("temperatures", temperatures);
      formInsertData.append("fumes_correlation", fumes_correlation);
      formInsertData.append("composition", composition);
      formInsertData.append(
        "calculated_fumes_composition",
        calculated_fumes_composition
      );
      formInsertData.append("cumulative", cumulative);
      formInsertData.append("mesurements_by_port", mesurements_by_port);
      await axios.post(API.INSERT_TRIALS, formInsertData, {
        headers: {
          authorization: userValue.web_token,
          'Content-Type': 'application/json'
        }
      }).then((res) => {
        if (res.status === 200) {
          excelGeneration(res.data.trials.rows[0].id);
          props.onSubmit("Form successfully submitted");
        }
      }).catch((error) => {
        console.error("An error occurred in update trials:", error);
        props.onSubmit("Unable to submit, please try again!");
        // Handle the error here (e.g., show an error message to the user).
      });
    } catch (error) {
      console.error(error);
      props.onSubmit("Unable to submit, please try again!");
    }
  };

  const updateTrials = async (
    trial_id,
    updated_at,
    created_by,
    customer_name,
    plant,
    trial_date,
    furnace,
    furnace_type,
    no_of_port,
    chamber_type,
    country,
    customer_info_id,
    natual_gas_properties,
    heavy_oil_properties,
    fuel_info_id,
    batch_info,
    regenerator_properties,
    furnace_details_id,
    temperatures,
    combustion,
    calculated_fumes_correlation,
    fumes_correlation,
    thermal_balance_id,
    composition,
    calculated_fumes_composition,
    fumes_composition_id,
    mesurements_by_port,
    cumulative,
    dust_analysis_id
  ) => {
    try {
      const formUpdateData = new FormData();
      formUpdateData.append("trial_id", trial_id);
      formUpdateData.append("updated_at", updated_at);
      formUpdateData.append("created_by", created_by);
      formUpdateData.append("customer_name", customer_name);
      formUpdateData.append("plant", plant);
      formUpdateData.append("trial_date", trial_date);
      formUpdateData.append("furnace", furnace);
      formUpdateData.append("furnace_type", furnace_type);
      formUpdateData.append("no_of_port", no_of_port);
      formUpdateData.append("chamber_type", chamber_type);
      formUpdateData.append("country", country);
      formUpdateData.append("customer_info_id", customer_info_id);
      formUpdateData.append("natual_gas_properties", natual_gas_properties);
      formUpdateData.append("heavy_oil_properties", heavy_oil_properties);
      formUpdateData.append("fuel_info_id", fuel_info_id);
      formUpdateData.append("batch_info", batch_info);
      formUpdateData.append("regenerator_properties", regenerator_properties);
      formUpdateData.append("furnace_details_id", furnace_details_id);
      formUpdateData.append("temperatures", temperatures);
      formUpdateData.append("combustion", combustion);
      formUpdateData.append(
        "calculated_fumes_correlation",
        calculated_fumes_correlation
      );
      formUpdateData.append("fumes_correlation", fumes_correlation);
      formUpdateData.append("thermal_balance_id", thermal_balance_id);
      formUpdateData.append("composition", composition);
      formUpdateData.append(
        "calculated_fumes_composition",
        calculated_fumes_composition
      );
      formUpdateData.append("fumes_composition_id", fumes_composition_id);
      formUpdateData.append("mesurements_by_port", mesurements_by_port);
      formUpdateData.append("cumulative", cumulative);
      formUpdateData.append("dust_analysis_id", dust_analysis_id);
      await axios.put(API.UPDATE_TRIALS_LIST, formUpdateData, {
        headers: {
          authorization: userValue.web_token,
          'Content-Type': 'application/json'
        }
      }).then((res) => {
        if (res.status === 200) {
          excelGeneration(trial_id);
          // alert("Form successfully updated");
          props.onSubmit("Form successfully updated")

        }
      }).catch((error) => {
        console.error("An error occurred in update trials:", error);
        props.onSubmit("Unable to submit, please try again!");
        // Handle the error here (e.g., show an error message to the user).
      });
    } catch (error) {
      console.error(error);
      props.onSubmit("Unable to submit, please try again!");
    }
  };

  async function onHandleSubmit() {
    console.log("onHandleSubmit");
    const user = JSON.parse(localStorage.getItem("user"));
    let noOfPorts =
      customerInfo.furnaceType === "Cross Fired"
        ? customerInfo.numberOfPorts
        : "0";
    if (visitStatus) {

      await insertTrials(
        new Date().toISOString(),
        user.id,
        customerInfo.customerName,
        customerInfo.plantName,
        customerInfo.date,
        customerInfo.furnaceName,
        customerInfo.furnaceType,
        noOfPorts,
        customerInfo.chamberType,
        customerInfo.country,
        getJsonb("naturalgas"),
        getJsonb("heavyoil"),
        getJsonb("batchinfo"),
        getJsonb("regenerator"),
        getJsonb("temperatures"),
        getJsonb("combustion"),
        getJsonb("calculatedfumescorrelation"),
        getJsonb("fumescorrelation"),
        getJsonb("composition"),
        getJsonb("calculatedfumescomposition"),
        getJsonb("measurements"),
        getJsonb("cumulative")
      );
      // Delete the submitted row from local storage
      let tmpVisitData = JSON.parse(localStorage.getItem("myRegenVisitData"));
      let resData = {};
      let sgId = getSgId();
      let object = tmpVisitData[sgId];
      delete object[getVisitNumber()];
      let insertResult = {
        [sgId]: object,
      };
      Object.assign(resData, insertResult);
      localStorage.setItem("myRegenVisitData", JSON.stringify(resData));
    } else {
      await updateTrials(
        trial_id,
        new Date().toISOString(),
        user.id,
        customerInfo.customerName,
        customerInfo.plantName,
        customerInfo.date,
        customerInfo.furnaceName,
        customerInfo.furnaceType,
        noOfPorts,
        customerInfo.chamberType,
        customerInfo.country,
        trialDataID.customer_info_id,
        getJsonb("naturalgas"),
        getJsonb("heavyoil"),
        trialDataID.fuel_info_id,
        getJsonb("batchinfo"),
        getJsonb("regenerator"),
        trialDataID.furnace_details_id,
        getJsonb("temperatures"),
        getJsonb("combustion"),
        getJsonb("calculatedfumescorrelation"),
        getJsonb("fumescorrelation"),
        trialDataID.thermal_balance_id,
        getJsonb("composition"),
        getJsonb("calculatedfumescomposition"),
        trialDataID.fumes_composition_id,
        getJsonb("measurements"),
        getJsonb("cumulative"),
        trialDataID.dust_analysis_id
      );
      // Delete the submitted row from local storage
      let tmpVisitData = JSON.parse(localStorage.getItem("myRegenVisitData"));
      let restData = {};
      let sg_Id = getSgId();
      let object = tmpVisitData[sg_Id];
      delete object[getVisitNumber()];
      let updateResult = {
        [sg_Id]: object,
      };
      Object.assign(restData, updateResult);
      localStorage.setItem("myRegenVisitData", JSON.stringify(restData));
    }
  }

  return (
    <React.Fragment>
      <Grid container>
        <Grid container spacing={2}>
          <Grid item xs={12} xl={12}>
            <Typography
              variant="h6"
              style={{ fontWeight: 600, marginLeft: 20 }}
            >
              Inputs
            </Typography>
            <FlowsInputCard
              tableHeading={"Flows"}
              fuelInputDet={fuelInputDetails}
              batchInputDet={batchInputDetails}
              regenInputDet={regenInputDetails}
            />
          </Grid>
          <Grid item xs={12} xl={6} style={{ marginTop: "3px" }}>
            <EfficienciesInputCard
              tableHeading={"Efficiencies"}
              airFumesTempInputDet={airFumesTempInputDetails}
              fumesGasCompInputDet={fumesGasCompInputDetails}
            />
          </Grid>
          <Grid item xs={12} xl={6} style={{ marginTop: "3px" }}>
            <DustsInputCard
              tableHeading={"Dusts"}
              dustsAnalysisInputDet={dustsAnalysisInputDetails}
            />
          </Grid>
        </Grid>

        <Grid container spacing={2}>
          <Grid item xs={12} xl={12} style={{ marginTop: "3px" }}>
            <Typography
              variant="h6"
              style={{ fontWeight: 600, marginLeft: 20 }}
            >
              Outputs
            </Typography>
            <FlowsOutputCard
              tableHeading={"Flows"}
              fuelOutputDet={fuelOutputDetails}
              batchDet={batchOutputDetails}
              regenOutputDet={regenOutputDetails}
            />
          </Grid>
          <Grid item xs={12} xl={12} style={{ marginTop: "3px" }}>
            <EfficienciesOutputCard
              tableHeading={"Efficiencies"}
              airFumesTempOutputDet={airFumesTempOutputDetails}
              fumesGasCompOutputDet={fumesGasCompositionOutputDetails}
            />
          </Grid>
          <Grid item xs={12} xl={12} style={{ marginTop: "3px" }}>
            <DustsOutputCard
              tableHeading={"Dusts"}
              dustsAnalysisOutputDet={dustsAnalysisOutputDetails}
            />
          </Grid>
        </Grid>
      </Grid>
      <Box style={{ textAlign: "right" }}>
        <Button
          variant="contained"
          onClick={onHandleSubmit}
          style={{
            margin: "10px",
            backgroundColor: "#203468",
            textTransform: "capitalize",
          }}
        >
          Submit
        </Button>
      </Box>
    </React.Fragment>
  );
};

export default Summary;