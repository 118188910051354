import React, { useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CustomText from "../../../../../components/ui/Typography";
import { Container, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import { useRecoilState } from "recoil";
import { customerInfoData, dustsAnalysis, newVisitStatus } from "../../../../../recoil/atoms";
import configParam from "../../../../../config";

const DustAnalysis = (props) => {
  const deepCopy = configParam.DEEP_COPY;
  const [customerInfo] = useRecoilState(customerInfoData);
  let numberOfPorts = customerInfo.numberOfPorts;
  if (customerInfo.furnaceType === "End Fired") {
    numberOfPorts = 1;
  }
  const [dustData, setDustData] = useRecoilState(dustsAnalysis);
  const [visitStatus] = useRecoilState(newVisitStatus);
  const sgid = localStorage.getItem("UserID");
  const getItemNo = configParam.getVisitNumbers;
  const myRegenFeatures = localStorage.getItem("myRegenVisitData");
  let data = JSON.parse(myRegenFeatures ?? "{}");

  const getNumberOfPorts = (type, subType) => {
    let data = dustData;
    const arr = [];
    for (let i = 0; i < numberOfPorts; i++) {
      let id = i + 1;
      arr.push(
        <Grid item xs={12} md={12}>
          <TextField
            variant="outlined"
            size="small"
            name={id}
            label={"#" + id}
            type="number"
            value={customerInfo.furnaceType === "End Fired" ? dustData[type + "EFF"][subType] : data[type + "CFF"][subType][id]}
            onChange={(e) => {
              const newValue = e.target.value;
              if (newValue.length <= 10 && !isNaN(newValue)) {
                OnHandleChange(type, subType, e);
              }
            }}
          />
        </Grid>
      );
    }
    return arr;
  };

  const OnHandleChange = (type, subType, event) => {
    let tmpObj = deepCopy(dustData);
    if (customerInfo.furnaceType === "End Fired") {
      tmpObj[type + "EFF"][subType] = event.target.value;
    } else {
      tmpObj[type + "CFF"][subType][event.target.name] = event.target.value;
    }
    setDustData(tmpObj);
    let rowIndex = getItemNo();
    let obj = { dustsAnalysis: dustData };
    let merged = { ...data[sgid][rowIndex || 0], ...obj };
    data[sgid][rowIndex] = merged;
    localStorage.setItem("myRegenVisitData", JSON.stringify(data));
  };

  useEffect(() => {
    if (visitStatus) {
      let rowIndex = getItemNo();
      let obj = { dustsAnalysis: dustData };
      let merged = { ...data[sgid][rowIndex || 0], ...obj };
      data[sgid][rowIndex] = merged;
      localStorage.setItem("myRegenVisitData", JSON.stringify(data));
    }
  });

  return (
    <Dialog
      open={props.open}
      onClose={props.handleClose}
      fullWidth={true}
      maxWidth={"md"}>
      <DialogTitle>
        Dusts Analysis
        <CloseIcon
          sx={{
            position: "absolute",
            right: 20,
            top: 20,
            cursor: "pointer"
          }}
          onClick={props.handleClose} />
      </DialogTitle>
      <DialogContent
        style={{
          borderTop: "1px solid #a8a8a8",
          margin: "0 0 10px",
          padding: 0,
        }}>
        <DialogContentText>
          <Container>
            <CustomText
              value="Carryovers [mg/Nm3]"
              variant="h6"
              style={{
                color: "#2D2D2D",
                fontWeight: 500,
                marginTop: "10px",
                fontSize: "18px",
              }}
              align="left" />
            <Grid container spacing={2}>
              <Grid item xl={3} md={3} xs={6}>
                {numberOfPorts > 0 && (
                  <React.Fragment>
                    <CustomText
                      value="SiO2 "
                      variant="subtitle1"
                      style={{
                        color: "#2D2D2D",
                        margin: "0 0 10px",
                        fontSize: "14px",
                      }}
                      align="left"
                    />
                    <Grid container spacing={2}>
                      {getNumberOfPorts("carryovers", "sio2")}
                    </Grid>
                  </React.Fragment>
                )}
              </Grid>
              <Grid item xl={3} md={3} xs={6}>
                {numberOfPorts > 0 && (
                  <React.Fragment>
                    <CustomText
                      value="CaO"
                      variant="subtitle1"
                      style={{
                        color: "#2D2D2D",
                        margin: "0 0 10px",
                        fontSize: "14px",
                      }}
                      align="left"
                    />
                    <Grid container spacing={2}>
                      {getNumberOfPorts("carryovers", "cao")}
                    </Grid>
                  </React.Fragment>
                )}
              </Grid>
              <Grid item xl={3} md={3} xs={6}>
                {numberOfPorts > 0 && (
                  <React.Fragment>
                    <CustomText
                      value="MgO"
                      variant="subtitle1"
                      style={{
                        color: "#2D2D2D",
                        margin: "0 0 10px",
                        fontSize: "14px",
                      }}
                      align="left"
                    />
                    <Grid container spacing={2}>
                      {getNumberOfPorts("carryovers", "mgo")}
                    </Grid>
                  </React.Fragment>
                )}
              </Grid>
              <Grid item xl={3} md={3} xs={6}>
                {numberOfPorts > 0 && (
                  <React.Fragment>
                    <CustomText
                      value="Al2O3"
                      variant="subtitle1"
                      style={{
                        color: "#2D2D2D",
                        margin: "0 0 10px",
                        fontSize: "14px",
                      }}
                      align="left"
                    />
                    <Grid container spacing={2}>
                      {getNumberOfPorts("carryovers", "al2o3")}
                    </Grid>
                  </React.Fragment>
                )}
              </Grid>
            </Grid>
            <CustomText
              value="Alkaline Compounds [mg/Nm3] "
              variant="h6"
              style={{
                color: "#2D2D2D",
                fontWeight: 500,
                marginTop: "10px",
                fontSize: "18px",
              }}
              align="left"
            />
            <Grid container spacing={2}>
              <Grid item xl={3} md={3} sm={6} xs={6}>
                {numberOfPorts > 0 && (
                  <React.Fragment>
                    <CustomText
                      value="Na2O "
                      variant="subtitle1"
                      style={{
                        color: "#2D2D2D",
                        margin: "0 0 10px",
                        fontSize: "14px",
                      }}
                      align="left"
                    />
                    <Grid container spacing={2}>
                      {getNumberOfPorts("alkalineCompounds", "na2o")}
                    </Grid>
                  </React.Fragment>
                )}
              </Grid>
              <Grid item xl={3} md={3} sm={6} xs={6}>
                {numberOfPorts > 0 && (
                  <React.Fragment>
                    <CustomText
                      value="K2O "
                      variant="subtitle1"
                      style={{
                        color: "#2D2D2D",
                        margin: "0 0 10px",
                        fontSize: "14px",
                      }}
                      align="left"
                    />
                    <Grid container spacing={2}>
                      {getNumberOfPorts("alkalineCompounds", "k2o")}
                    </Grid>
                  </React.Fragment>
                )}
              </Grid>
              <Grid item xl={3} md={3} sm={6} xs={6}>
                {numberOfPorts > 0 && (
                  <React.Fragment>
                    <CustomText
                      value="SO4"
                      variant="subtitle1"
                      style={{
                        color: "#2D2D2D",
                        margin: "0 0 10px",
                        fontSize: "14px",
                      }}
                      align="left"
                    />
                    <Grid container spacing={2}>
                      {getNumberOfPorts("alkalineCompounds", "so4")}
                    </Grid>
                  </React.Fragment>
                )}
              </Grid>
            </Grid>
            <CustomText
              value="Others [mg/Nm3] "
              variant="h6"
              style={{
                color: "#2D2D2D",
                fontWeight: 500,
                marginTop: "10px",
                fontSize: "18px",
              }}
              align="left"
            />
            <Grid container spacing={2}>
              <Grid item xl={3} md={3} xs={6}>
                {numberOfPorts > 0 && (
                  <React.Fragment>
                    <CustomText
                      value="P2O5"
                      variant="subtitle1"
                      style={{
                        color: "#2D2D2D",
                        margin: "0 0 10px",
                        fontSize: "14px",
                      }}
                      align="left"
                    />
                    <Grid container spacing={2}>
                      {getNumberOfPorts("others", "p2o5")}
                    </Grid>
                  </React.Fragment>
                )}
              </Grid>
              <Grid item xl={3} md={3} xs={6}>
                {numberOfPorts > 0 && (
                  <React.Fragment>
                    <CustomText
                      value="Cr2O3"
                      variant="subtitle1"
                      style={{
                        color: "#2D2D2D",
                        margin: "0 0 10px",
                        fontSize: "14px",
                      }}
                      align="left"
                    />
                    <Grid container spacing={2}>
                      {getNumberOfPorts("others", "cr2o3")}
                    </Grid>
                  </React.Fragment>
                )}
              </Grid>
              <Grid item xl={3} md={3} xs={6}>
                {numberOfPorts > 0 && (
                  <React.Fragment>
                    <CustomText
                      value="Fe2O3"
                      variant="subtitle1"
                      style={{
                        color: "#2D2D2D",
                        margin: "0 0 10px",
                        fontSize: "14px",
                      }}
                      align="left"
                    />
                    <Grid container spacing={2}>
                      {getNumberOfPorts("others", "fe2o3")}
                    </Grid>
                  </React.Fragment>
                )}
              </Grid>
              <Grid item xl={3} md={3} xs={6}>
                {numberOfPorts > 0 && (
                  <React.Fragment>
                    <CustomText
                      value="PbO"
                      variant="subtitle1"
                      style={{
                        color: "#2D2D2D",
                        margin: "0 0 10px",
                        fontSize: "14px",
                      }}
                      align="left"
                    />
                    <Grid container spacing={2}>
                      {getNumberOfPorts("others", "pbo")}
                    </Grid>
                  </React.Fragment>
                )}
              </Grid>
            </Grid>

            <Grid style={{ marginTop: "10px" }} container spacing={2}>
              <Grid item xl={3} md={8} xs={6}>
                {numberOfPorts > 0 && (
                  <React.Fragment>
                    <CustomText
                      value="V2O5 "
                      variant="subtitle1"
                      style={{
                        color: "#2D2D2D",
                        margin: "0 0 10px",
                        fontSize: "14px",
                      }}
                      align="left"
                    />
                    <Grid container spacing={2}>
                      {getNumberOfPorts("others", "v2o5")}
                    </Grid>
                  </React.Fragment>
                )}
              </Grid>
              <Grid item xl={3} md={8} xs={6}>
                {numberOfPorts > 0 && (
                  <React.Fragment>
                    <CustomText
                      value="Cl "
                      variant="subtitle1"
                      style={{
                        color: "#2D2D2D",
                        margin: "0 0 10px",
                        fontSize: "14px",
                      }}
                      align="left"
                    />
                    <Grid container spacing={2}>
                      {getNumberOfPorts("others", "cl")}
                    </Grid>
                  </React.Fragment>
                )}
              </Grid>
              <Grid item xl={3} md={8} xs={6}>
                {numberOfPorts > 0 && (
                  <React.Fragment>
                    <CustomText
                      value="NO3"
                      variant="subtitle1"
                      style={{
                        color: "#2D2D2D",
                        margin: "0 0 10px",
                        fontSize: "14px",
                      }}
                      align="left"
                    />
                    <Grid container spacing={2}>
                      {getNumberOfPorts("others", "no3")}
                    </Grid>
                  </React.Fragment>
                )}
              </Grid>
            </Grid>
          </Container>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default DustAnalysis;