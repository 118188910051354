import React from "react";
import { Typography } from "@mui/material";

const CustomText = (props) => {
  return (
    <Typography
      id={props.id}
      align={props.align}
      style={props.style}
      className={props.className}
      onClick={props.onClick}
      variant={props.variant}
    >
      {" "}
      {props.value}
    </Typography>
  );
};

export default CustomText;